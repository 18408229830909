<ng-template #popTitle
	><h4 style="color: black">
		<b>{{
			tooltipInformationObject.title !== ""
				? tooltipInformationObject.title
				: "No Tag Found"
		}}</b>
	</h4>
	<p style="color: black" *ngIf="tooltipInformationObject.title !== ''">
		Right Click To Copy To Clipboard
	</p>
</ng-template>
<ng-template #popContent>
	<div *ngIf="tooltipInformationObject.title !== ''">
		<p style="color: black"><b>TagName:</b></p>
		<p style="color: black">{{ tooltipInformationObject.TagName }}</p>
		<p style="color: black"><b>TagId:</b></p>
		<p style="color: black">{{ tooltipInformationObject.TagId }}</p>
		<p style="color: black"><b>ListOfStdIdsUsed:</b></p>
		<p style="color: black">
			{{ tooltipInformationObject.ListOfStdIdsUsed }}
		</p>
		<p style="color: black"><b>StdId:</b></p>
		<p style="color: black">{{ tooltipInformationObject.StdId }}</p>
		<p style="color: black"><b>Datetime:</b></p>
		<p style="color: black">{{ tooltipInformationObject.Datetime | date :'MM/dd/yyyy&nbsp;HH:mm:ss.SSS' }}</p>
		<p style="color: black"><b>Value:</b></p>
		<p style="color: black">{{ tooltipInformationObject.Value }}</p>
		<p style="color: black"><b>ValueWhenActive:</b></p>
		<p style="color: black">
			{{ tooltipInformationObject.ValueWhenActive }}
		</p>
	</div>
	<div *ngIf="tooltipInformationObject.title === ''">
		<p style="color: black"><b>ListOfStdIdsUsed:</b></p>
		<p style="color: black">
			{{ tooltipInformationObject.ListOfStdIdsUsed }}
		</p>
	</div>
</ng-template>

<div *ngIf="!isDataLoading" class="box">
	<div class="d-flex flex-row" style="flex-wrap: wrap; align-items: start; justify-content: normal !important">
		<div class="d-flex flex-column">
			<div class="d-flex flex-row" style="flex-wrap: wrap;">
				<div
					[openDelay]="300"
					triggers="mouseenter:mouseleave"
					[disablePopover]="!global.User.DebugMode"
					(contextmenu)="onRightClick($event, 'PowerOn')"
					container="body"
					(mouseenter)="tooltipInformation('PowerOn')"
					[ngbPopover]="popContent"
					[popoverTitle]="popTitle"
					(click)="
						openTagGraphSingle(
							ObservationIdMappingObjectForTrueFalseValues.PowerOn
						)
					"
					class="col box-stat-content"

					[ngClass]="{
						'unit-undefined': assetTags.PowerOn == undefined,
						'unit-on': assetTags.PowerOn == true,
						'unit-off': assetTags.PowerOn == false
					}"
					[ngStyle]="{
						color: theme === 'light' ? '#27304c' : 'white'
					}"
				>
					{{ assetTags.PowerOn ? "Power On" : "Power Off" }}
				</div>
				<div
					[openDelay]="300"
					triggers="mouseenter:mouseleave"
					[disablePopover]="!global.User.DebugMode"
					(contextmenu)="onRightClick($event, 'Moving')"
					container="body"
					(mouseenter)="tooltipInformation('Moving')"
					[ngbPopover]="popContent"
					[popoverTitle]="popTitle"
					(click)="
						openTagGraphSingle(
							ObservationIdMappingObjectForTrueFalseValues.Moving
						)
					"
					class="col box-stat-content"
					*ngIf="assetTags.Moving != undefined || global.User.DebugMode"
					[ngClass]="{
						'unit-undefined': assetTags.Moving == undefined,
						'unit-on': assetTags.Moving == true,
						'unit-off': assetTags.Moving == false
					}"
					[ngStyle]="{
						color: theme === 'light' ? '#27304c' : 'white'
					}"
				>
					{{ assetTags.Moving ? "Moving" : "Not Moving" }}
				</div>
				<!-- {{ assetTags.Moving == undefined ? "Moving Und" : assetTags.Moving == true ? "Moving" : "Not Moving" }} -->
				<div
					[openDelay]="300"
					triggers="mouseenter:mouseleave"
					[disablePopover]="!global.User.DebugMode"
					(contextmenu)="onRightClick($event, 'AutoLevelStatus')"
					container="body"
					(mouseenter)="tooltipInformation('AutoLevelStatus')"
					[ngbPopover]="popContent"
					[popoverTitle]="popTitle"
					(click)="
						openTagGraphSingle(
							ObservationIdMappingObjectForTrueFalseValues.AutoLevelStatus
						)
					"
					class="col box-stat-content"
					
					[ngClass]="{
						'unit-undefined': assetTags.AutoLevelStatus == undefined,
						'unit-on': assetTags.AutoLevelStatus == true,
						'unit-off': assetTags.AutoLevelStatus == false
					}"
					[ngStyle]="{
						color: theme === 'light' ? '#27304c' : 'white'
					}"
				>
					{{
						assetTags.AutoLevelStatus
							? "AutoLevel On"
							: "AutoLevel Off"
					}}
				</div>
				<div
					[openDelay]="300"
					triggers="mouseenter:mouseleave"
					[disablePopover]="!global.User.DebugMode"
					(contextmenu)="onRightClick($event, 'AircraftDocked')"
					container="body"
					(mouseenter)="tooltipInformation('AircraftDocked')"
					[ngbPopover]="popContent"
					[popoverTitle]="popTitle"
					(click)="
						openTagGraphSingle(
							ObservationIdMappingObjectForTrueFalseValues.AircraftDocked
						)
					"
					class="col box-stat-content"
					
					[ngClass]="{
						'unit-undefined': assetTags.AircraftDocked == undefined,
						'unit-on': assetTags.AircraftDocked == true,
						'unit-off': assetTags.AircraftDocked == false
					}"
					[ngStyle]="{
						color: theme === 'light' ? '#27304c' : 'white'
					}"
				>
					{{ assetTags.AircraftDocked ? assetTags.AircraftDockedTime : "UnDocked" }}
				</div>
				<div
					[openDelay]="300"
					triggers="mouseenter:mouseleave"
					[disablePopover]="!global.User.DebugMode"
					(contextmenu)="onRightClick($event, 'CanopyDown')"
					container="body"
					(mouseenter)="tooltipInformation('CanopyDown')"
					[ngbPopover]="popContent"
					[popoverTitle]="popTitle"
					(click)="
						openTagGraphSingle(
							ObservationIdMappingObjectForTrueFalseValues.CanopyDown
						)
					"
					class="col box-stat-content"
					
					[ngClass]="{
						'unit-undefined': assetTags.CanopyDown == undefined,
						'unit-on': assetTags.CanopyDown == true,
						'unit-off': assetTags.CanopyDown == false
					}"
					[ngStyle]="{
						color: theme === 'light' ? '#27304c' : 'white'
					}"
				>
					Canopy Down
				</div>
				<div
					[openDelay]="300"
					triggers="mouseenter:mouseleave"
					[disablePopover]="!global.User.DebugMode"
					(contextmenu)="onRightClick($event, 'SlowDownActive')"
					container="body"
					(mouseenter)="tooltipInformation('SlowDownActive')"
					[ngbPopover]="popContent"
					[popoverTitle]="popTitle"
					(click)="
						openTagGraphSingle(
							ObservationIdMappingObjectForTrueFalseValues.SlowDownActive
						)
					"
					class="col box-stat-content"
					
					[ngClass]="{
						'unit-undefined': assetTags.SlowDownActive == undefined,
						'unit-on': assetTags.SlowDownActive == true,
						'unit-off': assetTags.SlowDownActive == false
					}"
					[ngStyle]="{
						color: theme === 'light' ? '#27304c' : 'white'
					}"
				>
					Slow Down
				</div>
			</div>
			<div class="d-flex flex-row" style="justify-content: normal !important">
				<div style="justify-content: normal !important; margin: 1px;"
						[ngClass]="{
							'd-flex flex-row': global.isMobile == true,
							'flex-column': global.isMobile == false
						}"
				>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'AcfStatus')"
						container="body"
						(mouseenter)="tooltipInformation('AcfStatus')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.AcfStatus
							)
						"
						class="col box-stat-content-left"
						*ngIf="assetTags.AcfStatus != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.AcfStatus == undefined,
							'unit-on': assetTags.AcfStatus == true,
							'unit-off': assetTags.AcfStatus == false
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						ACF Floor
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'VentilatorStatus')"
						container="body"
						(mouseenter)="tooltipInformation('VentilatorStatus')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.VentilatorStatus
							)
						"
						class="col box-stat-content-left"
						*ngIf="assetTags.VentilatorStatus != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.VentilatorStatus == undefined,
							'unit-on': assetTags.VentilatorStatus == true,
							'unit-off': assetTags.VentilatorStatus == false
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Ventilator
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'FloodLight')"
						container="body"
						(mouseenter)="tooltipInformation('FloodLight')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.FloodLight
							)
						"
						class="col box-stat-content-left"
						*ngIf="assetTags.FloodLight != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.FloodLight == undefined,
							'unit-on': assetTags.FloodLight == true,
							'unit-off': assetTags.FloodLight == false
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Flood Light
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'GateSign')"
						container="body"
						(mouseenter)="tooltipInformation('GateSign')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.GateSign
							)
						"
						class="col box-stat-content-left"
						*ngIf="assetTags.GateSign != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.GateSign == undefined,
							'unit-on': assetTags.GateSign == true,
							'unit-off': assetTags.GateSign == false
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Gate Sign
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'FloorHeat')"
						container="body"
						(mouseenter)="tooltipInformation('FloorHeat')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.FloorHeat
							)
						"
						class="col box-stat-content-left"
						*ngIf="assetTags.FloorHeat != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.FloorHeat == undefined,
							'unit-on': assetTags.FloorHeat == true,
							'unit-off': assetTags.FloorHeat == false
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Floor Heat
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'WindowHeat')"
						container="body"
						(mouseenter)="tooltipInformation('WindowHeat')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.WindowHeat
							)
						"
						class="col box-stat-content-left"
						*ngIf="assetTags.WindowHeat != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.WindowHeat == undefined,
							'unit-on': assetTags.WindowHeat == true,
							'unit-off': assetTags.WindowHeat == false
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Window Heat
					</div>
				<!-- </div> -->
				</div>
				<div class="d-flex flex-column" *ngIf="camera && viewCamera && global.isMobile == false">
					<iframe
						width="120%"
						height="100%"
						[src]="camera.trustedSourceURL"
					></iframe>
					<button
						title="Switch to Graphic View"
						class="jbt-btn-sm camera-button"
						(click)="switchView(false)"
					>
						<i class="tim-icons icon-image-02"></i>
					</button>
				</div>
				<div class="d-flex flex-column" *ngIf="!viewCamera && global.isMobile == false">
					<img
						[src]="
							assetTags.AircraftDocked
								? global.imagesUrl + 'assets/images/PBBGraphics/Plane_At_Gate.png'
								: global.imagesUrl + 'assets/images/PBBGraphics/Bridge.png'
						"
					/>
					<button
						title="Switch to Live Camera View"
						class="jbt-btn-sm camera-button"
						*ngIf="camera"
						(click)="switchView(true)"
					>
						<i class="tim-icons icon-camera-18"></i>
					</button>
				</div>
			</div>
		</div>
		<div class="d-md-flex flex-column flex-item" style="margin: 1px;">
			<fieldset class="fieldsetstyle">
				<legend class="legendstyle">Interlock/SlowDown</legend>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'AircraftDocked')"
						container="body"
						(mouseenter)="tooltipInformation('AircraftDocked')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.AircraftDocked
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.AircraftDocked != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.AircraftDocked == undefined,
							'unit-on': assetTags.AircraftDocked == true,
							'unit-off': assetTags.AircraftDocked == false
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Docked
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'Hoist1')"
						container="body"
						(mouseenter)="tooltipInformation('Hoist1')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.Hoist1
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.Hoist1 != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.Hoist1 == undefined,
							'unit-on': assetTags.Hoist1 == true,
							'unit-off': assetTags.Hoist1 == false
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Hoist 1
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'DeadmanSwitch')"
						container="body"
						(mouseenter)="tooltipInformation('DeadmanSwitch')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.DeadmanSwitch
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.DeadmanSwitch != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.DeadmanSwitch == undefined,
							'unit-on': assetTags.DeadmanSwitch == true,
							'unit-off': assetTags.DeadmanSwitch == false
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Deadman Switch
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'Hoist2')"
						container="body"
						(mouseenter)="tooltipInformation('Hoist2')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.Hoist2
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.Hoist2 != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.Hoist2 == undefined,
							'unit-on': assetTags.Hoist2 == true,
							'unit-off': assetTags.Hoist2 == false
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Hoist 2
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'VdgsInterlock')"
						container="body"
						(mouseenter)="tooltipInformation('VdgsInterlock')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.VdgsInterlock
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.VdgsInterlock != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.VdgsInterlock == undefined,
							'unit-on': assetTags.VdgsInterlock == true,
							'unit-off': assetTags.VdgsInterlock == false
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						VDGS Interlock
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'Hoist3')"
						container="body"
						(mouseenter)="tooltipInformation('Hoist3')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.Hoist3
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.Hoist3 != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.Hoist3 == undefined,
							'unit-on': assetTags.Hoist3 == true,
							'unit-off': assetTags.Hoist3 == false
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Hoist 3
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'SwingDoorLeft')"
						container="body"
						(mouseenter)="tooltipInformation('SwingDoorLeft')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.SwingDoorLeft
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.SwingDoorLeft != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.SwingDoorLeft == undefined,
							'unit-on': assetTags.SwingDoorLeft == true,
							'unit-off': assetTags.SwingDoorLeft == false
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Swing Door Left
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'CenterIRSlowDown')"
						container="body"
						(mouseenter)="tooltipInformation('CenterIRSlowDown')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.CenterIRSlowDown
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.CenterIRSlowDown != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.CenterIRSlowDown == undefined,
							'unit-on': assetTags.CenterIRSlowDown == true,
							'unit-off': assetTags.CenterIRSlowDown == false
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Center IR SlowDown
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'SwingDoorRight')"
						container="body"
						(mouseenter)="tooltipInformation('SwingDoorRight')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.SwingDoorRight
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.SwingDoorRight != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.SwingDoorRight == undefined,
							'unit-on': assetTags.SwingDoorRight == true,
							'unit-off': assetTags.SwingDoorRight == false
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Swing Door Right
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'LeftIRSlowDown')"
						container="body"
						(mouseenter)="tooltipInformation('LeftIRSlowDown')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.LeftIRSlowDown
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.LeftIRSlowDown != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.LeftIRSlowDown == undefined,
							'unit-on': assetTags.LeftIRSlowDown == true,
							'unit-off': assetTags.LeftIRSlowDown == false
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Left IR SlowDown
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'ServiceStairDoor')"
						container="body"
						(mouseenter)="tooltipInformation('ServiceStairDoor')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.ServiceStairDoor
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.ServiceStairDoor != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.ServiceStairDoor == undefined,
							'unit-on': assetTags.ServiceStairDoor == true,
							'unit-off': assetTags.ServiceStairDoor == false
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Service Stair Door
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'RightIRSlowDown')"
						container="body"
						(mouseenter)="tooltipInformation('RightIRSlowDown')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.RightIRSlowDown
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.RightIRSlowDown != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.RightIRSlowDown == undefined,
							'unit-on': assetTags.RightIRSlowDown == true,
							'unit-off': assetTags.RightIRSlowDow == false
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Right IR SlowDown
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'TerminalDoor')"
						container="body"
						(mouseenter)="tooltipInformation('TerminalDoor')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.TerminalDoor
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.TerminalDoor != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.TerminalDoor == undefined,
							'unit-on': assetTags.TerminalDoor == true,
							'unit-off': assetTags.TerminalDoor == false
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Terminal Door
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'ForwardSlowDown')"
						container="body"
						(mouseenter)="tooltipInformation('ForwardSlowDown')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.ForwardSlowDown
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.ForwardSlowDown != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.ForwardSlowDown == undefined,
							'unit-on': assetTags.ForwardSlowDown == true,
							'unit-off': assetTags.ForwardSlowDown == false
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Forward SlowDown
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'BagLiftInterlock')"
						container="body"
						(mouseenter)="tooltipInformation('BagLiftInterlock')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.BagLiftInterlock
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.BagLiftInterlock != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.BagLiftInterlock == undefined,
							'unit-on': assetTags.BagLiftInterlock == true,
							'unit-off': assetTags.BagLiftInterlock == false
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Bag Lift Interlock
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'ReverseSlowDown')"
						container="body"
						(mouseenter)="tooltipInformation('ReverseSlowDown')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.ReverseSlowDown
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.ReverseSlowDown != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.ReverseSlowDown == undefined,
							'unit-on': assetTags.ReverseSlowDown == true,
							'unit-off': assetTags.ReverseSlowDown == false
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Reverse SlowDown
					</div>
				</div>
			</fieldset>
		</div>
	
		<div class="d-md-flex flex-column flex-item" style="margin: 1px;">
			<fieldset class="fieldsetstyle">
				<legend class="legendstyle">Alarms</legend>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'AutolevelAlarm')"
						container="body"
						(mouseenter)="tooltipInformation('AutolevelAlarm')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.AutolevelAlarm
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.AutolevelAlarm != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.AutolevelAlarm == undefined,
							'unit-alarm': assetTags.AutolevelAlarm == true,
							'unit-off': assetTags.AutolevelAlarm == false
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Autolevel Failure
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="
							onRightClick($event, 'HorizInverterFault')
						"
						container="body"
						(mouseenter)="tooltipInformation('HorizInverterFault')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.HorizInverterFault
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.HorizInverterFault != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.HorizInverterFault == undefined,
							'unit-alarm': assetTags.HorizInverterFault == true,
							'unit-off': assetTags.HorizInverterFault == false
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Horizontal Inverter
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'EstopMain')"
						container="body"
						(mouseenter)="tooltipInformation('EstopMain')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.EstopMain
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.EstopMain != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.EstopMain == undefined,
							'unit-alarm': assetTags.EstopMain == true,
							'unit-off': assetTags.EstopMain == false
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						E-Stop Main
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'AcfFault')"
						container="body"
						(mouseenter)="tooltipInformation('AcfFault')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.AcfFault
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.AcfFault != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.AcfFault == undefined,
							'unit-alarm': assetTags.AcfFault == true,
							'unit-off': assetTags.AcfFault == false
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						ACF Floor
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'EstopDriveRight')"
						container="body"
						(mouseenter)="tooltipInformation('EstopDriveRight')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.EstopDriveRight
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.EstopDriveRight != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.EstopDriveRight == undefined,
							'unit-alarm': assetTags.EstopDriveRight == true,
							'unit-off': assetTags.EstopDriveRight == false
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						E-Stop Drive Col Right
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'SpacerBypassPB')"
						container="body"
						(mouseenter)="tooltipInformation('SpacerBypassPB')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.SpacerBypassPB
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.SpacerBypassPB != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.SpacerBypassPB == undefined,
							'unit-alarm': assetTags.SpacerBypassPB == true,
							'unit-off': assetTags.SpacerBypassPB == false
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Spacer Bypass PB
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'EstopDriveLeft')"
						container="body"
						(mouseenter)="tooltipInformation('EstopDriveLeft')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.EstopDriveLeft
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.EstopDriveLeft != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.EstopDriveLeft == undefined,
							'unit-alarm': assetTags.EstopDriveLeft == true,
							'unit-off': assetTags.EstopDriveLeft == false
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						E-Stop Drive Col Left
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="
							onRightClick($event, 'VerticalOverloadLeft')
						"
						container="body"
						(mouseenter)="
							tooltipInformation('VerticalOverloadLeft')
						"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.VerticalOverloadLeft
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.VerticalOverloadLeft != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.VerticalOverloadLeft == undefined,
							'unit-alarm': assetTags.VerticalOverloadLeft == true,
							'unit-off': assetTags.VerticalOverloadLeft == false
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Vertical Left Overload
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'SmokeDetector')"
						container="body"
						(mouseenter)="tooltipInformation('SmokeDetector')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.SmokeDetector
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.SmokeDetector != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.SmokeDetector == undefined,
							'unit-alarm': assetTags.SmokeDetector == true,
							'unit-off': assetTags.SmokeDetector == false
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Smoke Detector
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="
							onRightClick($event, 'VerticalOverloadRight')
						"
						container="body"
						(mouseenter)="
							tooltipInformation('VerticalOverloadRight')
						"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.VerticalOverloadRight
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.VerticalOverloadRight != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.VerticalOverloadRight == undefined,
							'unit-alarm': assetTags.VerticalOverloadRight == true,
							'unit-off': assetTags.VerticalOverloadRight == false
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Vertical Right Overload
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'VerticalRack')"
						container="body"
						(mouseenter)="tooltipInformation('VerticalRack')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.VerticalRack
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.VerticalRack != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.VerticalRack == undefined,
							'unit-alarm': assetTags.VerticalRack == true,
							'unit-off': assetTags.VerticalRack == false
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Vertical Rack
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="
							onRightClick($event, 'VerticalDownWeldFault')
						"
						container="body"
						(mouseenter)="
							tooltipInformation('VerticalDownWeldFault')
						"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.VerticalDownWeldFault
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.VerticalDownWeldFault != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.VerticalDownWeldFault == undefined,
							'unit-alarm': assetTags.VerticalDownWeldFault == true,
							'unit-off': assetTags.VerticalDownWeldFault == false
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Vertical Down Weld
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="
							onRightClick($event, 'SafetyShoeNotConnected')
						"
						container="body"
						(mouseenter)="
							tooltipInformation('SafetyShoeNotConnected')
						"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.SafetyShoeNotConnected
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.SafetyShoeNotConnected != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.SafetyShoeNotConnected == undefined,
							'unit-alarm': assetTags.SafetyShoeNotConnected == true,
							'unit-off': assetTags.SafetyShoeNotConnected == false
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Safety Shoe Not Connected
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'CabLeftWeldFault')"
						container="body"
						(mouseenter)="tooltipInformation('CabLeftWeldFault')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.CabLeftWeldFault
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.CabLeftWeldFault != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.CabLeftWeldFault == undefined,
							'unit-alarm': assetTags.CabLeftWeldFault == true,
							'unit-off': assetTags.CabLeftWeldFault == false
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Cab Left Weld
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="
							onRightClick($event, 'HydraulicMotorWeldFault')
						"
						container="body"
						(mouseenter)="
							tooltipInformation('HydraulicMotorWeldFault')
						"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.HydraulicMotorWeldFault
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.HydraulicMotorWeldFault != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.HydraulicMotorWeldFault == undefined,
							'unit-alarm': assetTags.HydraulicMotorWeldFault == true,
							'unit-off': assetTags.HydraulicMotorWeldFault == false
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Hydraulic Motor Weld
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="
							onRightClick($event, 'MotionEnableWeldFault')
						"
						container="body"
						(mouseenter)="
							tooltipInformation('MotionEnableWeldFault')
						"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.MotionEnableWeldFault
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.MotionEnableWeldFault != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.MotionEnableWeldFault == undefined,
							'unit-alarm': assetTags.MotionEnableWeldFault == true,
							'unit-off': assetTags.MotionEnableWeldFault == false
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Motion Enable Weld
					</div>
				</div>
			</fieldset>
		</div>
		<div class="d-md-flex flex-column flex-item" style="margin: 1px;">
			<fieldset class="fieldsetstyle">
				<legend class="legendstyle">Limits</legend>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'CabLeftLimit')"
						container="body"
						(mouseenter)="tooltipInformation('CabLeftLimit')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.CabLeftLimit
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.CabLeftLimit != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.CabLeftLimit == undefined,
							'unit-on': assetTags.CabLeftLimit == true,
							'unit-off': assetTags.CabLeftLimit == false,
							WarningBackground:
								assetTags.CabLeftLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.CabLeftLimit) === 'Warning',
							AlarmBackground:
								assetTags.CabLeftLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.CabLeftLimit) === 'Alarm',
							CriticalBackground:
								assetTags.CabLeftLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.CabLeftLimit) === 'Critical'
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Cab Left
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'CabLeftUltimate')"
						container="body"
						(mouseenter)="tooltipInformation('CabLeftUltimate')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.CabLeftUltimate
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.CabLeftUltimate != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.CabLeftUltimate == undefined,
							'unit-on': assetTags.CabLeftUltimate == true,
							'unit-off': assetTags.CabLeftUltimate == false,
							WarningBackground:
								assetTags.CabLeftUltimate &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.CabLeftUltimate) === 'Warning',
							AlarmBackground:
								assetTags.CabLeftUltimate &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.CabLeftUltimate) === 'Alarm',
							CriticalBackground:
								assetTags.CabLeftLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.CabLeftUltimate) === 'Critical'
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Cab Left Ult.
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'CabRightLimit')"
						container="body"
						(mouseenter)="tooltipInformation('CabRightLimit')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.CabRightLimit
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.CabRightLimit != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.CabRightLimit == undefined,
							'unit-on': assetTags.CabRightLimit == true,
							'unit-off': assetTags.CabRightLimit == false,
							WarningBackground:
								assetTags.CabRightLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.CabRightLimit) === 'Warning',
							AlarmBackground:
								assetTags.CabRightLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.CabRightLimit) === 'Alarm',
							CriticalBackground:
								assetTags.CabLeftLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.CabRightLimit) === 'Critical'
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Cab Right
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'CabRightUltimate')"
						container="body"
						(mouseenter)="tooltipInformation('CabRightUltimate')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.CabRightUltimate
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.CabRightUltimate != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.CabRightUltimate == undefined,
							'unit-on': assetTags.CabRightUltimate == true,
							'unit-off': assetTags.CabRightUltimate == false,
							WarningBackground:
								assetTags.CabRightUltimate &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.CabRightUltimate) === 'Warning',
							AlarmBackground:
								assetTags.CabRightUltimate &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.CabRightUltimate) === 'Alarm',
							CriticalBackground:
								assetTags.CabLeftLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.CabRightUltimate) === 'Critical'
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Cab Right Ult.
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'SwingLeftLimit')"
						container="body"
						(mouseenter)="tooltipInformation('SwingLeftLimit')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.SwingLeftLimit
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.SwingLeftLimit != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.SwingLeftLimit == undefined,
							'unit-on': assetTags.SwingLeftLimit == true,
							'unit-off': assetTags.SwingLeftLimit == false,
							WarningBackground:
								assetTags.SwingLeftLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.SwingLeftLimit) === 'Warning',
							AlarmBackground:
								assetTags.SwingLeftLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.SwingLeftLimit) === 'Alarm',
							CriticalBackground:
								assetTags.CabLeftLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.SwingLeftLimit) === 'Critical'
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Swing Left
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="
							onRightClick($event, 'VerticalDownLimit')
						"
						container="body"
						(mouseenter)="tooltipInformation('VerticalDownLimit')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.VerticalDownLimit
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.VerticalDownLimit != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.VerticalDownLimit == undefined,
							'unit-on': assetTags.VerticalDownLimit == true,
							'unit-off': assetTags.VerticalDownLimit == false,
							WarningBackground:
								assetTags.VerticalDownLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.VerticalDownLimit) === 'Warning',
							AlarmBackground:
								assetTags.VerticalDownLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.VerticalDownLimit) === 'Alarm',
							CriticalBackground:
								assetTags.CabLeftLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.VerticalDownLimit) === 'Critical'
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Vertical Down
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'SwingRightLimit')"
						container="body"
						(mouseenter)="tooltipInformation('SwingRightLimit')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.SwingRightLimit
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.SwingRightLimit != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.SwingRightLimit == undefined,
							'unit-on': assetTags.SwingRightLimit == true,
							'unit-off': assetTags.SwingRightLimit == false,
							WarningBackground:
								assetTags.SwingRightLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.SwingRightLimit) === 'Warning',
							AlarmBackground:
								assetTags.SwingRightLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.SwingRightLimit) === 'Alarm',
							CriticalBackground:
								assetTags.CabLeftLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.SwingRightLimit) === 'Critical'
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Swing Right
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'VerticalUpLimit')"
						container="body"
						(mouseenter)="tooltipInformation('VerticalUpLimit')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.VerticalUpLimit
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.VerticalUpLimit != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.VerticalUpLimit == undefined,
							'unit-on': assetTags.VerticalUpLimit == true,
							'unit-off': assetTags.VerticalUpLimit == false,
							WarningBackground:
								assetTags.VerticalUpLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.VerticalUpLimit) === 'Warning',
							AlarmBackground:
								assetTags.VerticalUpLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.VerticalUpLimit) === 'Alarm',
							CriticalBackground:
								assetTags.CabLeftLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.VerticalUpLimit) === 'Critical'
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Vertical Up
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'SlopeUpLimit')"
						container="body"
						(mouseenter)="tooltipInformation('SlopeUpLimit')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.SlopeUpLimit
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.SlopeUpLimit != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.SlopeUpLimit == undefined,
							'unit-on': assetTags.SlopeUpLimit == true,
							'unit-off': assetTags.SlopeUpLimit == false,
							WarningBackground:
								assetTags.SlopeUpLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(
									ObservationIdMappingObjectForTrueFalseValues.SlopeUpLimit
								) === 'Warning',
							AlarmBackground:
								assetTags.SlopeUpLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(
									ObservationIdMappingObjectForTrueFalseValues.SlopeUpLimit
								) === 'Alarm',
							CriticalBackground:
								assetTags.SlopeUpLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(
									ObservationIdMappingObjectForTrueFalseValues.SlopeUpLimit
								) === 'Critical'
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Slope Up
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'LeftSpacerLimit')"
						container="body"
						(mouseenter)="tooltipInformation('LeftSpacerLimit')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.LeftSpacerLimit
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.LeftSpacerLimit != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.LeftSpacerLimit == undefined,
							'unit-on': assetTags.LeftSpacerLimit == true,
							'unit-off': assetTags.LeftSpacerLimit == false,
							WarningBackground:
								assetTags.LeftSpacerLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(
									ObservationIdMappingObjectForTrueFalseValues.LeftSpacerLimit
								) === 'Warning',
							AlarmBackground:
								assetTags.LeftSpacerLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(
									ObservationIdMappingObjectForTrueFalseValues.LeftSpacerLimit
								) === 'Alarm',
							CriticalBackground:
								assetTags.LeftSpacerLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(
									ObservationIdMappingObjectForTrueFalseValues.LeftSpacerLimit
								) === 'Critical'
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Left Spacer
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'SlopeDownLimit')"
						container="body"
						(mouseenter)="tooltipInformation('SlopeDownLimit')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.SlopeDownLimit
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.SlopeDownLimit != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.SlopeDownLimit == undefined,
							'unit-on': assetTags.SlopeDownLimit == true,
							'unit-off': assetTags.SlopeDownLimit == false,
							WarningBackground:
								assetTags.SlopeDownLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.SlopeDownLimit) === 'Warning',
							AlarmBackground:
								assetTags.LeftSpacerLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.SlopeDownLimit) === 'Alarm',
							CriticalBackground:
								assetTags.SlopeDownLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.SlopeDownLimit) === 'Critical'
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Slope Dow
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'RightSpacerLimit')"
						container="body"
						(mouseenter)="tooltipInformation('RightSpacerLimit')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.RightSpacerLimit
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.RightSpacerLimit != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.RightSpacerLimit == undefined,
							'unit-on': assetTags.RightSpacerLimit == true,
							'unit-off': assetTags.RightSpacerLimit == false,
							WarningBackground:
								assetTags.RightSpacerLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.RightSpacerLimit) === 'Warning',
							AlarmBackground:
								assetTags.LeftSpacerLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.RightSpacerLimit) === 'Alarm',
							CriticalBackground:
								assetTags.RightSpacerLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.RightSpacerLimit) === 'Critical'
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Right Spacer
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'LimitsDisabled')"
						container="body"
						(mouseenter)="tooltipInformation('LimitsDisabled')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.LimitsDisabled
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.LimitsDisabled != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.LimitsDisabled == undefined,
							'unit-on': assetTags.LimitsDisabled == true,
							'unit-off': assetTags.LimitsDisabled == false,
							WarningBackground:
								assetTags.LimitsDisabled &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.LimitsDisabled) === 'Warning',
							AlarmBackground:
								assetTags.LeftSpacerLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.LimitsDisabled) === 'Alarm',
							CriticalBackground:
								assetTags.LimitsDisabled &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.LimitsDisabled) === 'Critical'
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Limits Disabled
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="
							onRightClick($event, 'HorizontalExtendLimit')
						"
						container="body"
						(mouseenter)="
							tooltipInformation('HorizontalExtendLimit')
						"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.HorizontalExtendLimit
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.HorizontalExtendLimit != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.HorizontalExtendLimit == undefined,
							'unit-on': assetTags.HorizontalExtendLimit == true,
							'unit-off': assetTags.HorizontalExtendLimit == false,
							WarningBackground:
								assetTags.HorizontalExtendLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.HorizontalExtendLimit) === 'Warning',
							AlarmBackground:
								assetTags.LeftSpacerLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.HorizontalExtendLimit) === 'Alarm',
							CriticalBackground:
								assetTags.HorizontalExtendLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.HorizontalExtendLimit) === 'Critical'
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Horizontal Extend
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="
							onRightClick($event, 'HydraulicMotorOverload')
						"
						container="body"
						(mouseenter)="
							tooltipInformation('HydraulicMotorOverload')
						"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.HydraulicMotorOverload
							)
						"
						title="Hydraulic Motor Overload"
						class="col box-stat-content"
						*ngIf="assetTags.HydraulicMotorOverload != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.HydraulicMotorOverload == undefined,
							'unit-on': assetTags.HydraulicMotorOverload == true,
							'unit-off': assetTags.HydraulicMotorOverload == false,
							WarningBackground:
								assetTags.HydraulicMotorOverload &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.HydraulicMotorOverload) === 'Warning',
							AlarmBackground:
								assetTags.LeftSpacerLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.HydraulicMotorOverload) === 'Alarm',
							CriticalBackground:
								assetTags.HydraulicMotorOverload &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.HydraulicMotorOverload) === 'Critical'

						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Hydraulic M Overload
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="
							onRightClick($event, 'HorizontalRetractLimit')
						"
						container="body"
						(mouseenter)="
							tooltipInformation('HorizontalRetractLimit')
						"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.HorizontalRetractLimit
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.HorizontalRetractLimit != undefined || global.User.DebugMode"
						[ngClass]="{
							'unit-undefined': assetTags.HorizontalRetractLimit == undefined,
							'unit-on': assetTags.HorizontalRetractLimit == true,
							'unit-off': assetTags.HorizontalRetractLimit == false,
							WarningBackground:
								assetTags.HorizontalRetractLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.HorizontalRetractLimit) === 'Warning',
							AlarmBackground:
								assetTags.LeftSpacerLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.HorizontalRetractLimit) === 'Alarm',
							CriticalBackground:
								assetTags.HorizontalRetractLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.HorizontalRetractLimit) === 'Critical'
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Horizontal Retract
					</div>
				</div>
			</fieldset>
		</div>
		<div class="d-md-flex flex-column flex-item" style="margin: 1px;">
			<fieldset class="fieldsetstyle">
				<legend class="legendstyle">Analog I/O</legend>
				<div class="d-flex flex-row">
					<div
						title="Wheel Bogie Limit Low"
						class="col aligntext labeldiv"
						
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Wheel Bogie L-L
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="
							onRightClick($event, 'WheelBogieLimitLow')
						"
						container="body"
						(mouseenter)="tooltipInformation('WheelBogieLimitLow')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForParseFloatValues.WheelBogieLimitLow
							)
						"
						class="col box-stat-content widthdiv"
						
						[ngClass]="{
							
							'unit-on': assetTags.AircraftDocked,
							'unit-off': !assetTags.AircraftDocked
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						{{ assetTags.WheelBogieLimitLow }}
					</div>
					<div
						title="Cab Limit Low"
						class="col aligntext labeldiv"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Cab L-L
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'CabLimitLow')"
						container="body"
						(mouseenter)="tooltipInformation('CabLimitLow')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForParseFloatValues.CabLimitLow
							)
						"
						class="col box-stat-content widthdiv"
						*ngIf="assetTags.CabLimitLow != undefined"
						[ngClass]="{
							'unit-on': assetTags.AircraftDocked,
							'unit-off': !assetTags.AircraftDocked
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						{{ assetTags.CabLimitLow }}
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						title="Wheel Bogie Limit High"
						class="col aligntext labeldiv"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Wheel Bogie L-H
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="
							onRightClick($event, 'WheelBogieLimitHigh')
						"
						container="body"
						(mouseenter)="tooltipInformation('WheelBogieLimitHigh')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForParseFloatValues.WheelBogieLimitHigh
							)
						"
						class="col box-stat-content widthdiv"
						*ngIf="assetTags.WheelBogieLimitHigh != undefined"
						[ngClass]="{
							'unit-on': assetTags.AircraftDocked,
							'unit-off': !assetTags.AircraftDocked
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						{{ assetTags.WheelBogieLimitHigh }}
					</div>
					<div
						title="Cab Limit High"
						class="col aligntext labeldiv"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Cab L-H
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'CabLimitHigh')"
						container="body"
						(mouseenter)="tooltipInformation('CabLimitHigh')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForParseFloatValues.CabLimitHigh
							)
						"
						class="col box-stat-content widthdiv"
						*ngIf="assetTags.CabLimitHigh != undefined"
						[ngClass]="{
							'unit-on': assetTags.AircraftDocked,
							'unit-off': !assetTags.AircraftDocked
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						{{ assetTags.CabLimitHigh }}
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						title="Height Limit Low"
						class="col aligntext labeldiv"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Height L-L
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'HeightLimitLow')"
						container="body"
						(mouseenter)="tooltipInformation('HeightLimitLow')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForParseFloatValues.HeightLimitLow
							)
						"
						class="col box-stat-content widthdiv"
						*ngIf="assetTags.HeightLimitLow != undefined"
						[ngClass]="{
							'unit-on': assetTags.AircraftDocked,
							'unit-off': !assetTags.AircraftDocked
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						{{ assetTags.HeightLimitLow }}
					</div>
					<div
						title="Swing Limit Low"
						class="col aligntext labeldiv"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Swing L-L
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'SwingLimitLow')"
						container="body"
						(mouseenter)="tooltipInformation('SwingLimitLow')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForParseFloatValues.SwingLimitLow
							)
						"
						class="col box-stat-content widthdiv"
						*ngIf="assetTags.SwingLimitLow != undefined"
						[ngClass]="{
							'unit-on': assetTags.AircraftDocked,
							'unit-off': !assetTags.AircraftDocked
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						{{ assetTags.SwingLimitLow }}
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						title="Height Limit High"
						class="col aligntext labeldiv"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Height L-H
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'HeightLimitHigh')"
						container="body"
						(mouseenter)="tooltipInformation('HeightLimitHigh')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForParseFloatValues.HeightLimitHigh
							)
						"
						class="col box-stat-content widthdiv"
						*ngIf="assetTags.HeightLimitHigh != undefined"
						[ngClass]="{
							'unit-on': assetTags.AircraftDocked,
							'unit-off': !assetTags.AircraftDocked
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						{{ assetTags.HeightLimitHigh }}
					</div>
					<div
						title="Swing Limit High"
						class="col aligntext labeldiv"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Swing L-H
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'SwingLimitHigh')"
						container="body"
						(mouseenter)="tooltipInformation('SwingLimitHigh')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForParseFloatValues.SwingLimitHigh
							)
						"
						class="col box-stat-content widthdiv"
						*ngIf="assetTags.SwingLimitHigh != undefined"
						[ngClass]="{
							'unit-on': assetTags.AircraftDocked,
							'unit-off': !assetTags.AircraftDocked
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						{{ assetTags.SwingLimitHigh }}
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						title="Joystick Left Right"
						class="col aligntext labeldiv"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Joystick L-R
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="
							onRightClick($event, 'JoystickLeftRight')
						"
						container="body"
						(mouseenter)="tooltipInformation('JoystickLeftRight')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForParseFloatValues.JoystickLeftRight
							)
						"
						class="col box-stat-content widthdiv"
						*ngIf="assetTags.JoystickLeftRight != undefined"
						[ngClass]="{
							'unit-on': assetTags.AircraftDocked,
							'unit-off': !assetTags.AircraftDocked
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						{{ assetTags.JoystickLeftRight }}
					</div>
					<div class="col aligntext"></div>
					<div class="col widthdiv"></div>
				</div>
				<div class="d-flex flex-row">
					<div
						title="Joystick Forward Reverse"
						class="col aligntext labeldiv"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Joystick F-R
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="
							onRightClick($event, 'JoystickForwardReverse')
						"
						container="body"
						(mouseenter)="
							tooltipInformation('JoystickForwardReverse')
						"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForParseFloatValues.JoystickForwardReverse
							)
						"
						class="col box-stat-content widthdiv"
						*ngIf="assetTags.JoystickForwardReverse != undefined"
						[ngClass]="{
							'unit-on': assetTags.AircraftDocked,
							'unit-off': !assetTags.AircraftDocked
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						{{ assetTags.JoystickForwardReverse }}
					</div>
					<div class="col aligntext"></div>
					<div class="col widthdiv"></div>
				</div>
				<div class="d-flex flex-row">
					<div
						title="Extend Limit Low"
						class="col aligntext labeldiv"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Extend L-L
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'ExtendlimitLow')"
						container="body"
						(mouseenter)="tooltipInformation('ExtendlimitLow')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForParseFloatValues.ExtendlimitLow
							)
						"
						class="col box-stat-content widthdiv"
						*ngIf="assetTags.ExtendlimitLow != undefined"
						[ngClass]="{
							'unit-on': assetTags.AircraftDocked,
							'unit-off': !assetTags.AircraftDocked
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						{{ assetTags.ExtendlimitLow }}
					</div>
					<div class="col aligntext"></div>
					<div class="col widthdiv"></div>
				</div>
				<div class="d-flex flex-row">
					<div
						title="Extend Limit High"
						class="col aligntext labeldiv"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Extend L-H
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'ExtendlimitHigh')"
						container="body"
						(mouseenter)="tooltipInformation('ExtendlimitHigh')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForParseFloatValues.ExtendlimitHigh
							)
						"
						class="col box-stat-content widthdiv"
						*ngIf="assetTags.ExtendlimitHigh != undefined"
						[ngClass]="{
							'unit-on': assetTags.AircraftDocked,
							'unit-off': !assetTags.AircraftDocked
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						{{ assetTags.ExtendlimitHigh }}
					</div>
					<div class="col aligntext"></div>
					<div class="col widthdiv"></div>
				</div>
			</fieldset>
		</div>
	</div>
</div>
