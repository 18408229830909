import {
	Component,
	AfterViewInit,
	OnDestroy,
	Optional,
	Inject,
	ChangeDetectorRef,
	EventEmitter,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import {
	MAT_DIALOG_DATA,
	MatDialogRef,
	MatDialog,
} from '@angular/material/dialog';
import { DataService } from '../../services/data.service';
import _ from 'lodash';

import { DashboardService } from '../../services/dashboard.service';
import { Global } from '../../_constants/global.variables';
import { IGlobal } from '../../_models/global.model';
import { FileUploadListComponent } from '../file-upload-list/file-upload-list.component';
import { AlertManagementPopupComponent } from '../alert-management-popup/alert-management-popup.component';
import { Router } from '@angular/router';
import { SignalRCoreService } from '../../services/signalr-core.service';
import { Observable, filter } from 'rxjs';


@Component({
	selector: 'lib-dialog-modal-parent',
	templateUrl: './dialog-modal-parent.component.html',
	styleUrls: ['./dialog-modal-parent.component.scss'],
})
export class DialogModalParentComponent implements AfterViewInit, OnDestroy {
	public global: IGlobal = Global;
	@ViewChild('lazyLoadedWidgetInsert', { read: ViewContainerRef })
	lazyLoadedWidgetInsert!: ViewContainerRef;

	alertNotificationColor: any;
	alertNotificationTitle: any;
	widget: any;
	isFullScreen = false;
	searchboxDisabled: boolean;
	searchString: string;
	timerId: NodeJS.Timeout;
	public fileImageData: any;
	public FileImageLibraryControlObject: any;
	private assetId: number;
	public timeZoneChangedOnSingleTagTrendEvent: EventEmitter<any> =
		new EventEmitter();

	private componentName: string = 'dialog-modal-component';
	sub: any;
	childComponent: any;
	signalRSubscription: any;
	VocationalSettingsJSON: any;

	constructor(
		@Optional() @Inject(MAT_DIALOG_DATA) public dialogModalInputObject: any,
		@Optional() public dialogRef: MatDialogRef<DialogModalParentComponent>,
		public dataService: DataService,
		private ref: ChangeDetectorRef,
		public dialog: MatDialog,
		public dashboardService: DashboardService,
		private router: Router,
		private signalRCore: SignalRCoreService
	) {}

	createAssetSummaryWidget() {
		let assetSummaryObject: any;
		let assetSummaryCacheObject: any;
		if (this.dialogModalInputObject.WidgetName == 'PBB-Summary') {
			assetSummaryCacheObject = this.dataService.cache.widgetTypes.filter(
				(n: any) => {
					return n.AngularDirectiveName == 'pbbSummary';
				}
			);
		} else if (this.dialogModalInputObject.WidgetName == 'PCA-Summary') {
			assetSummaryCacheObject = this.dataService.cache.widgetTypes.filter(
				(n: any) => {
					return n.AngularDirectiveName == 'pcaSummary';
				}
			);
		} else if (this.dialogModalInputObject.WidgetName == 'GPU-Summary') {
			assetSummaryCacheObject = this.dataService.cache.widgetTypes.filter(
				(n: any) => {
					return n.AngularDirectiveName == 'gpuSummary';
				}
			);
		} else if (this.dialogModalInputObject.WidgetName == 'AHU-Summary') {
			assetSummaryCacheObject = this.dataService.cache.widgetTypes.filter(
				(n: any) => {
					return n.AngularDirectiveName == 'ahuSummary';
				}
			);
		} else if (this.dialogModalInputObject.WidgetName == 'WCL-Summary') {
			assetSummaryCacheObject = this.dataService.cache.widgetTypes.filter(
				(n: any) => {
					return n.AngularDirectiveName == 'wheelChairSummary';
				}
			);
		} else if (this.dialogModalInputObject.WidgetName == 'CU-Summary') {
			assetSummaryCacheObject = this.dataService.cache.widgetTypes.filter(
				(n: any) => {
					return n.AngularDirectiveName == 'centralUnitsSummary';
				}
			);
		}

		assetSummaryObject = _.cloneDeep(assetSummaryCacheObject.first());
		Global.User.DebugMode &&
			console.log(
				this.componentName + ': assetSummaryObject = %O',
				assetSummaryObject
			);
		assetSummaryObject.WidgetTypeId = assetSummaryObject.Id;
		assetSummaryObject.WidgetAssetName =
			this.dialogModalInputObject.assetObj.WidgetAssetName;
		assetSummaryObject.WidgetName =
			this.dialogModalInputObject.assetObj.Type + ' Summary';
		assetSummaryObject.WidgetSiteId =
			this.dialogModalInputObject.assetObj.SiteId;
		assetSummaryObject.WidgetSiteName =
			this.dialogModalInputObject.assetObj.SiteName;
		assetSummaryObject.WidgetGateSystemName =
			this.dialogModalInputObject.assetObj.GateName;
		assetSummaryObject.WidgetGateSystemId =
			this.dialogModalInputObject.assetObj.GateId;

		assetSummaryObject.WidgetTerminalName =
			this.dialogModalInputObject.assetObj.TerminalName;
		assetSummaryObject.WidgetTerminalSystemId =
			this.dialogModalInputObject.assetObj.TerminalId;
		assetSummaryObject.WidgetId = '';
		assetSummaryObject.AssetId = this.dialogModalInputObject.assetObj.Id;
		assetSummaryObject.cols = 5;
		assetSummaryObject.rows = 5;
		delete assetSummaryObject.Id;

		this.dataService.createNewDashboardWidget(assetSummaryObject);
		this.dialogRef.close();
	}

	createGSESummaryWidget() {
		const gseSummaryWidgetType = this.dataService.cache.widgetTypes.filter(
			(n: any) => {
				return n.AngularDirectiveName == 'gseSummary';
			}
		);

		let gseSummaryWidgetObject = _.cloneDeep(gseSummaryWidgetType[0]);
		Global.User.DebugMode &&
			console.log(
				this.componentName + ': gseSummaryWidgetObject = %O',
				gseSummaryWidgetObject
			);
		gseSummaryWidgetObject.WidgetTypeId = gseSummaryWidgetObject.Id;
		gseSummaryWidgetObject.WidgetName = gseSummaryWidgetObject.Name;

		gseSummaryWidgetObject.cols = 5;
		gseSummaryWidgetObject.rows = 5;
		delete gseSummaryWidgetObject.Id;
		gseSummaryWidgetObject.WidgetId = '';
		Global.User.DebugMode &&
			console.log(
				this.componentName + ': gseSummaryWidgetType = %O',
				gseSummaryWidgetType
			);


		if(this.VocationalSettingsJSON) {
			gseSummaryWidgetObject.VocationalSettingsJSON = this.VocationalSettingsJSON;
		}
		else {
			gseSummaryWidgetObject.WidgetSiteId = this.dialogModalInputObject
				.widgetObject.Site?.Id
				? this.dialogModalInputObject.widgetObject.Site.Id
				: this.dialogModalInputObject.widgetObject.SiteId
				? this.dialogModalInputObject.widgetObject.SiteId
				: undefined;
			gseSummaryWidgetObject.AssetId =
				this.dialogModalInputObject.widgetObject.Id;
			gseSummaryWidgetObject.WidgetAssetId =
				this.dialogModalInputObject.widgetObject.Id;

		}

		//pass gseSummaryWidgetObject into dashbaord service and emit to dashbaord
		this.dataService.createNewDashboardWidget(gseSummaryWidgetObject);
		this.dialogRef.close();
	}

	widgetEvaluation() {
		if (
			this.dialogModalInputObject.WidgetName === 'Kendo-Grid' ||
			this.dialogModalInputObject.WidgetName === 'WCL-Summary' ||
			this.dialogModalInputObject.WidgetName === 'CU-Summary' ||
			this.dialogModalInputObject.WidgetName === 'GPU-Summary' ||
			this.dialogModalInputObject.WidgetName === 'AHU-Summary' ||
			this.dialogModalInputObject.WidgetName === 'PBB-Summary' ||
			this.dialogModalInputObject.WidgetName === 'PCA-Summary' ||
			this.dialogModalInputObject.WidgetName ===
				'GS-Top-Five-Alarms-Summary' ||
			this.dialogModalInputObject.WidgetName ===
				'GS-Top-Five-Equipment-Alarms-Summary' ||
			this.dialogModalInputObject.WidgetName ===
				'GSE-Top-Five-Equipment-Alarms-Summary' ||
			this.dialogModalInputObject.WidgetName ===
				'GSE-Top-Five-Alarms-Summary'
		) {
			return true;
		}
	}

	ngAfterViewInit() {
		if(this.dialogModalInputObject.description && this.dialogModalInputObject.description == 'Notes list') {

			this.signalRSubscription = this.signalRCore.broadcastMessages$.pipe(filter((msg: any) =>
				msg.code == "dataService.cache.entityLogs Added" ||
				msg.code == "dataService.cache.entityLogs Modified" ||
				msg.code == "dataService.cache.entityLogs Deleted"
				)).subscribe((msg: any) => {
					let objectId = msg.object;
					if(this.dataService.cache.entityLogsObject[objectId] == null ||
						this.dataService.cache.entityLogsObject[objectId]?.EntityId == this.dialogModalInputObject.entityData.EntityId ||
						this.dialogModalInputObject.entityData.EntityId == null
					) {
						this.updateLogsFromCache();
					}
			});

		}
		this.sub = this.router.events.subscribe((event) => {
			console.log(event)
			this.onCancel();
		});

		Global.User.DebugMode &&
			console.log(
				this.componentName + ': dialogModalInputObject = %O',
				this.dialogModalInputObject
			);

		if (!this.dialogModalInputObject.assetObj) {
			if (
				this.dialogModalInputObject.WidgetName !=
					'GSE-Top-Five-Alarms-Summary' &&
				this.dialogModalInputObject.WidgetName !=
					'GSE-Top-Five-Equipment-Alarms-Summary'
			) {
				this.assetId = this.dialogModalInputObject?.widgetObject?.Id;
			} else {
				if (
					this.dialogModalInputObject.WidgetName ==
					'GSE-Top-Five-Equipment-Alarms-Summary'
				) {
					this.assetId = this.dialogModalInputObject?.assetId;
				}
			}
		} else {
			this.assetId = this.dialogModalInputObject?.assetObj?.Id;
		}

		if(this.dialogModalInputObject.parentWidget?.VocationalSettingsJSON) {
			var VocationalSettings = JSON.parse(this.dialogModalInputObject.parentWidget.VocationalSettingsJSON);

			let fleetId = VocationalSettings.id;
			const fleetConfig = {
				type: "Fleet",
				id: fleetId,
				child: {
					type: "Asset",
					id:  this.dialogModalInputObject.widgetObject.Id,
				}
			};

			let stringFleetConfig = JSON.stringify(_.cloneDeep(fleetConfig));

			this.VocationalSettingsJSON = stringFleetConfig;
		}

		this.lazyLoadWidget();
		this.getCountOfFilesUploaded();

		if(this.dialogModalInputObject.WidgetName === 'GPU-Summary'
			|| this.dialogModalInputObject.WidgetName === 'GSE-Summary'
			|| this.dialogModalInputObject.WidgetName === 'AHU-Summary'
			|| this.dialogModalInputObject.WidgetName === 'PBB-Summary'
			|| this.dialogModalInputObject.WidgetName === 'PCA-Summary')
		{
			this.widgetAlertNotificationColor();
		}

	}

	updateLogsFromCache() {
		let filteredLogs = 	this.dataService.filterNotesForWidget(this.dialogModalInputObject.widget);

		var logsArray = filteredLogs.select((visiblelog: any) => {
				var EntityLogTypeName = this.dataService.cache.entityLogTypesObject[visiblelog.EntityLogTypeId]?.Name;

				var output = {
					Id: visiblelog.Id,
					Description: visiblelog.Description,
					CreationDate: visiblelog.CreationDate,
					CreatorUserId: visiblelog.CreatorUserId,
					CreatorName: this.dataService.cache.people.firstOrDefault((person: any) => { return person.UserId == visiblelog.CreatorUserId })?.FullName,
					LastModifiedDate: visiblelog.LastModifiedDate,
					LastModifiedName: this.dataService.cache.people.firstOrDefault((person: any) => { return person.UserId == visiblelog.LastModifiedUserId })?.FullName,
					EntityLogTypeName: EntityLogTypeName,
					AssetName: EntityLogTypeName == "Asset" ? this.dataService.cache.assetsObject[visiblelog.EntityId].Name : null,
					CountOfFilesUploaded: visiblelog.CountOfFilesUploaded,
					CountOfLogComments: visiblelog.CountOfLogComments,
					EntityId: visiblelog.EntityId,
					EntityLogTypeId: visiblelog.EntityLogTypeId,
					IsPrivate: visiblelog.IsPrivate,
					Keywords: visiblelog.Keywords,
				};
				return output;

			}).toArray();

		this.childComponent.dataUpdated(logsArray)
	}

	getCountOfFilesUploadedForEntity(entity: any, entityId: number, entityType: string) {

		var sqlStatement = "API.FileImageManagement 'Count', '" + entityType + "', " + entityId + ", @UserId = " + Global.User.currentUser.Id;
		this.dataService.SQLActionAsPromise(sqlStatement).then((data: any) => {
			var listOfFiles = data;
			entity.CountOfFilesUploaded = listOfFiles.length > 0
					? listOfFiles.first().CountOfFilesUploaded
					: 0;

		});

	}

	openGSETemplatePopup() {
		this.dataService.initializeGseCanvasPopup(this.dialogModalInputObject.widgetObject);
	}

	private async lazyLoadWidget() {
		await this.mapWidget();
		setTimeout(() => {
			this.lazyLoadedWidgetInsert.clear();
			const { instance }: any =
				this.lazyLoadedWidgetInsert.createComponent(
					this.dialogModalInputObject.component[
						this.dialogModalInputObject.ProperComponentName
					]
				);
			this.childComponent = instance;
			switch (this.dialogModalInputObject.WidgetName) {
				case 'jbtStandardObservationIdTemplateComponent':
					instance.dataObject = this.dialogModalInputObject;
					break;
				case 'jbtStandardObservationIdTemplateTagsComponent':
					instance.dataObject = this.dialogModalInputObject;
					break;
				case 'canvas':
					instance.dataObject = this.dialogModalInputObject;
					break;
				case 'tag-graph':
					instance.tagModalInputObject = this.dialogModalInputObject;
					instance.timeZoneChangedOnSingleTagTrendEvent =
						this.timeZoneChangedOnSingleTagTrendEvent;
					break;
				case 'GSE-Summary':
					instance.dataObject = this.dialogModalInputObject;
					break;
				case 'Kendo-Grid':
					instance.dataObject = this.dialogModalInputObject;
					break;
				case 'Kendo-Chart':
					instance.dataObject = this.dialogModalInputObject;
					break;
				case 'GPU-Summary':
					instance.dataObject = this.dialogModalInputObject;
					break;
				case 'AHU-Summary':
					instance.dataObject = this.dialogModalInputObject;
					break;
				case 'PBB-Summary':
					instance.dataObject = this.dialogModalInputObject;
					break;
				case 'PCA-Summary':
					instance.dataObject = this.dialogModalInputObject;
					break;
				case 'WCL-Summary':
					instance.dataObject = this.dialogModalInputObject;
					break;
				case 'CU-Summary':
					instance.dataObject = this.dialogModalInputObject;
					break;
				case 'GS-Top-Five-Alarms-Summary':
					instance.dataObject = this.dialogModalInputObject;
					break;
				case 'GS-Top-Five-Equipment-Alarms-Summary':
					instance.dataObject = this.dialogModalInputObject;
					break;
				case 'GSE-Top-Five-Equipment-Alarms-Summary':
					instance.dataObject = this.dialogModalInputObject;
					break;
				case 'GSE-Top-Five-Alarms-Summary':
					instance.dataObject = this.dialogModalInputObject;
					break;
				case 'Raw-Information-Popup':
					instance.dataObject = this.dialogModalInputObject;
					break;
			}
		}, 100);
	}

	async mapWidget() {
		//---------------------------------------------------------------------------------------------------------------------------------------------------
		//-- NOTE: 	A number of components have been built out for the Dialog Modal Input Object to have a 'popupTitle', which should not have been the case.
		//--		Instead of hitting all of these that have been changed to use a popupTitle for the display in the Modal Dialog header, I'm using that 
		//--		popupTitle to define the widgetNameDisplay field and the assetName field so that when it's not a gate system asset nor a GSE asset,
		//--		the widgetNameDisplay is the first portion of the popupTitle and the assetName is the second part of the popupTitle.  This is so we don't 
		//--		have duplication in the title for gate system assets and GSE/Fleet assets. --Kirk T. Sherer, September 20, 2024. 
		//---------------------------------------------------------------------------------------------------------------------------------------------------
		var popupTitle = this.dialogModalInputObject.popupTitle;
		if (popupTitle != null) {
			this.dialogModalInputObject.widgetNameDisplay = popupTitle.split(": ").firstOrDefault();
			this.dialogModalInputObject.assetName = popupTitle.split(": ").lastOrDefault();
		}
		//---------------------------------------------------------------------------------------------------------------------------------------------------
		switch (this.dialogModalInputObject.WidgetName) {
			case 'jbtStandardObservationIdTemplateComponent':
				this.dialogModalInputObject.ProperComponentName =
					'JbtStandardObservationIdTemplateComponent';
				await import(
					'projects/shared-lib/src/lib/components/jbt-standard-observation-id-template/jbt-standard-observation-id-template.module'
				);
				this.dialogModalInputObject.component = await import(
					'projects/shared-lib/src/lib/components/jbt-standard-observation-id-template/jbt-standard-observation-id-template.component'
				);
				break;
			case 'jbtStandardObservationIdTemplateTagsComponent':
				this.dialogModalInputObject.ProperComponentName =
					'JbtStandardObservationIdTemplateTagsComponent';
				await import(
					'projects/shared-lib/src/lib/components/jbt-standard-observation-id-template-tags/jbt-standard-observation-id-template-tags.module'
				);
				this.dialogModalInputObject.component = await import(
					'projects/shared-lib/src/lib/components/jbt-standard-observation-id-template-tags/jbt-standard-observation-id-template-tags.component'
				);
				break;
			case 'canvas':
				this.dialogModalInputObject.ProperComponentName =
					'CanvasComponent';
				await import(
					'projects/shared-lib/src/lib/components/canvas/canvas.module'
				);
				this.dialogModalInputObject.component = await import(
					'projects/shared-lib/src/lib/components/canvas/canvas.component'
				);
				break;
			case 'tag-graph':
				this.dialogModalInputObject.ProperComponentName =
					'TagGraphComponent';
				await import(
					'projects/shared-lib/src/lib/components/tag-graph/tag-graph.module'
				);
				this.dialogModalInputObject.component = await import(
					'projects/shared-lib/src/lib/components/tag-graph/tag-graph.component'
				);
				break;
			case 'GSE-Summary':
				this.dialogModalInputObject.ProperComponentName =
					'AssetSummaryParentComponent';
				await import(
					'projects/shared-lib/src/lib/components/asset-summary-parent/asset-summary-parent.module'
				);
				this.dialogModalInputObject.component = await import(
					'projects/shared-lib/src/lib/components/asset-summary-parent/asset-summary-parent.component'
				);
				break;
			case 'Kendo-Grid':
				this.dialogModalInputObject.ProperComponentName =
					'KendoUiGenericPopupComponent';
				await import(
					'projects/shared-lib/src/lib/components/kendo-ui-generic-popup/kendo-ui-generic-popup.module'
				);
				this.dialogModalInputObject.component = await import(
					'projects/shared-lib/src/lib/components/kendo-ui-generic-popup/kendo-ui-generic-popup.component'
				);
				break;
			case 'Kendo-Chart':
				this.dialogModalInputObject.ProperComponentName =
					'KendoUiGenericPopupComponent';
				await import(
					'projects/shared-lib/src/lib/components/kendo-ui-generic-popup/kendo-ui-generic-popup.module'
				);
				this.dialogModalInputObject.component = await import(
					'projects/shared-lib/src/lib/components/kendo-ui-generic-popup/kendo-ui-generic-popup.component'
				);
				break;
			case 'Raw-Information-Popup':
				this.dialogModalInputObject.ProperComponentName =
					'RawInformationPopupComponent';
				await import(
					'projects/shared-lib/src/lib/components/raw-information-popup/raw-information-popup.module'
				);
				this.dialogModalInputObject.component = await import(
					'projects/shared-lib/src/lib/components/raw-information-popup/raw-information-popup.component'
				);
				break;
			case 'GPU-Summary':
				this.dialogModalInputObject.ProperComponentName =
					'GpuSummaryComponent';
				await import(
					'projects/shared-lib/src/lib/components/gpu-summary/gpu-summary.module'
				);
				this.dialogModalInputObject.component = await import(
					'projects/shared-lib/src/lib/components/gpu-summary/gpu-summary.component'
				);
				break;
			case 'AHU-Summary':
				this.dialogModalInputObject.ProperComponentName =
					'AhuSummaryComponent';
				await import(
					'projects/shared-lib/src/lib/components/ahu-summary/ahu-summary.module'
				);
				this.dialogModalInputObject.component = await import(
					'projects/shared-lib/src/lib/components/ahu-summary/ahu-summary.component'
				);
				break;
			case 'PBB-Summary':
				this.dialogModalInputObject.ProperComponentName =
					'PbbSummaryComponent';
				await import(
					'projects/shared-lib/src/lib/components/pbb-summary/pbb-summary.module'
				);
				this.dialogModalInputObject.component = await import(
					'projects/shared-lib/src/lib/components/pbb-summary/pbb-summary.component'
				);
				break;
			case 'PCA-Summary':
				this.dialogModalInputObject.ProperComponentName =
					'PcaSummaryComponent';
				await import(
					'projects/shared-lib/src/lib/components/pca-summary/pca-summary.module'
				);
				this.dialogModalInputObject.component = await import(
					'projects/shared-lib/src/lib/components/pca-summary/pca-summary.component'
				);
				break;
			case 'WCL-Summary':
				this.dialogModalInputObject.ProperComponentName =
					'WheelchairSummaryComponent';
				await import(
					'projects/shared-lib/src/lib/components/wheelchair-summary/wheelchair-summary.module'
				);
				this.dialogModalInputObject.component = await import(
					'projects/shared-lib/src/lib/components/wheelchair-summary/wheelchair-summary.component'
				);
				break;
			case 'CU-Summary':
				this.dialogModalInputObject.ProperComponentName =
					'CentralunitsSummaryComponent';
				await import(
					'projects/shared-lib/src/lib/components/centralunits-summary/centralunits-summary.module'
				);
				this.dialogModalInputObject.component = await import(
					'projects/shared-lib/src/lib/components/centralunits-summary/centralunits-summary.component'
				);
				break;
			case 'Edit-Note':
				this.dialogModalInputObject.ProperComponentName = 'EditNoteComponent';
				await import('projects/shared-lib/src/lib/components/edit-note/edit-note.module');
				this.dialogModalInputObject.component = await import('projects/shared-lib/src/lib/components/edit-note/edit-note.component');
				break;
			case 'Seat-Belt-Info-Popup':
				this.dialogModalInputObject.ProperComponentName = 'SeatBeltInfoPopupComponent';
				await import('projects/shared-lib/src/lib/components/seat-belt-info-popup/seat-belt-info-popup.module');
				this.dialogModalInputObject.component = await import('projects/shared-lib/src/lib/components/seat-belt-info-popup/seat-belt-info-popup.component');
				break;



		}
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log('ngOnDestroy invoked...');
		if(this.sub){
			this.sub.unsubscribe();
		}

		if (this.signalRSubscription !== undefined) {
			this.signalRSubscription.unsubscribe();
		}

	}

	onCancel() {
		this.dialogRef.close();
	}

	doKeywordFilter(value: string) {
		this.searchString = value.trim().toLocaleLowerCase();
	}

	fullScreen() {
		this.isFullScreen = !this.isFullScreen;
		Global.User.DebugMode &&
			console.log(
				this.componentName + ': isFullScreen = ' + this.isFullScreen
			);
		if (this.isFullScreen) {
			this.dialogRef.updateSize('100%', '100%');
		} else {
			this.dialogRef.updateSize('95%', '80%');
		}
	}

	openFileImageUploadWindow() {
		var service = this;
		var entityId = this.assetId;
		var entityType: string = 'Asset';
		var siteName = this.dialogModalInputObject.siteName
			? this.dialogModalInputObject.siteName
			: '';
		var entityName = this.dialogModalInputObject.gateSystemName
			? ' Gate ' + this.dialogModalInputObject.gateSystemName
			: this.dialogModalInputObject.gseName;
		var assetType = this.dialogModalInputObject.gateSystemName
			? ' ' + this.dialogModalInputObject.widgetNameDisplay.split(' ')[0]
			: '';

		var title =
			siteName +
			(entityName != null ? entityName : '') +
			assetType +
			' Documentation';

		var sqlStatement =
			"API.FileImageManagement @Type='List', @EntityType='" + entityType + "', @EntityId=" + entityId + ", @UserId=" + Global.User.currentUser.Id;
		this.dataService.SQLActionAsPromise(sqlStatement).then((data: any) => {
			Global.User.DebugMode &&
				console.log(
					this.componentName + ': ' + sqlStatement + ' = %O',
					data
				);
			this.fileImageData = data;
			this.FileImageLibraryControlObject = {
				imageKeys: this.fileImageData.filter((i: any) => {
					return i.ImageKey;
				}),
				//---B
				removeUploadFunction(deletedImageKey: string) {
					service.dataService.SQLActionAsPromise("API.FileImageManagement @Type='Delete', @EntityType='" + entityType + "', @EntityId=" + entityId + ", @ImageKey='" + deletedImageKey + "', @UserId=" + Global.User.currentUser.Id).then((deletedEntity: any) => {
						service.fileImageData.splice(deletedEntity);
					});
				},
				//---B
				addUploadFunction(newImageKey: string) {
					service.dataService.SQLActionAsPromise("API.FileImageManagement @Type='Add', @EntityType='" + entityType + "', @EntityId=" + entityId + ", @ImageKey='" + newImageKey + "', @UserId=" + Global.User.currentUser.Id).then((newEntity: any) => {
						service.fileImageData.push(newEntity);
					});
				},
				mode: null,
				listTitle: title,
				entityId: entityId,
				entityType: entityType,
				closeUploadWindowFunction() {
					service.FileImageLibraryControlObject = null;
				}
			};

			this.openFileUploadDialog();
		});
	}

	openFileUploadDialog(): void {
		var innerWidth = window.innerWidth;
		const dialogRef = this.dialog.open(FileUploadListComponent, {
			width: '70%',
			height: '70%',
		});
		dialogRef.componentInstance.fileImageLibraryControlObject =
			this.FileImageLibraryControlObject;
		dialogRef.afterClosed().subscribe((result) => {
			Global.User.DebugMode &&
				console.log(
					this.componentName + ': The File Upload dialog was closed'
				);
			this.getCountOfFilesUploaded();
		});
	}

	getCountOfFilesUploaded() {
		if (this.assetId) {
			this.dataService
				.SQLActionAsPromise(
					'API.FileImageCountByAssetId ' + this.assetId
				)
				.then((data: any) => {
					var listOfFiles = data;
					this.dialogModalInputObject.CountOfFilesUploaded =
						listOfFiles.length > 0
							? listOfFiles.first().CountOfFilesUploaded
							: 0;
				});
		}
	}

	radioChange(event) {
		console.log(event);
		//emit the event to the child.
		this.timeZoneChangedOnSingleTagTrendEvent.emit(event);
	}

	addTagToChart(tag) {
		console.log(tag);
		if (this.dataService.tempTagsToChart.includes(tag)) {
			_.remove(this.dataService.tempTagsToChart, (tag) => {
				return tag.TagId == tag.TagId;
			});
		} else {
			this.dataService.tempTagsToChart.push(tag);
		}
		console.log('dataService Tags', this.dataService.tempTagsToChart);
	}


	openAlertsPopup()
	{
		let widget = {
			WidgetSiteId: this.dialogModalInputObject.assetObj?.SiteId,
			WidgetSiteName: this.dialogModalInputObject.WidgetSiteName,
			WidgetGateSystemName: this.dialogModalInputObject.WidgetGateSystemName,
			VocationalSettingsJSON: this.dialogModalInputObject.parentWidget.VocationalSettingsJSON,
		};

		var assetObj;
		if(this.dialogModalInputObject.assetObj != null) {
			this.dialogModalInputObject.assetObj.Name = this.dialogModalInputObject.assetObj.Type;
		}
		else if(this.assetId != null) {
			assetObj = this.dataService.cache.assetsObject[this.assetId];
		}

		const dlg = this.dialog.open(
			AlertManagementPopupComponent,
			{
				width: Global.isMobile ? '90%' : '40%',
				height: Global.isMobile ? '80%' : '70%',
				data: {
					alertLevel: 'asset',
					widget: widget,
					item: this.dialogModalInputObject.assetObj ? this.dialogModalInputObject.assetObj : assetObj,
				},
				maxWidth: '100vw',
				maxHeight: '100vh',
			}
		);
		let d = dlg
			.afterClosed()
			.subscribe((result) => {
				this.widgetAlertNotificationColor();
			});
	}



	GetAlertNotificationDetails(userAlert) : any {

		let alertObject: any = {
			siteId: 0,
			siteName: '',
			gateSystemId: 0,
			gateSystemName: '',
			category: '',
			Email: 0,
		};


		// delivery
		alertObject.Email = userAlert.Email == 1 ? true : false;
		alertObject.TextMessage = userAlert.TextMessage == 1 ? true : false;

		// set days
		if(userAlert.SendAllDays == 1) {
			alertObject.DaysSelected = "all days";
		}
		else {
			alertObject.DaysSelected = "select days";
		}


		// calc work hours
		let hourToStart = userAlert.UserTimeZoneOffset + 8;

		// set hours
		if(userAlert.SendAllHours == 1) {
			alertObject.HoursSelected = "all Hours";
		}
		else {
			alertObject.HoursSelected = "select Hours";
		}

		return alertObject;
	}



	widgetAlertNotificationColor() : any {

		let userAlert = this.dataService.cache.emailTagUsers.filter((a) =>
			a.UserId == Global.User.currentUser.Id &&
			(a.CriticalAssetId == this.assetId || a.AssetId == this.assetId || a.AlertAssetId == this.assetId)
			);

		// set the title with alert info
		if(userAlert?.length > 0) {

			let title = userAlert[0].CriticalAssetId == this.assetId ? "Critical " : "";

			if(this.dialogModalInputObject?.assetObj) {
				title += "Alert notifications set for " +  this.dialogModalInputObject?.assetObj?.Type;
			}
			else {
				title += "Alert notifications set for " +  this.dialogModalInputObject?.gseName;
			}

			let alertObject = this.GetAlertNotificationDetails(userAlert[0]);

			if(alertObject.Email == true && alertObject.TextMessage == true) {
				title += " email and text delivery";
			}
			else {
				title += alertObject.Email == true ? " email delivery" : "";
				title += alertObject.TextMessage == true ? " text delivery" : "";
			}

			title += " on " + alertObject.DaysSelected;
			title += " during "+ alertObject.HoursSelected;

			this.alertNotificationTitle = title;
		}
		else {
			this.alertNotificationTitle = "Click to set alert management settings.";
		}


		if(userAlert?.length > 0) {
			this.alertNotificationColor = {'color': 'green'};
		}
		else {
			this.alertNotificationColor = {'color': 'white'};
		}
	}

	editNote(note){
		const noteDialog = this.dialog.open(DialogModalParentComponent, {
			width: Global.isMobile ? '100%' : '60%',
			height: Global.isMobile ? '90%' : '80%',
			data: {

				popupTitle: 'Editing note: ',
				WidgetName: 'Edit-Note',
				note: note,
				entityData: this.dialogModalInputObject.entityData,
				canCreateDashboardWidgetFromPopup: false,
			},
			maxWidth: '100vw',
			maxHeight: '100vh',
		});

		let d = noteDialog.afterClosed().subscribe((result) => {
			Global.User.DebugMode && console.log("result", result);
			Global.User.DebugMode && console.log("cache entityLogs", this.dataService.cache.entityLogs);
		});
	}

	openNotesPopup(widget){

		let entityData = {
			EntityId: widget.Id,
			EntityName: widget.displayName,
			EntityLogTypeId: 1, //Asset
		}

		let filteredLogs = 	this.dataService.filterNotesForWidget(widget, "dialog");
		var logsArray = filteredLogs.select((visiblelog: any) => {
				var EntityLogTypeName = this.dataService.cache.entityLogTypesObject[visiblelog.EntityLogTypeId]?.Name;

				var output = {
					Id: visiblelog.Id,
					Description: visiblelog.Description,
					CreationDate: visiblelog.CreationDate,
					CreatorUserId: visiblelog.CreatorUserId,
					CreatorName: this.dataService.cache.people.firstOrDefault((person: any) => { return person.UserId == visiblelog.CreatorUserId })?.FullName,
					LastModifiedDate: visiblelog.LastModifiedDate,
					LastModifiedName: this.dataService.cache.people.firstOrDefault((person: any) => { return person.UserId == visiblelog.LastModifiedUserId })?.FullName,
					EntityLogTypeName: EntityLogTypeName,
					AssetName: EntityLogTypeName == "Asset" ? this.dataService.cache.assetsObject[visiblelog.EntityId].Name : null,
					CountOfFilesUploaded: visiblelog.CountOfFilesUploaded,
					CountOfLogComments: visiblelog.CountOfLogComments,
					EntityId: visiblelog.EntityId,
					EntityLogTypeId: visiblelog.EntityLogTypeId,
					IsPrivate: visiblelog.IsPrivate,
					Keywords: visiblelog.Keywords,
				};
				return output;
			}).toArray();

		const dlg = this.dialog.open(DialogModalParentComponent, {
			width: Global.isMobile ? '100%' : '80%',
			height: Global.isMobile ? '90%' : '90%',
			data: {
				groupable: false,
				groupColumns: [],
				popupTitle: 'Logbook for Asset: ' + widget.displayName,
				widgetNameDisplay: 'Logbook for Asset: ' + widget.displayName,
				WidgetName: 'Kendo-Grid',
				description: 'Notes list',
				tableStructure:  [
					{
						field: "Id",
						title: "Id",
						_width: 50,
					},
					{
						field: "CreatorName",
						title: "Creator",
						_width: 75,
					},
					{
						field: "AssetName",
						title: "Asset",
						_width: 75,
					},
					{
						field: "Description",
						title: "Description",
						_width: 300,
					},
					{
						field: "LastModifiedDate",
						title: "Last Modified Date",
						_width: 75
					},
					{
						field: "EditNote",
						title: "Actions",
						_width: 100,
					},
				],
				tableData: logsArray,
				entityData: entityData,
				canCreateDashboardWidgetFromPopup: false,
			},
			maxWidth: '100vw',
			maxHeight: '100vh',
		});
	}

	openNotesPopupForAsset(asset){

		var assetName = asset.Type == "PBB" || asset.Type == "GPU" || asset.Type == "PCA" || asset.Type == "AHU" ?
				asset.Site.Name + " " + asset.ParentSystem.Name + " " + asset.Type :
				asset.Name;


		let entityData = {
			EntityId: asset.Id,
			EntityName: assetName,
			EntityLogTypeId: 1, //Asset
		}

		let filteredLogs = 	this.dataService.filterNotesForWidget(asset, "dialog");
		var logsArray = filteredLogs.select((visiblelog: any) => {
				var EntityLogTypeName = this.dataService.cache.entityLogTypesObject[visiblelog.EntityLogTypeId]?.Name;

				var output = {
					Id: visiblelog.Id,
					Description: visiblelog.Description,
					CreationDate: visiblelog.CreationDate,
					CreatorUserId: visiblelog.CreatorUserId,
					CreatorName: this.dataService.cache.people.firstOrDefault((person: any) => { return person.UserId == visiblelog.CreatorUserId })?.FullName,
					LastModifiedDate: visiblelog.LastModifiedDate,
					LastModifiedName: this.dataService.cache.people.firstOrDefault((person: any) => { return person.UserId == visiblelog.LastModifiedUserId })?.FullName,
					EntityLogTypeName: EntityLogTypeName,
					AssetName: assetName,
					CountOfFilesUploaded: visiblelog.CountOfFilesUploaded,
					CountOfLogComments: visiblelog.CountOfLogComments,
					EntityId: visiblelog.EntityId,
					EntityLogTypeId: visiblelog.EntityLogTypeId,
					IsPrivate: visiblelog.IsPrivate,
					Keywords: visiblelog.Keywords,
				};
				return output;
			}).toArray();


		const dlg = this.dialog.open(DialogModalParentComponent, {
			width: Global.isMobile ? '100%' : '80%',
			height: Global.isMobile ? '90%' : '90%',
			data: {
				groupable: false,
				groupColumns: [],
				popupTitle: 'Logbook for Asset: ' + assetName,
				widgetNameDisplay: 'Logbook for Asset: ' + assetName,
				WidgetName: 'Kendo-Grid',
				description: 'Notes list',
				tableStructure:  [
					{
						field: "Id",
						title: "Id",
						_width: 75,
					  },
					  {
						  field: "CreatorName",
						  title: "Creator",
						  _width: 100,
					  },
					  {
						field: "AssetName",
						title: "Asset",
						_width: 150,
					},
					{
						field: "Description",
						title: "Description",
						_width: 300,
					  },
					  {
						  field: "LastModifiedDate",
						  title: "Last Modified Date",
						  _width: 75
					  },
					  {
						  field: "EditNote",
						  title: "Actions",
						  _width: 100,
					  },

				  ],
				tableData: logsArray,
				entityData: entityData,
				canCreateDashboardWidgetFromPopup: false,
			},
			maxWidth: '100vw',
			maxHeight: '100vh',
		});
	}

}
