<ng-template #popTitle
	><h4 style="color: black">
		<b>{{ tooltipInformationObject.Title }}</b>
	</h4>
</ng-template>
<ng-template #popContent>
	<div *ngIf="tooltipInformationObject.StandardId !== ''">
		<p style="color: black"><b>Standard Id:</b></p>
		<p style="color: black">{{ tooltipInformationObject.StandardId }}</p>
		<p style="color: black"><b>Standard Name:</b></p>
		<p style="color: black">{{ tooltipInformationObject.StandardName }}</p>
	</div>
</ng-template>

<kendo-grid
	#kendoGridParent
	[data]="processedRawDataForGrid"
	[pageable]="
		gridSettings.state.take !== undefined && gridData?.length > 15
			? { pageSizes: true }
			: false
	"
	[pageSize]="gridSettings.state.take"
	[groupable]="groupable"
	[group]="gridSettings.state.group"
	[style.height.%]="height"
	(sortChange)="sortChange($event)"
	(pageChange)="pageChange($event)"
	(columnLockedChange)="columnLockedChange()"
	(groupChange)="groupChange($event)"
	(dblclick)="onDblClick($event, kendoGridParent)"
	(filterChange)="filterChange($event)"
	(columnReorder)="onReorder()"
	(columnResize)="onColumnResize()"
	(columnVisibilityChange)="onChange()"
	[filter]="gridSettings.state.filter"
	[filterable]="filterable"
	[sort]="gridSettings.state.sort"
	[skip]="gridSettings.state.skip"
	[hidden]="hidden"
	[rowClass]="updateRowColor"
	[sortable]="sortable"
	[reorderable]="reorderable"
	[resizable]="resizable"
	[scrollable]="
		widgetObject !== undefined ? widgetObject.scrollmode : 'scrollable'
	"
	[columnMenu]="columnMenu"
>
	<ng-template kendoGridToolbarTemplate *ngIf="exportable === true">
		<button kendoGridExcelCommand icon="file-excel">Export to Excel</button>
	</ng-template>
	<kendo-grid-excel
		[fetchData]="allData"
		fileName="export.xlsx"
		*ngIf="exportable === true"
	></kendo-grid-excel>
	<kendo-grid-column
		*ngFor="let column of gridSettings.columnsConfig"
		[locked]="column.locked"
		title="{{ column.title }}"
		field="{{ column.field }}"
		[width]="column._width"
		[filter]="column.filter"
		[hidden]="column.hidden"
		[includeInChooser]="column.includeInChooser"
		[filterable]="
			column.filterable !== undefined ? column.filterable : true
		"
	>
		<ng-template
			kendoGridHeaderTemplate
			let-column
			let-columnIndex="columnIndex"
			*ngIf="column.columnObId && global.User.DebugMode"
		>
			<div
				container="body"
				(mouseenter)="tooltipInformation(column)"
				[ngbPopover]="popContent"
				[popoverTitle]="popTitle"
				[openDelay]="300"
				triggers="mouseenter:mouseleave"
			>
				<span class="k-column-title">{{ column.title }}</span>
			</div>
		</ng-template>
		<ng-template
			kendoGridColumnMenuTemplate
			let-service="service"
			*ngIf="filterable === 'menu'"
		>
			<kendo-grid-columnmenu-sort [service]="service">
			</kendo-grid-columnmenu-sort>
			<kendo-grid-columnmenu-lock [service]="service">
			</kendo-grid-columnmenu-lock>
			<kendo-grid-columnmenu-chooser [service]="service">
			</kendo-grid-columnmenu-chooser>
			<kendo-grid-columnmenu-filter [service]="service">
			</kendo-grid-columnmenu-filter>
			<kendo-grid-columnmenu-item
				icon="k-icon k-i-filter-clear"
				text="Clear Filter"
				(itemClick)="
					filterChange({ logic: 'and', filters: [] }); service.close()
				"
			>
			</kendo-grid-columnmenu-item>
			<kendo-grid-columnmenu-item
				icon="k-icon k-i-increase-horizontal-spacing"
				text="Make Column Bigger"
				(itemClick)="
					columnResizeManual('bigger', kendoGridParent, column)
				"
			>
			</kendo-grid-columnmenu-item>
			<kendo-grid-columnmenu-item
				icon="k-icon k-i-decrease-horizontal-spacing"
				text="Make Column Smaller"
				(itemClick)="
					columnResizeManual('smaller', kendoGridParent, column)
				"
			>
			</kendo-grid-columnmenu-item>
		</ng-template>

		<ng-template
			*ngIf="column.field === 'JavascriptDate'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div>
				{{ dataItem.JavascriptDate | date : "medium" }}
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'isTagSelected'"
			kendoGridCellTemplate
			let-dataItem
		>
			<mat-checkbox
				style="padding: 5px 6px"
				[(ngModel)]="dataItem.isTagSelected"
			></mat-checkbox>
		</ng-template>

		<ng-template
			*ngIf="column.field === 'Date'"
			kendoGridCellTemplate
			let-dataItem
		>
			{{ dataItem.Date | date : "MM/dd/yy HH:mm:ss" }}
		</ng-template>
		<ng-template
			*ngIf="column.field === 'chart'"
			kendoGridCellTemplate
			let-dataItem
		>
			<td>
				<button
					style="padding: 3px 6px; height: 25px"
					class="jbt-btn btn-sm"
					(click)="addTagToChart(dataItem)"
				>
					<i
						*ngIf="
							!dataService.tempTagsToChart?.includes(
								dataItem.TagId
							)
						"
						class="fas fa-chart-line"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
						[style.font-size]="'12px'"
					></i>
					<i
						*ngIf="
							dataService.tempTagsToChart?.includes(
								dataItem.TagId
							)
						"
						class="fas fa-check"
						[ngStyle]="{
							color: theme === 'light' ? 'green' : 'green'
						}"
						[style.font-size]="'12px'"
					></i>
				</button>
			</td>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'EditNote'"
			kendoGridCellTemplate
			let-dataItem
		>
			<td>
				<button
					*ngIf="dataItem.CreatorUserId == global.User.currentUser.Id"
					style="padding: 3px 6px; height: 25px"
					class="jbt-btn btn-sm"
					(click)="editNote(dataItem, true)"
				>
					<i class="tim-icons icon-pencil" style="color: #f0f0f0"></i>
				</button>

				<div
					style="display: inline-block"
					matBadgeHidden="true"
					[matBadge]="1"
					matBadgeOverlap="true"
					matBadgeColor="warn"
				>
					<button
						id="comment"
						color="primary"
						class="btn issues-btn btn-sm"
						matTooltip="View the comments attached to this Log Entry.  {{
							dataItem.CountOfLogComments > 0
								? dataItem.CountOfLogComments +
								  ' entr' +
								  (dataItem.CountOfLogComments > 1
										? 'ies'
										: 'y') +
								  ' currently.'
								: ''
						}}"
						[matTooltipPosition]="'above'"
						type="button"
						style="padding-left: 7px; padding-right: 7px"
						(click)="
							editNote(
								dataItem,
								dataItem.CreatorUserId ==
									global.User.currentUser.Id
							)
						"
					>
						<i
							class="tim-icons icon-chat-33"
							[ngStyle]="{
								color:
									dataItem.CountOfLogComments > 0
										? 'green'
										: 'white'
							}"
						></i>
					</button>
				</div>

				<button
					color="primary"
					class="jbt-btn btn-sm"
					(click)="
						openFileImageUploadWindow(
							dataItem,
							'EntityLog',
							'Logbook Entry #' + dataItem.Id + ' Documentation',
							dataItem.Id
						)
					"
					matTooltip="View files attached to this Issue.  {{
						dataItem.CountOfFilesUploaded > 0
							? dataItem.CountOfFilesUploaded +
							  ' file' +
							  (dataItem.CountOfFilesUploaded > 1 ? 's' : '') +
							  ' uploaded currently.'
							: ''
					}}"
					[matTooltipPosition]="'above'"
					type="button"
					style="padding: 3px 6px; height: 25px"
				>
					<i
						class="tim-icons icon-notes"
						[ngStyle]="{
							color:
								dataItem.CountOfFilesUploaded > 0
									? 'green'
									: 'white'
						}"
					>
					</i>
				</button>

				<button
					*ngIf="dataItem.CreatorUserId == global.User.currentUser.Id"
					style="padding: 3px 6px; height: 25px"
					class="jbt-btn btn-sm"
					(click)="deleteNote(dataItem)"
				>
					<i
						class="tim-icons icon-simple-remove"
						style="color: red"
					></i>
				</button>
			</td>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'alertNotification'"
			kendoGridCellTemplate
			let-dataItem
		>
			<td
				*ngIf="
					widgetObject == null ||
					widgetObject?.AngularDirectiveName === 'gseSummary' ||
					widgetObject?.AngularDirectiveName === 'gpuSummary' ||
					widgetObject?.AngularDirectiveName === 'pbbSummary' ||
					widgetObject?.AngularDirectiveName === 'pcaSummary' ||
					widgetObject?.AngularDirectiveName === 'ahuSummary'
				"
			>
				<button
					*ngIf="
						dataItem.Severity == 'Critical' ||
						dataItem.Severity == 'Alarm' ||
						dataItem.Severity == 'Alert'
					"
					style="padding: 3px 6px; height: 25px"
					class="jbt-btn btn-sm"
					(click)="openAlertsPopup(dataItem)"
				>
					<i
						class="tim-icons icon-bell-55"
						[ngStyle]="{
							color:
								dataItem.alertNotification == true
									? 'green'
									: theme === 'light'
									? '#27304c'
									: 'white'
						}"
						[matTooltip]="dataItem.alertNotificationTitle"
						[style.font-size]="'12px'"
					></i>
				</button>
			</td>
			<td
				*ngIf="
					widgetObject?.AngularDirectiveName === 'gseOverview' ||
					widgetObject?.AngularDirectiveName === 'locateAllGSE'
				"
			>
				<button
					style="padding: 3px 6px; height: 25px"
					class="jbt-btn btn-sm"
					(click)="openAlertsPopup(dataItem, 'asset')"
				>
					<i
						class="tim-icons icon-bell-55"
						[ngStyle]="{
							color:
								dataItem.alertNotification == true
									? 'green'
									: theme === 'light'
									? '#27304c'
									: 'white'
						}"
						[matTooltip]="dataItem.alertNotificationTitle"
						[style.font-size]="'12px'"
					></i>
				</button>
			</td>
		</ng-template>

		<ng-template
			*ngIf="column.field === 'AssetAlarm'"
			kendoGridCellTemplate
			let-dataItem
		>
		</ng-template>

		<ng-template
			*ngIf="column.field === 'GateAlarm'"
			kendoGridCellTemplate
			let-dataItem
		>
			<td>
				<button
					style="padding: 3px 6px; height: 25px"
					class="jbt-btn btn-sm"
					(click)="openAlertsPopup(dataItem, 'gate')"
				>
					<i
						class="tim-icons icon-bell-55"
						[ngStyle]="{
							color:
								dataItem.alertNotification == true
									? 'green'
									: theme === 'light'
									? '#27304c'
									: 'white'
						}"
						[matTooltip]="dataItem.alertNotificationTitle"
						[style.font-size]="'12px'"
					></i>
				</button>
			</td>
		</ng-template>

		<ng-template
			*ngIf="column.field === 'Favorite'"
			kendoGridCellTemplate
			let-dataItem
		>
			<td>
				<button
					style="padding: 3px 6px; height: 25px"
					class="jbt-btn btn-sm"
					(click)="toggleFavorite(dataItem)"
				>
					<i
						*ngIf="dataItem.Favorite === true"
						class="far fa-check-square"
						[ngStyle]="{
							color: theme === 'light' ? 'green' : 'green'
						}"
						[style.font-size]="'12px'"
					></i>
					<i
						*ngIf="
							dataItem.Favorite === false ||
							dataItem.Favorite === undefined
						"
						class="far fa-square"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
						[style.font-size]="'12px'"
					></i>
				</button>
			</td>
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.field === 'Value'"
		>
			{{
				dataItem.Value
					| decimalPlaces : 1 : dataItem.JBTStandardObservationId
			}}
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="
				column.field === 'SiteDateFull' ||
				column.field === 'UserDateFull' ||
				column.field === 'UTCDateFull'
			"
		>
			{{ dataItem[column.field] | date : "MM/dd/yyyy&nbsp;HH:mm:ss.SSS" }}
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.field === 'JavascriptEndDate'"
		>
			<div *ngIf="dataItem.JavascriptEndDate" #tagDataRowForCalculation>
				{{ dataItem.JavascriptEndDate | date : "medium" }}
			</div>
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.field === 'JavascriptDate'"
		>
			<div
				*ngIf="
					column.title !== 'Last Refresh' && dataItem.JavascriptDate
				"
				#tagDataRowForCalculation
			>
				{{ dataItem.JavascriptDate | date : "medium" }}
			</div>
			<div
				*ngIf="
					column.title === 'Last Refresh' && dataItem.JavascriptDate
				"
				#tagDataRowForCalculation
			>
				{{ dataItem.JavascriptDate | date : "MMM dd, YYYY HH:mm:ss" }}
			</div>
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.field === 'SiteLocalJavascriptDate'"
		>
			<div
				*ngIf="
					column.title !== 'Last Refresh' &&
					dataItem.SiteLocalJavascriptDate
				"
				#tagDataRowForCalculation
			>
				{{ dataItem.SiteLocalJavascriptDate | date : "medium" }}
			</div>
			<div
				*ngIf="
					column.title === 'Last Refresh' &&
					dataItem.SiteLocalJavascriptDate
				"
				#tagDataRowForCalculation
			>
				{{
					dataItem.SiteLocalJavascriptDate
						| date : "MMM dd, YYYY HH:mm:ss"
				}}
			</div>
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.field === 'SiteLocalJavascriptEndDate'"
		>
			<div
				*ngIf="dataItem.SiteLocalJavascriptEndDate"
				#tagDataRowForCalculation
			>
				{{ dataItem.SiteLocalJavascriptEndDate | date : "medium" }}
			</div>
			<div
				*ngIf="!dataItem.SiteLocalJavascriptEndDate"
				#tagDataRowForCalculation
			>
				<p>Date not available</p>
			</div>
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.field === 'UTCJavascriptDate'"
		>
			<div
				*ngIf="
					column.title !== 'Last Refresh' &&
					dataItem.UTCJavascriptDate
				"
				#tagDataRowForCalculation
			>
				{{ dataItem.UTCJavascriptDate | date : "medium" }}
			</div>
			<div
				*ngIf="
					column.title === 'Last Refresh' &&
					dataItem.UTCJavascriptDate
				"
				#tagDataRowForCalculation
			>
				{{
					dataItem.UTCJavascriptDate | date : "MMM dd, YYYY HH:mm:ss"
				}}
			</div>
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.field === 'UTCJavascriptEndDate'"
		>
			<div
				*ngIf="dataItem.UTCJavascriptEndDate"
				#tagDataRowForCalculation
			>
				{{ dataItem.UTCJavascriptEndDate | date : "medium" }}
			</div>
			<div
				*ngIf="!dataItem.UTCJavascriptEndDate"
				#tagDataRowForCalculation
			>
				<p>Date not available</p>
			</div>
		</ng-template>

		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="
				column.filter === 'date' &&
				column.field != null &&
				column.field != 'SiteDateFull' &&
				column.field != 'UserDateFull' &&
				column.field != 'UTCDateFull'
			"
		>
			{{ dataItem[column.field] | date : "short" }}
		</ng-template>
		<ng-template
			*ngIf="column.field === 'AlarmReason'"
			kendoGridCellTemplate
			let-dataItem
		>
			<button
				style="padding: 3px 6px; height: 25px"
				class="jbt-btn btn-sm"
				*ngIf="
					checkForMetaDataInGSEServiceParent(dataItem) !== undefined
				"
				(click)="createMetaDataPopupParent(dataItem)"
			>
				<i
					class="fas fa-info"
					[ngStyle]="{
						color: theme === 'light' ? '#27304c' : 'white'
					}"
					[style.font-size]="'12px'"
				></i>
			</button>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'currentAlarmType'"
			kendoGridCellTemplate
			let-dataItem
		>
			<p
				[ngClass]="{
					flash: dataItem.currentAlarmType === 'Critical'
				}"
			>
				{{ dataItem.currentAlarmType }}
			</p>
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="
				column.field === 'Name' &&
				addWidgetToDashboardEmitter.observers.length === 0
			"
		>
			<div
				style="text-align: 'left'"
				(click)="
					widgetObject?.AngularDirectiveName === 'locateAllGSE'
						? showAssetOnMap(dataItem)
						: openSummaryWidget(dataItem, false, false)
				"
			>
				{{ dataItem.Name }}
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'LastModifiedDate'"
			kendoGridCellTemplate
			let-dataItem
		>
			{{ dataItem.LastModifiedDate | date : "MM/dd/yy HH:mm:ss" }}
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.field === 'UTCJavascriptDate'"
		>
			<div *ngIf="dataItem.UTCJavascriptDate" #calculatedDataRow>
				{{ dataItem.UTCJavascriptDate | date : "MM/dd/yy, hh:mm:ss a" }}
			</div>
			<div *ngIf="!dataItem.UTCJavascriptDate" #calculatedDataRow>
				<p>UTC Date not available</p>
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'stringPathForAssetImage'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				style="cursor: pointer; text-align: center"
				(click)="openSummaryWidget(dataItem, false, false)"
			>
				<img
					[src]="dataItem.stringPathForAssetImage"
					style="height: 30px"
				/>
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'stringPathForAssetUserDefinedAlarm'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				style="cursor: pointer; text-align: center"
				(click)="openSummaryWidget(dataItem, false, false)"
			>
				<img
					[ngClass]="{
						'blink-image':
							dataItem.stringPathForAssetUserDefinedAlarm?.includes(
								'Critical'
							)
					}"
					[src]="dataItem.stringPathForAssetUserDefinedAlarm"
					style="height: 30px"
				/>
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'displayName'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				style="cursor: pointer; text-align: center"
				(click)="openSummaryWidget(dataItem, false, false)"
			>
				<p>
					<span
						*ngIf="!dataService.determineIfFleetUser()"
						[ngClass]="{
							underlineWarning:
								dataItem.alertStatus === 'Warning',
							underlineRunning:
								dataItem.alertStatus != 'Alarm' &&
								dataItem.alertStatus != 'Critical' &&
								dataItem.alertStatus != 'Warning' &&
								dataItem.power !== null &&
								dataItem.power == 1,
							underlineAlarm: dataItem.alertStatus === 'Alarm',
							underlineBlinkCritical:
								dataItem.alertStatus === 'Critical'
						}"
					>
						{{ dataItem.displayName }}
					</span>
					<span *ngIf="dataService.determineIfFleetUser()">
						{{ dataItem.displayName }}
					</span>
				</p>
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'Site.Name'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				style="cursor: pointer; text-align: center"
				(click)="openSummaryWidget(dataItem, false, false)"
			>
				{{ dataItem.Site.Name }}
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'location'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				style="cursor: pointer; text-align: center"
				(click)="openSummaryWidget(dataItem, true, false)"
			>
				<img
					[style.height.px]="iconHeight"
					[src]="
						global.imagesUrl +
						'assets/img/gse-images/map-' +
						global.Theme +
						'.svg'
					"
				/>
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'playback'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				style="cursor: pointer; text-align: center"
				(click)="openSummaryWidget(dataItem, false, true)"
			>
				<mat-icon aria-hidden="false" aria-label="Playback"
					>play_arrow</mat-icon
				>
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'maintenance'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle({
						gse: dataItem,
						observationId: 4500
					})
				"
			>
				<img
					*ngIf="dataItem.MaintenanceMode"
					[style.height.px]="iconHeight"
					[src]="
						global.imagesUrl +
						'assets/img/gse-images/maintenance-mode.svg'
					"
				/>
				<img
					*ngIf="dataItem.OutOfService"
					[style.height.px]="iconHeight"
					[src]="
						global.imagesUrl +
						'assets/img/gse-images/out-of-service.svg'
					"
				/>
				<div
					*ngIf="!dataItem.MaintenanceMode && !dataItem.OutOfService"
				></div>
			</div>
		</ng-template>
		<ng-template
			*ngIf="
				column.field === 'power' &&
				widgetObject?.AngularDirectiveName !== 'wheelChairOverview'
			"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle(
						{ gse: dataItem, observationId: 4504 },
						57478
					)
				"
				*ngIf="dataItem.power !== null"
			>
				<img
					[style.height.px]="iconHeight"
					[src]="
						dataItem.power == 1
							? global.imagesUrl +
							  'assets/img/gse-images/power-on.svg'
							: global.imagesUrl +
							  'assets/img/gse-images/power-off-' +
							  global.Theme +
							  '.svg'
					"
				/>
			</div>
			<div style="text-align: center" *ngIf="dataItem.power === null">
				-
			</div>
		</ng-template>
		<ng-template
			*ngIf="
				column.field === 'ignitionStatus' &&
				widgetObject?.AngularDirectiveName !== 'wheelChairOverview'
			"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle(
						{ gse: dataItem, observationId: 59219 },
						57478
					)
				"
				*ngIf="dataItem.ignitionStatus !== null"
			>
				<img
					[style.height.px]="iconHeight"
					[src]="
						dataItem.ignitionStatus == 1
							? global.imagesUrl +
							  'assets/img/gse-images/power-on.svg'
							: global.imagesUrl +
							  'assets/img/gse-images/power-off-' +
							  global.Theme +
							  '.svg'
					"
				/>
			</div>
			<div
				style="text-align: center"
				*ngIf="dataItem.ignitionStatus === null"
			>
				-
			</div>
		</ng-template>

		<ng-template
			*ngIf="column.field === 'eMaster'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				*ngIf="dataItem.eMaster !== null"
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle(
						{ gse: dataItem, observationId: 63823 },
						57478
					)
				"
			>
				<i
					class="material-symbols-outlined"
					[ngClass]="{
						'material-symbols-outlined-on': dataItem.eMaster === 1,
						'material-symbols-outlined-light':
							dataItem.eMaster === 0 && global.Theme === 'dark',
						'material-symbols-outlined-dark':
							dataItem.eMaster === 0 && global.Theme === 'light'
					}"
				>
					e911_emergency
				</i>
			</div>
			<div style="text-align: center" *ngIf="dataItem.eMaster === null">
				-
			</div>
		</ng-template>

		<ng-template
			*ngIf="column.field === 'totalSeats'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				(click)="showSeatBeltPositions(dataItem)"
				style="cursor: pointer; text-align: center !important"
				matTooltip="Click to view Seat Positions"
				[matTooltipPosition]="'below'"
			>
				{{ dataItem.totalSeats }}
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'seatsOccupied'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				(click)="showSeatBeltPositions(dataItem)"
				style="cursor: pointer; text-align: center !important"
				matTooltip="Click to view Seat Positions"
				[matTooltipPosition]="'below'"
			>
				{{ dataItem.seatsOccupied }}
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'seatBeltsOn'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				(click)="showSeatBeltPositions(dataItem)"
				style="cursor: pointer; text-align: center !important"
				matTooltip="Click to view Seat Positions"
				[matTooltipPosition]="'below'"
			>
				{{ dataItem.seatBeltsOn }}
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'seatbeltOccupied'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				*ngFor="
					let seatbelt of dataItem.totalSeatbelts;
					let index = index
				"
			>
				<img
					[src]="gseService.seatStatus(dataItem, index, 'occupied')"
				/>
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'seatbeltEngaged'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				*ngFor="
					let seatbelt of dataItem.totalSeatbelts;
					let index = index
				"
			>
				<img
					[src]="gseService.seatStatus(dataItem, index, 'engaged')"
				/>
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'engineOn'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle(
						{ gse: dataItem, observationId: 19125 },
						57478
					)
				"
				*ngIf="dataItem.engineOn !== null"
			>
				<img
					[style.height.px]="iconHeight + 10"
					[src]="
						dataItem.engineOn == 1
							? global.imagesUrl +
							  'assets/img/gse-images/piston-running.svg'
							: global.imagesUrl +
							  'assets/img/gse-images/piston-off-' +
							  global.Theme +
							  '.svg'
					"
				/>
			</div>
			<div style="text-align: center" *ngIf="dataItem.engineOn === null">
				-
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'driveMode'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle({
						gse: dataItem,
						observationId: 19127
					})
				"
				*ngIf="dataItem.driveMode !== null"
			>
				<img
					[style.height.px]="iconHeight + 10"
					[src]="
						dataItem.driveMode == 1
							? global.imagesUrl +
							  'assets/img/gse-images/drive-mode-running.svg'
							: global.imagesUrl +
							  'assets/img/gse-images/drive-mode-idle-' +
							  global.Theme +
							  '.svg'
					"
				/>
			</div>
			<div style="text-align: center" *ngIf="dataItem.driveMode === null">
				-
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'operateMode'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle({ gse: dataItem, observationId: 54272 })
				"
				*ngIf="dataItem.operateMode !== null"
			>
				<img
					[style.height.px]="iconHeight + 10"
					[src]="
						dataItem.operateMode == 1
							? global.imagesUrl +
							  'assets/img/gse-images/drive-mode-running.svg'
							: global.imagesUrl +
							  'assets/img/gse-images/drive-mode-idle-' +
							  global.Theme +
							  '.svg'
					"
				/>
			</div>
			<div
				style="text-align: center"
				*ngIf="dataItem.operateMode === null"
			>
				-
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'dpfSootLoadPercent'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				class="mb-2"
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle(
						{ gse: dataItem, observationId: 19141 },
						19212
					)
				"
				*ngIf="dataItem.dpfSootLoadPercent !== null"
			>
				<p class="progress-value" style="text-align: center">
					{{ dataItem.dpfSootLoadPercent }}%
				</p>
				<div
					class="progress"
					[matTooltip]="
						evaluateProgressBarColor({
							value: dataItem.dpfSootLoadPercent,
							returnRuleDescription: true,
							nonstandardname: 'dpfSootLoadPercent'
						})
					"
				>
					<div
						class="progress-bar"
						[ngClass]="
							evaluateProgressBarColor({
								value: dataItem.dpfSootLoadPercent,
								nonstandardname: 'dpfSootLoadPercent'
							})
						"
						role="progressbar"
						[style.width.%]="dataItem.dpfSootLoadPercent"
						aria-valuenow="25"
						aria-valuemin="0"
						aria-valuemax="100"
					></div>
				</div>
			</div>
			<div
				style="text-align: center"
				*ngIf="dataItem.dpfSootLoadPercent === null"
			>
				-
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'engineCoolantLevel'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				class="mb-2"
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle(
						{ gse: dataItem, observationId: 19141 },
						19212
					)
				"
				*ngIf="dataItem.engineCoolantLevel !== null"
			>
				<p class="progress-value" style="text-align: center">
					{{ dataItem.engineCoolantLevel }}%
				</p>
				<div
					class="progress"
					[matTooltip]="
						evaluateProgressBarColor({
							value: dataItem.engineCoolantLevel,
							returnRuleDescription: true,
							nonstandardname: 'engineCoolantLevel'
						})
					"
				>
					<div
						class="progress-bar"
						[ngClass]="
							evaluateProgressBarColor({
								value: dataItem.engineCoolantLevel,
								nonstandardname: 'engineCoolantLevel'
							})
						"
						role="progressbar"
						[style.width.%]="dataItem.engineCoolantLevel"
						aria-valuenow="25"
						aria-valuemin="0"
						aria-valuemax="100"
					></div>
				</div>
			</div>
			<div
				style="text-align: center"
				*ngIf="dataItem.engineCoolantLevel === null"
			>
				-
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'fuelLevel'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				class="mb-2"
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle(
						{ gse: dataItem, observationId: 19141 },
						19212
					)
				"
				*ngIf="dataItem.fuelLevel !== null"
			>
				<p class="progress-value" style="text-align: center">
					{{ dataItem.fuelLevel }}%
				</p>
				<div
					class="progress"
					[matTooltip]="
						evaluateProgressBarColor({
							value: dataItem.fuelLevel,
							returnRuleDescription: true
						})
					"
				>
					<div
						class="progress-bar"
						[ngClass]="
							evaluateProgressBarColor({
								value: dataItem.fuelLevel
							})
						"
						role="progressbar"
						[style.width.%]="dataItem.fuelLevel"
						aria-valuenow="25"
						aria-valuemin="0"
						aria-valuemax="100"
					></div>
				</div>
			</div>
			<div style="text-align: center" *ngIf="dataItem.fuelLevel === null">
				-
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'batteryLevel'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				class="mb-2"
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle({ gse: dataItem, observationId: 55367 })
				"
				*ngIf="dataItem.batteryLevel !== null"
			>
				<p class="progress-value" style="text-align: center">
					{{ dataItem.batteryLevel }}%
				</p>
				<div
					class="progress"
					[matTooltip]="
						evaluateProgressBarColor({
							value: dataItem.batteryLevel,
							returnRuleDescription: true
						})
					"
				>
					<div
						class="progress-bar"
						[ngClass]="
							evaluateProgressBarColor({
								value: dataItem.batteryLevel
							})
						"
						role="progressbar"
						[style.width.%]="dataItem.batteryLevel"
						aria-valuenow="25"
						aria-valuemin="0"
						aria-valuemax="100"
					></div>
				</div>
			</div>
			<div
				style="text-align: center"
				*ngIf="dataItem.batteryLevel === null"
			>
				-
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'lowFuelLevel'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle({
						gse: dataItem,
						observationId: 54126
					})
				"
				*ngIf="dataItem.lowFuelLevel !== null"
			>
				<img
					[style.height.px]="iconHeight"
					[src]="
						dataItem.lowFuelLevel == 0
							? global.imagesUrl +
							  'assets/img/gse-images/fuel-level-green.svg'
							: global.imagesUrl +
							  'assets/img/gse-images/fuel-level-red.svg'
					"
				/>
			</div>
			<div
				style="text-align: center"
				*ngIf="dataItem.lowFuelLevel === null"
			>
				-
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'engineSpeed'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				class="mb-2"
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle({
						gse: dataItem,
						observationId: 19130
					})
				"
				*ngIf="dataItem.engineSpeed !== null"
			>
				<span class="progress-value">{{ dataItem.engineSpeed }} </span>
				<progressbar
					[max]="3000"
					[ngClass]="
						dataItem.engineSpeed >= 2500
							? 'customClassHigh'
							: dataItem.engineSpeed < 1000
							? 'customClassLowO'
							: 'customClassNormal'
					"
					[value]="returnParsedNumber(dataItem.engineSpeed)"
				></progressbar>
			</div>
			<div
				style="text-align: center"
				*ngIf="dataItem.engineSpeed === null"
			>
				-
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'activeFaults'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				*ngIf="
					dataItem.activeFaults !== null && dataItem.activeFaults != 0
				"
				style="text-align: center !important"
			>
				<span
					[ngClass]="{
						criticalAlarmFaults:
							dataItem.alertStatus === 'Critical',
						alarmFaults: dataItem.alertStatus === 'Alarm'
					}"
					style="text-align: center !important"
					>{{ dataItem.activeFaults }}</span
				>
			</div>
			<div
				style="text-align: center"
				*ngIf="
					dataItem.activeFaults === null || dataItem.activeFaults == 0
				"
			>
				<i
					class="tim-icons icon-check-2 noFaults"
					style="color: #f0f0f0; text-align: center"
				></i>
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'activeAlerts'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				style="text-align: center !important"
				*ngIf="
					dataItem.activeAlerts !== null && dataItem.activeAlerts != 0
				"
			>
				<span
					class="criticalAlarmFaults"
					style="text-align: center !important"
					>{{ dataItem.activeAlerts }}</span
				>
			</div>
			<div
				style="text-align: center"
				*ngIf="
					dataItem.activeAlerts === null || dataItem.activeAlerts == 0
				"
			>
				<i
					class="tim-icons icon-check-2 noFaults"
					style="color: #f0f0f0; text-align: center"
				></i>
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'driveMinutesToday'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				style="text-align: center"
				*ngIf="dataItem.driveMinutesToday !== null"
			>
				{{ dataItem.driveMinutesToday | number : "1.0-1" }}
			</div>
			<div
				style="text-align: center"
				*ngIf="dataItem.driveMinutesToday === null"
			>
				-
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'idleMinutesToday'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				style="text-align: center"
				*ngIf="dataItem.idleMinutesToday !== null"
			>
				{{ dataItem.idleMinutesToday | number : "1.0-1" }}
			</div>
			<div
				style="text-align: center"
				*ngIf="dataItem.idleMinutesToday === null"
			>
				-
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'minutesOperatedToday'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				style="text-align: center"
				*ngIf="dataItem.minutesOperatedToday !== null"
			>
				{{ dataItem.minutesOperatedToday | number : "1.0-1" }}
			</div>
			<div
				style="text-align: center"
				*ngIf="dataItem.minutesOperatedToday === null"
			>
				-
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'engineMinutesToday'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				style="text-align: center"
				*ngIf="dataItem.engineMinutesToday !== null"
			>
				{{ dataItem.engineMinutesToday | number : "1.0-1" }}
			</div>
			<div
				style="text-align: center"
				*ngIf="dataItem.engineMinutesToday === null"
			>
				-
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'idleTime'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div style="text-align: center" *ngIf="dataItem.idleTime !== null">
				{{ dataItem.idleTime | number : "1.0-1" }}
			</div>
			<div style="text-align: center" *ngIf="dataItem.idleTime === null">
				-
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'totalEngineRunTimeHrs'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				style="text-align: center"
				*ngIf="dataItem.totalEngineRunTimeHrs !== null"
			>
				{{ dataItem.totalEngineRunTimeHrs | number : "1.0-1" }}
			</div>
			<div
				style="text-align: center"
				*ngIf="dataItem.totalEngineRunTimeHrs === null"
			>
				-
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'CatalystDEFTankLevel'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				class="mb-2"
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle({
						gse: dataItem,
						observationId: 19229
					})
				"
				*ngIf="dataItem.CatalystDEFTankLevel !== null"
			>
				<p class="progress-value" style="text-align: center">
					{{ dataItem.CatalystDEFTankLevel }}%
				</p>
				<div
					class="progress"
					[matTooltip]="
						evaluateProgressBarColor({
							value: dataItem.CatalystDEFTankLevel,
							returnRuleDescription: true
						})
					"
				>
					<div
						class="progress-bar"
						[ngClass]="
							evaluateProgressBarColor({
								value: dataItem.CatalystDEFTankLevel
							})
						"
						role="progressbar"
						[style.width.%]="dataItem.CatalystDEFTankLevel"
						aria-valuenow="25"
						aria-valuemin="0"
						aria-valuemax="100"
					></div>
				</div>
			</div>
			<div
				style="text-align: center"
				*ngIf="dataItem.CatalystDEFTankLevel === null"
			>
				-
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'DeiceFluidTankLevelPercent'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				class="mb-2"
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle(
						{ gse: dataItem, observationId: 55222 },
						56349
					)
				"
				*ngIf="dataItem.DeiceFluidTankLevelPercent !== null"
			>
				<p class="progress-value" style="text-align: center">
					{{ dataItem.DeiceFluidTankLevelPercent }}%
				</p>
				<div
					class="progress"
					[matTooltip]="
						evaluateProgressBarColor({
							value: dataItem.DeiceFluidTankLevelPercent,
							returnRuleDescription: true
						})
					"
				>
					<div
						class="progress-bar"
						[ngClass]="
							evaluateProgressBarColor({
								value: dataItem.DeiceFluidTankLevelPercent
							})
						"
						role="progressbar"
						[style.width.%]="dataItem.DeiceFluidTankLevelPercent"
						aria-valuenow="25"
						aria-valuemin="0"
						aria-valuemax="100"
					></div>
				</div>
			</div>
			<div
				style="text-align: center"
				*ngIf="dataItem.DeiceFluidTankLevelPercent === null"
			>
				-
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'DeiceFluidTankLevelLiters'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				class="mb-2"
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle(
						{ gse: dataItem, observationId: 55223 },
						57483
					)
				"
				*ngIf="dataItem.DeiceFluidTankLevelLiters !== null"
			>
				{{ dataItem.DeiceFluidTankLevelLiters }}
			</div>
			<div
				style="text-align: center"
				*ngIf="dataItem.DeiceFluidTankLevelLiters === null"
			>
				-
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'AntiIceTankPercent'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				class="mb-2"
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle(
						{ gse: dataItem, observationId: 55218 },
						56350
					)
				"
				*ngIf="dataItem.AntiIceTankPercent !== null"
			>
				<p class="progress-value" style="text-align: center">
					{{ dataItem.AntiIceTankPercent }}%
				</p>
				<div
					class="progress"
					[matTooltip]="
						evaluateProgressBarColor({
							value: dataItem.AntiIceTankPercent,
							returnRuleDescription: true
						})
					"
				>
					<div
						class="progress-bar"
						[ngClass]="
							evaluateProgressBarColor({
								value: dataItem.AntiIceTankPercent
							})
						"
						role="progressbar"
						[style.width.%]="dataItem.AntiIceTankPercent"
						aria-valuenow="25"
						aria-valuemin="0"
						aria-valuemax="100"
					></div>
				</div>
			</div>
			<div
				style="text-align: center"
				*ngIf="dataItem.AntiIceTankPercent === null"
			>
				-
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'AntiIceTankVolumeLiters'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				class="mb-2"
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle(
						{ gse: dataItem, observationId: 55219 },
						57484
					)
				"
				*ngIf="dataItem.AntiIceTankVolumeLiters !== null"
			>
				{{ dataItem.AntiIceTankVolumeLiters }}
			</div>
			<div
				style="text-align: center"
				*ngIf="dataItem.AntiIceTankVolumeLiters === null"
			>
				-
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'AirFirstActive'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				class="mb-2"
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle({ gse: dataItem, observationId: 55083 })
				"
				*ngIf="dataItem.AirFirstActive !== null"
			>
				<i
					class="tim-icons icon-simple-remove"
					[style.color]="'red'"
					*ngIf="dataItem.AirFirstActive == 0"
				></i>
				<i
					class="tim-icons icon-check-2"
					[style.color]="'green'"
					*ngIf="dataItem.AirFirstActive == 1"
				></i>
			</div>
			<div
				style="text-align: center"
				*ngIf="dataItem.AirFirstActive === null"
			>
				-
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'OneManDrive'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				class="mb-2"
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle({ gse: dataItem, observationId: 54474 })
				"
				*ngIf="dataItem.OneManDrive !== null"
			>
				<i
					class="tim-icons icon-simple-remove"
					[style.color]="'red'"
					*ngIf="dataItem.OneManDrive == 0"
				></i>
				<i
					class="tim-icons icon-check-2"
					[style.color]="'green'"
					*ngIf="dataItem.OneManDrive == 1"
				></i>
			</div>
			<div
				style="text-align: center"
				*ngIf="dataItem.OneManDrive === null"
			>
				-
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'OneManDriveTrigger'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				class="mb-2"
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle({ gse: dataItem, observationId: 54821 })
				"
				*ngIf="dataItem.OneManDriveTrigger !== null"
			>
				<i
					class="tim-icons icon-simple-remove"
					[style.color]="'red'"
					*ngIf="dataItem.OneManDriveTrigger == 0"
				></i>
				<i
					class="tim-icons icon-check-2"
					[style.color]="'green'"
					*ngIf="dataItem.OneManDriveTrigger == 1"
				></i>
			</div>
			<div
				style="text-align: center"
				*ngIf="dataItem.OneManDriveTrigger === null"
			>
				-
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'EngineHours'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				class="mb-2"
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle({ gse: dataItem, observationId: 54909 })
				"
				*ngIf="dataItem.EngineHours !== null"
			>
				{{ dataItem.EngineHours }}
			</div>
			<div
				style="text-align: center"
				*ngIf="dataItem.EngineHours === null"
			>
				-
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'FuelConsumption'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				class="mb-2"
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle({ gse: dataItem, observationId: 19161 })
				"
				*ngIf="dataItem.FuelConsumption !== null"
			>
				{{ dataItem.FuelConsumption }}
			</div>
			<div
				style="text-align: center"
				*ngIf="dataItem.FuelConsumption === null"
			>
				-
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'BoomOverrideRs'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				class="mb-2"
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle({ gse: dataItem, observationId: 55023 })
				"
				*ngIf="dataItem.BoomOverrideRs !== null"
			>
				<i
					class="tim-icons icon-simple-remove"
					[style.color]="'red'"
					*ngIf="dataItem.BoomOverrideRs == 0"
				></i>
				<i
					class="tim-icons icon-check-2"
					[style.color]="'green'"
					*ngIf="dataItem.BoomOverrideRs == 1"
				></i>
			</div>
			<div
				style="text-align: center"
				*ngIf="dataItem.BoomOverrideRs === null"
			>
				-
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'BoomOverrideTg'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				class="mb-2"
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle({ gse: dataItem, observationId: 54897 })
				"
				*ngIf="dataItem.BoomOverrideTg !== null"
			>
				<i
					class="tim-icons icon-simple-remove"
					[style.color]="'red'"
					*ngIf="dataItem.BoomOverrideTg == 0"
				></i>
				<i
					class="tim-icons icon-check-2"
					[style.color]="'green'"
					*ngIf="dataItem.BoomOverrideTg == 1"
				></i>
			</div>
			<div
				style="text-align: center"
				*ngIf="dataItem.BoomOverrideTg === null"
			>
				-
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'EngineOverrideFuel'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				class="mb-2"
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle({ gse: dataItem, observationId: 55128 })
				"
				*ngIf="dataItem.EngineOverrideFuel !== null"
			>
				<i
					class="tim-icons icon-simple-remove"
					[style.color]="'red'"
					*ngIf="dataItem.EngineOverrideFuel == 0"
				></i>
				<i
					class="tim-icons icon-check-2"
					[style.color]="'green'"
					*ngIf="dataItem.EngineOverrideFuel == 1"
				></i>
			</div>
			<div
				style="text-align: center"
				*ngIf="dataItem.EngineOverrideFuel === null"
			>
				-
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'EngineOverrideLowFuel'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				class="mb-2"
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle({ gse: dataItem, observationId: 55018 })
				"
				*ngIf="dataItem.EngineOverrideLowFuel !== null"
			>
				<i
					class="tim-icons icon-simple-remove"
					[style.color]="'red'"
					*ngIf="dataItem.EngineOverrideLowFuel == 0"
				></i>
				<i
					class="tim-icons icon-check-2"
					[style.color]="'green'"
					*ngIf="dataItem.EngineOverrideLowFuel == 1"
				></i>
			</div>
			<div
				style="text-align: center"
				*ngIf="dataItem.EngineOverrideLowFuel === null"
			>
				-
			</div>
		</ng-template>

		<ng-template
			*ngIf="column.field === 'BasketSeatBelt'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				class="mb-2"
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle({ gse: dataItem, observationId: 54858 })
				"
				*ngIf="dataItem.BasketSeatBelt !== null"
			>
				<i
					class="tim-icons icon-simple-remove"
					[style.color]="'red'"
					*ngIf="dataItem.BasketSeatBelt == 0"
				></i>
				<i
					class="tim-icons icon-check-2"
					[style.color]="'green'"
					*ngIf="dataItem.BasketSeatBelt == 1"
				></i>
			</div>
			<div
				style="text-align: center"
				*ngIf="dataItem.BasketSeatBelt === null"
			>
				-
			</div>
		</ng-template>

		<ng-template
			*ngIf="column.field === 'AmbientTempBasket'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				class="mb-2"
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle({ gse: dataItem, observationId: 55205 })
				"
				*ngIf="dataItem.AmbientTempBasket !== null"
			>
				{{ dataItem.AmbientTempBasket }}
			</div>
			<div
				style="text-align: center"
				*ngIf="dataItem.AmbientTempBasket === null"
			>
				-
			</div>
		</ng-template>

		<ng-template
			*ngIf="column.field === 'HeaterTempInlet'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				class="mb-2"
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle({ gse: dataItem, observationId: 55159 })
				"
				*ngIf="dataItem.HeaterTempInlet !== null"
			>
				{{ dataItem.HeaterTempInlet }}
			</div>
			<div
				style="text-align: center"
				*ngIf="dataItem.HeaterTempInlet === null"
			>
				-
			</div>
		</ng-template>

		<ng-template
			*ngIf="column.field === 'HeaterTempOutlet'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				class="mb-2"
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle({ gse: dataItem, observationId: 55160 })
				"
				*ngIf="dataItem.HeaterTempOutlet !== null"
			>
				{{ dataItem.HeaterTempOutlet }}
			</div>
			<div
				style="text-align: center"
				*ngIf="dataItem.HeaterTempOutlet === null"
			>
				-
			</div>
		</ng-template>

		<ng-template
			*ngIf="column.field === 'HeaterTempNozzle'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				class="mb-2"
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle({ gse: dataItem, observationId: 54893 })
				"
				*ngIf="dataItem.HeaterTempNozzle !== null"
			>
				{{ dataItem.HeaterTempNozzle }}
			</div>
			<div
				style="text-align: center"
				*ngIf="dataItem.HeaterTempNozzle === null"
			></div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'latitude'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				class="mb-2"
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle({ gse: dataItem, observationId: 54260 })
				"
				*ngIf="dataItem.latitude !== null"
			>
				{{ dataItem.latitude }}
			</div>
			<div *ngIf="dataItem.latitude === null"></div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'longitude'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				class="mb-2"
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle({ gse: dataItem, observationId: 54261 })
				"
				*ngIf="dataItem.longitude !== null"
			>
				{{ dataItem.longitude }}
			</div>
			<div *ngIf="dataItem.longitude === null"></div>
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.field === 'pbbOn'"
		>
			<div
				*ngIf="dataItem.pbbId"
				title="Click for more Details"
				style="text-align: center; cursor: pointer"
				(click)="openPBBSummary(dataItem.pbbId)"
				[ngClass]="{
					'out-of-service-container':
						dataItem.pbbCommsLoss ||
						dataItem.pbbOutOfService ||
						dataItem.pbbMaintenanceMode
				}"
			>
				<div
					*ngIf="
						!dataItem.pbbCommsLoss &&
						!dataItem.pbbOutOfService &&
						!dataItem.pbbMaintenanceMode
					"
					[ngClass]="{
						'unit-on': dataItem.pbbOn,
						'unit-off': !dataItem.pbbOn,
						'alarm-active': dataItem.pbbAlarmActive,
						'critical-alarm-active':
							dataItem.pbbCriticalAlarmActive,
						'alarm-inactive': !dataItem.pbbAlarmActive
					}"
				>
					PBB
				</div>
				<div
					class="out-of-service"
					*ngIf="
						dataItem.pbbCommsLoss ||
						dataItem.pbbOutOfService ||
						dataItem.pbbMaintenanceMode
					"
				>
					<img
						*ngIf="dataItem.pbbOutOfService"
						[style.height.px]="iconHeight"
						[src]="
							global.imagesUrl + 'assets/img/out-of-service.svg'
						"
					/>
					<img
						*ngIf="dataItem.pbbMaintenanceMode"
						[style.height.px]="iconHeight"
						[src]="
							global.imagesUrl + 'assets/img/maintenance-mode.svg'
						"
					/>
					<img
						*ngIf="
							dataItem.pbbCommsLoss &&
							!dataItem.pbbOutOfService &&
							!dataItem.pbbMaintenanceMode
						"
						[style.height.px]="iconHeight"
						[src]="global.imagesUrl + 'assets/img/comms-loss.svg'"
					/>
				</div>
			</div>
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.field === 'pcaOn'"
		>
			<div
				*ngIf="dataItem.pcaId"
				title="Click for more Details"
				style="text-align: center; cursor: pointer"
				(click)="openPCASummary(dataItem.pcaId)"
				[ngClass]="{
					'out-of-service-container':
						dataItem.pcaCommsLoss ||
						dataItem.pcaOutOfService ||
						dataItem.pcaMaintenanceMode
				}"
			>
				<div
					*ngIf="
						!dataItem.pcaCommsLoss &&
						!dataItem.pcaOutOfService &&
						!dataItem.pcaMaintenanceMode
					"
					[ngClass]="{
						'pca-unit-on-not-bridge-mode':
							dataItem.pcaOn && dataItem.pcaDischMode != 'Bridge',
						'pca-unit-on-bridge-mode':
							dataItem.pcaOn && dataItem.pcaDischMode == 'Bridge',
						'unit-off': !dataItem.pcaOn,
						'alarm-active': dataItem.pcaAlarmActive,
						'critical-alarm-active':
							dataItem.pcaCriticalAlarmActive,
						'alarm-inactive': !dataItem.pcaAlarmActive
					}"
				>
					PCA
				</div>
				<div
					class="out-of-service"
					*ngIf="
						dataItem.pcaCommsLoss ||
						dataItem.pcaOutOfService ||
						dataItem.pcaMaintenanceMode
					"
				>
					<img
						*ngIf="dataItem.pcaOutOfService"
						[style.height.px]="iconHeight"
						[src]="
							global.imagesUrl + 'assets/img/out-of-service.svg'
						"
					/>
					<img
						*ngIf="dataItem.pcaMaintenanceMode"
						[style.height.px]="iconHeight"
						[src]="
							global.imagesUrl + 'assets/img/maintenance-mode.svg'
						"
					/>
					<img
						*ngIf="
							dataItem.pcaCommsLoss &&
							!dataItem.pcaOutOfService &&
							!dataItem.pcaMaintenanceMode
						"
						[style.height.px]="iconHeight"
						[src]="global.imagesUrl + 'assets/img/comms-loss.svg'"
					/>
				</div>
			</div>
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.field === 'gpuOn'"
		>
			<div
				*ngIf="dataItem.gpuId"
				title="Click for more Details"
				style="text-align: center; cursor: pointer"
				(click)="openGPUSummary(dataItem.gpuId)"
				[ngClass]="{
					'out-of-service-container':
						dataItem.gpuCommsLoss ||
						dataItem.gpuOutOfService ||
						dataItem.gpuMaintenanceMode
				}"
			>
				<div
					*ngIf="
						!dataItem.gpuCommsLoss &&
						!dataItem.gpuOutOfService &&
						!dataItem.gpuMaintenanceMode
					"
					[ngClass]="{
						'gpu-unit-on-and-switched-over':
							dataItem.gpuOn && dataItem.gpuSwitchedOver,
						'gpu-unit-on-and-not-switched-over':
							dataItem.gpuOn && !dataItem.gpuSwitchedOver,
						'unit-off': !dataItem.gpuOn,
						'alarm-active': dataItem.gpuAlarmActive,
						'critical-alarm-active':
							dataItem.gpuCriticalAlarmActive,
						'alarm-inactive': !dataItem.gpuAlarmActive
					}"
				>
					GPU
				</div>
				<div
					class="out-of-service"
					*ngIf="
						dataItem.gpuCommsLoss ||
						dataItem.gpuOutOfService ||
						dataItem.gpuMaintenanceMode
					"
				>
					<img
						*ngIf="dataItem.gpuOutOfService"
						[style.height.px]="iconHeight"
						[src]="
							global.imagesUrl + 'assets/img/out-of-service.svg'
						"
					/>
					<img
						*ngIf="dataItem.gpuMaintenanceMode"
						[style.height.px]="iconHeight"
						[src]="
							global.imagesUrl + 'assets/img/maintenance-mode.svg'
						"
					/>
					<img
						*ngIf="
							dataItem.gpuCommsLoss &&
							!dataItem.gpuOutOfService &&
							!dataItem.gpuMaintenanceMode
						"
						[style.height.px]="iconHeight"
						[src]="global.imagesUrl + 'assets/img/comms-loss.svg'"
					/>
				</div>
			</div>
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.field === 'ahuOn'"
		>
			<div
				*ngIf="dataItem.ahuId"
				title="Click for more Details"
				style="text-align: center; cursor: pointer"
				(click)="openAHUSummary(dataItem.ahuId)"
				[ngClass]="{
					'out-of-service-container':
						dataItem.ahuCommsLoss ||
						dataItem.ahuOutOfService ||
						dataItem.ahuMaintenanceMode
				}"
			>
				<div
					*ngIf="
						!dataItem.ahuCommsLoss &&
						!dataItem.ahuOutOfService &&
						!dataItem.ahuMaintenanceMode
					"
					[ngClass]="{
						'pca-unit-on-not-bridge-mode':
							dataItem.ahuOn &&
							dataItem.pcaDischargeMode != 'Bridge',
						'pca-unit-on-bridge-mode':
							dataItem.ahuOn && dataItem.pcaDischMode == 'Bridge',
						'unit-off': !dataItem.ahuOn,
						'alarm-active': dataItem.ahuAlarmActive,
						'critical-alarm-active':
							dataItem.ahuCriticalAlarmActive,
						'alarm-inactive': !dataItem.ahuAlarmActive
					}"
				>
					AHU
				</div>
				<div
					class="out-of-service"
					*ngIf="
						dataItem.ahuCommsLoss ||
						dataItem.ahuOutOfService ||
						dataItem.ahuMaintenanceMode
					"
				>
					<img
						*ngIf="dataItem.ahuOutOfService"
						[style.height.px]="iconHeight"
						[src]="
							global.imagesUrl + 'assets/img/out-of-service.svg'
						"
					/>
					<img
						*ngIf="dataItem.ahuMaintenanceMode"
						[style.height.px]="iconHeight"
						[src]="
							global.imagesUrl + 'assets/img/maintenance-mode.svg'
						"
					/>
					<img
						*ngIf="
							dataItem.ahuCommsLoss &&
							!dataItem.ahuOutOfService &&
							!dataItem.ahuMaintenanceMode
						"
						[style.height.px]="iconHeight"
						[src]="global.imagesUrl + 'assets/img/comms-loss.svg'"
					/>
				</div>
			</div>
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.field === 'NextArrival'"
		>
			<div>
				<lib-perfect-turn
					siteName="{{ widgetObject?.WidgetSiteName }}"
					gateName="{{ dataItem.gate }}"
					parentWidgetName="{{ widgetObject?.WidgetName }}"
					parentWidgetTimeZoneId="{{ widgetObject?.TimeZoneId }}"
					[dashboardTimeZoneChanged]="dashboardTimeZoneChanged"
				>
				</lib-perfect-turn>
			</div>
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.field === 'pbbAircraftDocked'"
		>
			<div
				*ngIf="
					!dataItem.pbbCommsLoss &&
					!dataItem.pbbOutOfService &&
					!dataItem.pbbMaintenanceMode
				"
			>
				<img
					*ngIf="dataItem.pbbAircraftDocked"
					style="height: 20px"
					[src]="global.imagesUrl + 'assets/img/airplane.png'"
				/>
			</div>
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.field === 'pcaDischTemp'"
		>
			<div
				*ngIf="
					!dataItem.pcaCommsLoss &&
					!dataItem.pcaOutOfService &&
					!dataItem.pcaMaintenanceMode
				"
				[ngClass]="{
					'hmi-on':
						dataItem.pcaOn && dataItem.pcaDischMode != 'Bridge',
					'hmi-pca-unit-on-bridge-mode':
						dataItem.pcaOn && dataItem.pcaDischMode == 'Bridge',
					'hmi-off': !dataItem.pcaOn,
					'hmi-alarm': dataItem.pcaAlarmActive,
					'hmi-critical-alarm': dataItem.pcaCriticalAlarmActive
				}"
			>
				{{
					dataItem.pcaDischTemp != undefined
						? dataItem.pcaDischTemp
						: ""
				}}
			</div>
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.field === 'gpuAmpsOutAvg'"
		>
			<div
				*ngIf="
					!dataItem.gpuCommsLoss &&
					!dataItem.gpuOutOfService &&
					!dataItem.gpuMaintenanceMode
				"
				[ngClass]="{
					'hmi-on': dataItem.gpuOn && dataItem.gpuSwitchedOver,
					'hmi-gpu-unit-on-and-not-switched-over':
						dataItem.gpuOn && !dataItem.gpuSwitchedOver,
					'hmi-off': !dataItem.gpuOn,
					'hmi-alarm': dataItem.gpuAlarmActive,
					'hmi-critical-alarm': dataItem.gpuCriticalAlarmActive
				}"
			>
				{{
					dataItem.gpuAmpsOutAvg != undefined
						? dataItem.gpuAmpsOutAvg
						: ""
				}}
			</div>
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.field === 'pbbRuntime'"
		>
			<div
				*ngIf="
					!dataItem.pbbCommsLoss &&
					!dataItem.pbbOutOfService &&
					!dataItem.pbbMaintenanceMode
				"
				[ngClass]="{
					'hmi-on': dataItem.pbbOn,
					'hmi-off': !dataItem.pbbOn,
					'hmi-alarm': dataItem.pbbAlarmActive,
					'hmi-critical-alarm': dataItem.pbbCriticalAlarmActive
				}"
			>
				{{ dataItem.pbbRuntime | number : "1.0" }}
			</div>
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.field === 'pcaRuntime'"
		>
			<div
				*ngIf="
					!dataItem.pcaCommsLoss &&
					!dataItem.pcaOutOfService &&
					!dataItem.pcaMaintenanceMode
				"
				[ngClass]="{
					'hmi-on':
						dataItem.pcaOn && dataItem.pcaDischMode != 'Bridge',
					'hmi-pca-unit-on-bridge-mode':
						dataItem.pcaOn && dataItem.pcaDischMode == 'Bridge',
					'hmi-off': !dataItem.pcaOn,
					'hmi-alarm': dataItem.pcaAlarmActive,
					'hmi-critical-alarm': dataItem.pcaCriticalAlarmActive
				}"
			>
				{{ dataItem.pcaRuntime | number : "1.0" }}
			</div>
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.field === 'gpuRuntime'"
		>
			<div
				*ngIf="
					!dataItem.gpuCommsLoss &&
					!dataItem.gpuOutOfService &&
					!dataItem.gpuMaintenanceMode
				"
				[ngClass]="{
					'hmi-on': dataItem.gpuOn && dataItem.gpuSwitchedOver,
					'hmi-gpu-unit-on-and-not-switched-over':
						dataItem.gpuOn && !dataItem.gpuSwitchedOver,
					'hmi-off': !dataItem.gpuOn,
					'hmi-alarm': dataItem.gpuAlarmActive,
					'hmi-critical-alarm': dataItem.gpuCriticalAlarmActive
				}"
			>
				{{ dataItem.gpuRuntime | number : "1.0" }}
			</div>
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.field === 'ahuRuntime'"
		>
			<div
				*ngIf="
					!dataItem.ahuCommsLoss &&
					!dataItem.ahuOutOfService &&
					!dataItem.ahuMaintenanceMode
				"
				[ngClass]="{
					'hmi-on': dataItem.ahuOn,
					'hmi-off': !dataItem.ahuOn,
					'hmi-alarm': dataItem.ahuAlarmActive,
					'hmi-critical-alarm': dataItem.ahuCriticalAlarmActive
				}"
			>
				{{ dataItem.ahuRuntime | number : "1.0" }}
			</div>
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.field === 'gpuAmpsOutChart'"
		>
			<div
				*ngIf="
					!dataItem.gpuCommsLoss &&
					!dataItem.gpuOutOfService &&
					!dataItem.gpuMaintenanceMode &&
					dataItem.gpuAmpsOutChart &&
					dataItem.gpuAmpsOutChart[0].data.length > 0
				"
			>
				<kendo-sparkline
					#gpuCharts
					renderAs="canvas"
					[series]="dataItem.gpuAmpsOutChart"
					[resizeRateLimit]="1"
				>
					<kendo-chart-area background="transparent">
					</kendo-chart-area>
					<kendo-chart-tooltip>
						<ng-template
							kendoChartSeriesTooltipTemplate
							let-dataItem="dataItem"
						>
							<div>
								<span
									><strong>{{ dataItem.y }}</strong></span
								><br />
								<span *ngIf="timeZoneType === 'User Time'">{{
									dataItem.obsTimeFormattedLocal
								}}</span>
								<span *ngIf="timeZoneType === 'Site Time'">{{
									dataItem.obsTimeFormattedSite
								}}</span>
								<span *ngIf="timeZoneType === 'UTC Time'">{{
									dataItem.obsTimeFormattedUTC
								}}</span>
							</div>
						</ng-template>
					</kendo-chart-tooltip>
				</kendo-sparkline>
			</div>
			<div
				*ngIf="
					!dataItem.gpuCommsLoss &&
					!dataItem.gpuOutOfService &&
					!dataItem.gpuMaintenanceMode &&
					dataItem.gpuAmpsOutChart &&
					dataItem.gpuAmpsOutChart[0].data.length == 0
				"
			>
				No Data
			</div>
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.field === 'pcaDischTempChart'"
		>
			<div
				*ngIf="
					!dataItem.pcaCommsLoss &&
					!dataItem.pcaOutOfService &&
					!dataItem.pcaMaintenanceMode &&
					dataItem.pcaDischTempChart &&
					dataItem.pcaDischTempChart[0]?.data.length > 0
				"
			>
				<kendo-sparkline
					#pcaCharts
					renderAs="canvas"
					[series]="dataItem.pcaDischTempChart"
					[resizeRateLimit]="1"
				>
					<kendo-chart-area background="transparent">
					</kendo-chart-area>
					<kendo-chart-tooltip
						[color]="
							dataItem.pcaOpMode == 'Cool'
								? 'Blue'
								: dataItem.pcaOpMode == 'Heat'
								? 'Red'
								: dataItem.pcaOpMode == 'Vent'
								? 'White'
								: 'Green'
						"
					>
						<ng-template
							kendoChartSeriesTooltipTemplate
							let-dataItem="dataItem"
						>
							<div>
								<span
									><strong>{{ dataItem.y }}</strong></span
								><br />
								<span *ngIf="timeZoneType === 'User Time'">{{
									dataItem.obsTimeFormattedLocal
								}}</span>
								<span *ngIf="timeZoneType === 'Site Time'">{{
									dataItem.obsTimeFormattedSite
								}}</span>
								<span *ngIf="timeZoneType === 'UTC Time'">{{
									dataItem.obsTimeFormattedUTC
								}}</span>
							</div>
						</ng-template>
					</kendo-chart-tooltip>
				</kendo-sparkline>
			</div>
			<div
				*ngIf="
					!dataItem.gpuCommsLoss &&
					!dataItem.gpuOutOfService &&
					!dataItem.gpuMaintenanceMode &&
					dataItem.pcaDischTempChart &&
					dataItem.pcaDischTempChart[0]?.data.length == 0
				"
			>
				No Data
			</div>
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.field === 'pcaDischMode'"
		>
			<div
				*ngIf="
					!dataItem.pcaCommsLoss &&
					!dataItem.pcaOutOfService &&
					!dataItem.pcaMaintenanceMode
				"
			>
				{{ dataItem.pcaDischMode ? dataItem.pcaDischMode : "" }}
			</div>
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.field === 'pcaOpMode'"
		>
			<div
				*ngIf="
					!dataItem.pcaCommsLoss &&
					!dataItem.pcaOutOfService &&
					!dataItem.pcaMaintenanceMode
				"
			>
				<img
					*ngIf="dataItem.pcaOpMode !== 'UDF'"
					style="height: 30px"
					[src]="
						dataItem.pcaOpMode == 'Cool'
							? global.imagesUrl +
							  'assets/img/gs-images/pcaOpMode-Cool.png'
							: dataItem.pcaOpMode == 'Heat'
							? global.imagesUrl +
							  'assets/img/gs-images/pcaOpMode-Heat.png'
							: dataItem.pcaOpMode == 'Vent'
							? global.imagesUrl +
							  'assets/img/gs-images/pcaOpMode-Vent.png'
							: global.imagesUrl +
							  'assets/img/gs-images/pcaOpMode-Auto.png'
					"
				/>
				<p *ngIf="dataItem.pcaOpMode == 'UDF'">UDF</p>
			</div>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'isSelected'"
			kendoGridCellTemplate
			let-dataItem
		>
			<td>
				<button
					style="padding: 3px 6px; height: 25px"
					class="jbt-btn btn-sm"
					(click)="toggleAssetInFormList(dataItem)"
				>
					<i
						*ngIf="
							!assetsForSelectedZoneType?.includes(dataItem.Id)
						"
						class="far fa-square"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
						[style.font-size]="'12px'"
					></i>
					<i
						*ngIf="assetsForSelectedZoneType?.includes(dataItem.Id)"
						class="fas fa-check"
						[ngStyle]="{
							color: theme === 'light' ? 'green' : 'green'
						}"
						[style.font-size]="'12px'"
					></i>
				</button>
			</td>
		</ng-template>
		<ng-template
			*ngIf="column.field === 'trueDateObject'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div>
				{{ dataItem.trueDateObject | date : "medium" }}
			</div>
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.field === 'CommsLossTime'"
		>
			<div>
				{{ dataItem.CommsLossTime | number : "1.0" }}&nbsp;<span
					style="font-size: xx-small"
					>min</span
				>
			</div>
		</ng-template>

		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.field === 'gate'"
		>
			<div
				title="Click for more Details"
				style="text-align: center; cursor: pointer"
				(click)="openWCLSummary(dataItem.assetId)"
			>
				<div>{{ dataItem.gate }}</div>
			</div>
		</ng-template>

		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="
				column.field === 'power' &&
				widgetObject?.AngularDirectiveName === 'wheelChairOverview'
			"
		>
			<div>
				<div
					[ngClass]="{
						'unit-on': dataItem.power,
						'unit-alarm': !dataItem.power
					}"
					[ngStyle]="{
						border: theme === 'light' ? '#27304c' : 'white'
					}"
				>
					&nbsp;
				</div>
			</div>
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.field === 'eStop'"
		>
			<div>
				<div
					[ngClass]="{
						'unit-on': dataItem.eStop,
						'unit-alarm': !dataItem.eStop
					}"
					[ngStyle]="{
						border: theme === 'light' ? '#27304c' : 'white'
					}"
				>
					&nbsp;
				</div>
			</div>
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.field === 'gateClosed'"
		>
			<div>
				<div
					[ngClass]="{
						'unit-on': dataItem.gateClosed,
						'unit-alarm': !dataItem.gateClosed
					}"
					[ngStyle]="{
						border: theme === 'light' ? '#27304c' : 'white'
					}"
				>
					&nbsp;
				</div>
			</div>
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.field === 'liftDown'"
		>
			<div>
				<div
					[ngClass]="{
						'unit-on': dataItem.liftDown,
						'unit-alarm': !dataItem.liftDown
					}"
					[ngStyle]="{
						border: theme === 'light' ? '#27304c' : 'white'
					}"
				>
					&nbsp;
				</div>
			</div>
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.field === 'failSafeLimit'"
		>
			<div>
				<div
					[ngClass]="{
						'unit-on': dataItem.failSafeLimit,
						'unit-alarm': !dataItem.failSafeLimit
					}"
					[ngStyle]="{
						border: theme === 'light' ? '#27304c' : 'white'
					}"
				>
					&nbsp;
				</div>
			</div>
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.field === 'failSafeSolenoid'"
		>
			<div>
				<div
					[ngClass]="{
						'unit-on': dataItem.failSafeSolenoid,
						'unit-alarm': !dataItem.failSafeSolenoid
					}"
					[ngStyle]="{
						border: theme === 'light' ? '#27304c' : 'white'
					}"
				>
					&nbsp;
				</div>
			</div>
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="
				column.field === 'Name' &&
				addWidgetToDashboardEmitter.observers.length > 0
			"
		>
			<div
				(click)="addWidgetToDashboard(dataItem)"
				style="cursor: pointer"
			>
				{{ dataItem.Name }}
			</div>
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.field === 'Description' && gridPurpose === 'logbook'"
		>
			<div
				(click)="editNote(dataItem, false)"
				style="cursor: pointer"
				[innerHTML]="dataItem.Description"
				[matTooltip]="'View Note'"
			></div>
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.field === 'Description' && gridPurpose !== 'logbook'"
		>
			<div
				(click)="addWidgetToDashboard(dataItem)"
				style="cursor: pointer"
				[innerHTML]="dataItem.Description"
			></div>
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.field === 'TrainingLesson'"
		>
			<td>
				<button
					style="padding: 3px 6px; height: 25px"
					class="jbt-btn btn-sm"
					(click)="loadVideo(dataItem)"
					*ngIf="dataItem.TrainingCourseLesson?.VideoImageKey"
				>
					<i class="tim-icons icon-triangle-right-17"></i>
				</button>
			</td>
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.field === 'Category'"
		>
			<div>
				{{ dataItem.Category }}
			</div>
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.field === 'alertType'"
		>
			<div>
				{{ dataItem.alertType }}
			</div>
		</ng-template>

		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.field === 'notificationActive'"
		>
			<div>
				<mat-icon
					*ngIf="dataItem.notificationActive == 'success'"
					style="color: green"
				>
					check_circle_outline
				</mat-icon>
			</div>
		</ng-template>

		<ng-template
			*ngIf="column.field === 'EditAlert'"
			kendoGridCellTemplate
			let-dataItem
		>
			<td>
				<button
					style="padding: 3px 6px; height: 25px"
					class="jbt-btn btn-sm"
					(click)="editAlert(dataItem)"
				>
					<i class="tim-icons icon-pencil" style="color: #f0f0f0"></i>
				</button>

				<button
					style="padding: 3px 6px; height: 25px"
					class="jbt-btn btn-sm"
					(click)="deleteAlert(dataItem)"
				>
					<i
						class="tim-icons icon-simple-remove"
						style="color: red"
					></i>
				</button>
			</td>
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.field === 'Severity'"
		>
			<div
				[ngClass]="{
					'fleet-amber': dataItem.Severity == 'Amber',
					'fleet-alarm': dataItem.Severity == 'Alarm',
					'fleet-critical':
						dataItem.Severity == 'Critical' ||
						dataItem.Severity == 'Red',
					'fleet-alert': dataItem.Severity == 'Alert'
				}"
				[style.text-align]="'center'"
			>
				{{ dataItem.Severity }}
			</div>
		</ng-template>
		<ng-template
			kendoGridCellTemplate
			let-dataItem
			*ngIf="column.centered === true"
		>
			<div
				class="mb-2"
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle({
						gse: dataItem,
						observationId: column.columnObId
					})
				"
				*ngIf="
					dataItem[column.field] !== null && column.trendable === true
				"
			>
				{{
					column.ignoreDecimalDefault
						? dataItem[column.field]
						: (dataItem[column.field] | decimalPlaces : 1)
				}}
			</div>
			<div
				class="mb-2"
				style="text-align: center"
				*ngIf="dataItem[column.field] !== null && !column.trendable"
				(click)="test(dataItem, column)"
			>
				{{
					column.ignoreDecimalDefault
						? dataItem[column.field]
						: (dataItem[column.field] | decimalPlaces : 1)
				}}
			</div>
			<div
				style="text-align: center"
				*ngIf="dataItem[column.field] === null"
			>
				-
			</div>
		</ng-template>

		<ng-template
			*ngIf="column.field === 'WaterTankLevelPercent'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				class="mb-2"
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle(
						{ gse: dataItem, observationId: 55218 },
						55220
					)
				"
				*ngIf="dataItem.WaterTankLevelPercent !== null"
			>
				<p class="progress-value" style="text-align: center">
					{{ dataItem.WaterTankLevelPercent }}%
				</p>
				<div
					class="progress"
					[matTooltip]="
						evaluateProgressBarColor({
							value: dataItem.WaterTankLevelPercent,
							returnRuleDescription: true
						})
					"
				>
					<div
						class="progress-bar"
						[ngClass]="
							evaluateProgressBarColor({
								value: dataItem.WaterTankLevelPercent
							})
						"
						role="progressbar"
						[style.width.%]="dataItem.WaterTankLevelPercent"
						aria-valuenow="25"
						aria-valuemin="0"
						aria-valuemax="100"
					></div>
				</div>
			</div>
			<div
				style="text-align: center"
				*ngIf="dataItem.WaterTankLevelPercent === null"
			>
				-
			</div>
		</ng-template>

		<ng-template
			*ngIf="column.field === 'WaterTankVolumeLiters'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				class="mb-2"
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle({ gse: dataItem, observationId: 55221 })
				"
				*ngIf="dataItem.WaterTankVolumeLiters !== null"
			>
				{{ dataItem.WaterTankVolumeLiters }}
			</div>
			<div
				style="text-align: center"
				*ngIf="dataItem.WaterTankVolumeLiters === null"
			>
				-
			</div>
		</ng-template>

		<ng-template
			*ngIf="column.field === 'WaterTankTemp'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				class="mb-2"
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle({ gse: dataItem, observationId: 55215 })
				"
				*ngIf="dataItem.WaterTankTemp !== null"
			>
				{{ dataItem.WaterTankTemp }}&deg;C
			</div>
			<div
				style="text-align: center"
				*ngIf="dataItem.WaterTankTemp === null"
			>
				-
			</div>
		</ng-template>

		<ng-template
			*ngIf="column.field === 'EngineAirInletTemp'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				class="mb-2"
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle({ gse: dataItem, observationId: 19297 })
				"
				*ngIf="dataItem.EngineAirInletTemp !== null"
			>
				{{ dataItem.EngineAirInletTemp }}&deg;C
			</div>
			<div
				style="text-align: center"
				*ngIf="dataItem.EngineAirInletTemp === null"
			>
				-
			</div>
		</ng-template>

		<ng-template
			*ngIf="column.field === 'AntiIcePumpFlowLiters'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				class="mb-2"
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle({ gse: dataItem, observationId: 55180 })
				"
				*ngIf="dataItem.AntiIcePumpFlowLiters !== null"
			>
				{{ dataItem.AntiIcePumpFlowLiters }}
			</div>
			<div
				style="text-align: center"
				*ngIf="dataItem.AntiIcePumpFlowLiters === null"
			>
				-
			</div>
		</ng-template>

		<ng-template
			*ngIf="column.field === 'DeicePumpFlowLiters'"
			kendoGridCellTemplate
			let-dataItem
		>
			<div
				class="mb-2"
				style="cursor: pointer; text-align: center"
				(click)="
					openTagGraphSingle({ gse: dataItem, observationId: 55174 })
				"
				*ngIf="dataItem.DeicePumpFlowLiters !== null"
			>
				{{ dataItem.DeicePumpFlowLiters }}
			</div>
			<div
				style="text-align: center"
				*ngIf="dataItem.DeicePumpFlowLiters === null"
			>
				-
			</div>
		</ng-template>
	</kendo-grid-column>

	<ng-template
		kendoPagerTemplate
		let-totalPages="totalPages"
		let-currentPage="currentPage"
	>
		<kendo-pager-prev-buttons></kendo-pager-prev-buttons>
		<kendo-pager-numeric-buttons
			[buttonCount]="5"
		></kendo-pager-numeric-buttons>
		<kendo-pager-next-buttons></kendo-pager-next-buttons>
		<!-- <kendo-pager-input></kendo-pager-input> -->
		<kendo-pager-info></kendo-pager-info>
		<kendo-pager-page-sizes
			[pageSizes]="[15, 30, 50]"
		></kendo-pager-page-sizes>
	</ng-template>
</kendo-grid>
<lib-grid-context-menu
	*ngIf="gridContextMenuItems?.length > 0"
	[for]="kendoGridParent"
	[menuItems]="gridContextMenuItems"
	(select)="onRightClickSelect($event)"
></lib-grid-context-menu>
