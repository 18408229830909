<div class="card card-tasks" id="card-whole">
	<div class="card-header">
		<h6 class="title d-inline" *ngIf="this.dataSource.data.AssetTypeId === undefined">{{ this.dataSource.title }}</h6>
		<h6 class="title d-inline" *ngIf="this.dataSource.data.AssetTypeId !== undefined">{{ this.dataSource.data.Name }}</h6>
		<button class="btn btn-link" style="padding: 0px; float: right; margin-right: 20px; margin-left: 20px" (click)="onCancel()">
			<i class="tim-icons icon-simple-remove"></i>
		</button>
		<button class="btn btn-fill btn-primary" type="submit" (click)="onSubmit(widgetForm)" style="float: right">Save and Close</button>
	</div>
	<div class="card-body">
		<form *ngIf="dataSource.title === 'Re-Name Template'" [formGroup]="widgetForm" class="form-horizontal" (ngSubmit)="onSubmit(widgetForm)">
			<div class="row">
				<label class="col-md-3 col-form-label">Template Name</label>
				<div class="col-md-9">
					<div class="form-group">
						<input *ngIf="dataSource.title === 'Re-Name Template'" class="form-control" type="text" formControlName="templateName" />
					</div>
				</div>
			</div>
		</form>
		<form *ngIf="dataSource.title === 'Re-Name Canvas Template'" [formGroup]="widgetForm" class="form-horizontal" (ngSubmit)="onSubmit(widgetForm)">
			<div class="row">
				<label class="col-md-3 col-form-label">Canvas Template Name</label>
				<div class="col-md-9">
					<div class="form-group">
						<input *ngIf="dataSource.title === 'Re-Name Canvas Template'" class="form-control" type="text" formControlName="canvasTemplateName" />
					</div>
				</div>
			</div>
		</form>
		<form *ngIf="dataSource.title === 'Re-Name Widget' || dataSource.title === 'Re-Name Asset'" [formGroup]="widgetForm" class="form-horizontal" (ngSubmit)="onSubmit(widgetForm)">
			<div class="row">
				<label class="col-md-3 col-form-label" *ngIf="dataSource.title === 'Re-Name Widget'"> Widget Name </label>
				<label class="col-md-3 col-form-label" *ngIf="dataSource.title === 'Re-Name Asset'">Asset Name</label>
				<div class="col-md-9">
					<div class="form-group">
						<input *ngIf="dataSource.title === 'Re-Name Widget' || dataSource.title === 'Re-Name Asset'" class="form-control" type="text" formControlName="widgetName" />
					</div>
				</div>
			</div>
		</form>
		<form *ngIf="dataSource.title === 'Change Asset Model'" [formGroup]="assetModelFormGroup" class="form-horizontal" (ngSubmit)="onSubmit(assetModelFormGroup)">
			<div class="row">
				<label class="col-md-3 col-form-label">Asset Model</label>
				<div class="col-md-9">
					<div class="form-group">
						<select *ngIf="dataSource.title === 'Change Asset Model'" class="form-control" id="assetModel" [title]="'Asset Model'" formControlName="assetModel">
							<option class="form-control" *ngFor="let assetModel of dataService.cache.assetModels" [value]="assetModel.Id">{{ assetModel.Model }}</option>
						</select>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
