import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JbtStandardObservationIdTemplateComponent } from './jbt-standard-observation-id-template.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GridModule } from '@progress/kendo-angular-grid';
import { KendoGridParentModule } from '../kendo-grid-parent/kendo-grid-parent.module';

@NgModule({
	declarations: [JbtStandardObservationIdTemplateComponent],
	imports: [
		CommonModule,
		DragDropModule,
		MatButtonModule,
		MatTooltipModule,
		MatSelectModule,
		MatCheckboxModule,
		FormsModule,
		ReactiveFormsModule,
		GridModule,
		MatButtonToggleModule,
		KendoGridParentModule,
	],
	exports: [JbtStandardObservationIdTemplateComponent],
})
export class JbtStandardObservationIdTemplateModule {}
