import { AfterViewInit, Component, Input, Optional } from "@angular/core";
import { SignalRCoreService } from "projects/shared-lib/src/lib/services/signalr-core.service";
import { filter, map, startWith, switchMapTo } from "rxjs/operators";
import _, { identity, noop } from "lodash";
import { ToastrService } from "ngx-toastr";
import * as moment from "moment";
import { DataService } from "../../services/data.service";
import { UtilityService } from "../../services/utility.service";
import { Global } from "../../_constants/global.variables";
import { Observable } from 'rxjs';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import { KeyValue } from '@angular/common';
import { trigger, transition, style, animate, query, stagger } from '@angular/animations';
import { FooterComponent } from "@progress/kendo-angular-grid";
import { enableDebugTools } from "@angular/platform-browser";


  const fadeSlide = trigger('fadeSlide', [
		transition(':enter', [
			  style({ opacity: 0, transform: 'translateX(250px)' }),
			  animate(1000, style({ opacity: 1, transform: 'translateX(0)' }))
		]),
		transition(':leave', [
			  animate(1000,style({ opacity: 0, transform: 'translateX(250px)' }))
		])
	]);

export interface ButtonSelection {
	order: any;
	enabled?: any;
	email?: any;
	textMessage?: any;
	allDays?: any;
	allHours?: any;
	days?: any;
	hours?: any;
	MinutesBetweenNotification?: any;
	MinutesToEscalation?: any;
}
// export interface ValidateSelection {
// 	oneAlert: any;
// 	emailOrText: any;
// 	days: any;
// 	hours: any;
// }
@Component({
	selector: "lib-alert-configuration",
	animations: [fadeSlide],
	templateUrl: "./alert-configuration.component.html",
	styleUrls: ["./alert-configuration.component.scss"]
})
export class AlertConfigurationComponent implements AfterViewInit {
	@Optional() @Input() widgetObject: any;
	@Input() public formGroup: FormGroup;

	private fullDataCacheSubscription: any;
	public person: any;
	public site: any;
	public gates: any;
	public gateSystem: any;
	public assets: any;
	public asset: any;
	public alarmTags: any = [];
	public alarmStandardObservationsForSite: any = [];
	public gseAssetTypes: any = [];
	public gseAssetModels: any = [];

	private selectedTags: any = [];
	private dataLoaded: boolean = false;
	private dbEmailTagUsers: any;
	private dbEmailTagUsersUnfiltered: any;
	public alternateEmailForwards = [];

	public personToAdd : any;
	public associatedPeople = [];
	public filteredAssociatedPeople$: Observable<any[]>;
	public inputPerson : FormControl;

	public alertSummaryCount = 0;
	public alertSummary = [];
	public alertSummaryFromOtherSites = [];
	public alertSummaryInfo: string;

	public oneAlertEnabled = "";
	public myFleet;
	private fleetAssets:any;


	public validation = {
		CriticalThing: {
			emailOrText: "",
			days: "",
			hours: "",
			description: ""
		},
		AlertThing: {
			emailOrText: "",
			days: "",
			hours: "",
			description: ""
		},
		Thing: {
			emailOrText: "",
			days: "",
			hours: "",
			description: ""
		},
		criticalSite: {
			emailOrText: "",
			days: "",
			hours: "",
			description: ""
		},
		site: {
			emailOrText: "",
			days: "",
			hours: "",
			description: ""
		},
		criticalGateSystem: {
			emailOrText: "",
			days: "",
			hours: "",
			description: ""
		},
		gateSystem: {
			emailOrText: "",
			days: "",
			hours: "",
			description: ""
		},

		criticalAsset: {
			emailOrText: "",
			days: "",
			hours: "",
			description: ""
		},
		alertAsset: {
			emailOrText: "",
			days: "",
			hours: "",
			description: ""
		},
		asset: {
			emailOrText: "",
			days: "",
			hours: "",
			description: ""
		},

		allPBBCriticalSite: {
			emailOrText: "",
			days: "",
			hours: "",
			description: ""
		},

		allPBBSite: {
			emailOrText: "",
			days: "",
			hours: "",
			description: ""
		},

		allPCACriticalSite: {
			emailOrText: "",
			days: "",
			hours: "",
			description: ""
		},

		allPCASite: {
			emailOrText: "",
			days: "",
			hours: "",
			description: ""
		},

		allGPUCriticalSite: {
			emailOrText: "",
			days: "",
			hours: "",
			description: ""
		},

		allGPUSite: {
			emailOrText: "",
			days: "",
			hours: "",
			description: ""
		},

		tag: {
			emailOrText: [],
			days: [],
			hours: [],
			description: "",
			tagDescription: []
		},

		JbtStandardObservation: {
			emailOrText: [],
			days: [],
			hours: [],
			description: "",
			tagDescription: []
		},

		allGSECriticalSite: {
			emailOrText: "",
			days: "",
			hours: "",
			description: ""
		},

		allGSESite: {
			emailOrText: "",
			days: "",
			hours: "",
			description: ""
		},

		CriticalGSEAssetType: {
			emailOrText: [],
			days: [],
			hours: [],
			description: "",
			tagDescription: []
		},

		GSEAssetType: {
			emailOrText: [],
			days: [],
			hours: [],
			description: "",
			tagDescription: []
		},

		CriticalGSEAssetModel: {
			emailOrText: [],
			days: [],
			hours: [],
			description: "",
			tagDescription: []
		},

		GSEAssetModel: {
			emailOrText: [],
			days: [],
			hours: [],
			description: "",
			tagDescription: []
		}

	};

	public localDisplaySettings = {
		emailButtonSize: 25,
		booleanSelectorButtonSize: 24,
		dayButtonWidth: 32,
		hourButtonWidth: 20
	};

	public dataCollections: any = {
		daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
		hoursOfDay00to11: _.range(0, 12).filter((digit) => {
			return digit < 12;
		}),
		hoursOfDay12to23: _.range(12, 24).filter((digit) => {
			return digit < 24;
		}),

	};

	// Order by ascending property value
	valueOrder = (a: KeyValue<string,ButtonSelection>, b: KeyValue<string,ButtonSelection>): number => {
		//return 0;
		return a.value.order > b.value.order ? 1 : (b.value.order > a.value.order ? -1 : 0);
	}

	public buttonSelections: { [name: string]: ButtonSelection } ;


	public sites: any;
	public showWidgetBody: boolean;
	private signalRAlerts$: any;

	constructor(private signalRCore: SignalRCoreService, private dataService: DataService, private utilityService: UtilityService, public toastr: ToastrService) {}

	public displayFn(person: any): string {
		return person && person.FullName
			? person.FullName
			: '';
	}

	InitSettingsButtonSelections() {
		if (this.widgetObject.WidgetSiteId) {
			this.buttonSelections  = {

				allPBBCriticalSite: {
					order: 2,
					enabled: [],
					email: [],
					textMessage: [],
					allDays: [],
					allHours: [],
					days: [],
					hours: [],
					MinutesBetweenNotification: [],
					MinutesToEscalation: []
				},

				criticalSite: {
					order: 1,
					enabled: [],
					email: [],
					textMessage: [],
					allDays: [],
					allHours: [],
					days: [],
					hours: [],
					MinutesBetweenNotification: [],
					MinutesToEscalation: []
				},

				site: {
					order: 9,
					enabled: [],
					email: [],
					textMessage: [],
					allDays: [],
					allHours: [],
					days: [],
					hours: [],
					MinutesBetweenNotification: [],
					MinutesToEscalation: []
				},


				criticalGateSystem: {
					order: 7,
					enabled: [],
					email: [],
					textMessage: [],
					allDays: [],
					allHours: [],
					days: [],
					hours: [],
					MinutesBetweenNotification: [],
					MinutesToEscalation: []
				},

				gateSystem: {
					order: 11,
					enabled: [],
					email: [],
					textMessage: [],
					allDays: [],
					allHours: [],
					days: [],
					hours: [],
					MinutesBetweenNotification: [],
					MinutesToEscalation: []
				},

				criticalAsset: {
					order: 8,
					enabled: [],
					email: [],
					textMessage: [],
					allDays: [],
					allHours: [],
					days: [],
					hours: [],
					MinutesBetweenNotification: [],
					MinutesToEscalation: []
				},
				
				asset: {
					order: 15,
					enabled: [],
					email: [],
					textMessage: [],
					allDays: [],
					allHours: [],
					days: [],
					hours: [],
					MinutesBetweenNotification: [],
					MinutesToEscalation: []
				},

				allPBBSite: {
					order: 10,
					enabled: [],
					email: [],
					textMessage: [],
					allDays: [],
					allHours: [],
					days: [],
					hours: [],
					MinutesBetweenNotification: [],
					MinutesToEscalation: []
				},

				allPCACriticalSite: {
					order: 3,
					enabled: [],
					email: [],
					textMessage: [],
					allDays: [],
					allHours: [],
					days: [],
					hours: [],
					MinutesBetweenNotification: [],
					MinutesToEscalation: []
				},

				allPCASite: {
					order: 11,
					enabled: [],
					email: [],
					textMessage: [],
					allDays: [],
					allHours: [],
					days: [],
					hours: [],
					MinutesBetweenNotification: [],
					MinutesToEscalation: []
				},

				allGPUCriticalSite: {
					order: 4,
					enabled: [],
					email: [],
					textMessage: [],
					allDays: [],
					allHours: [],
					days: [],
					hours: [],
					MinutesBetweenNotification: [],
					MinutesToEscalation: []
				},

				allGPUSite: {
					order: 12,
					enabled: [],
					email: [],
					textMessage: [],
					allDays: [],
					allHours: [],
					days: [],
					hours: [],
					MinutesBetweenNotification: [],
					MinutesToEscalation: []
				},

				tag: {
					order: 16,
					enabled: [],
					email: [],
					textMessage: [],
					allDays: [],
					allHours: [],
					days: [],
					hours: [],
					MinutesBetweenNotification: [],
					MinutesToEscalation: [],
				},

				JbtStandardObservation: {
					order: 17,
					enabled: [],
					email: [],
					textMessage: [],
					allDays: [],
					allHours: [],
					days: [],
					hours: [],
					MinutesBetweenNotification: [],
					MinutesToEscalation: [],
				},

				allGSECriticalSite: {
					order: 5,
					enabled: [],
					email: [],
					textMessage: [],
					allDays: [],
					allHours: [],
					days: [],
					hours: [],
					MinutesBetweenNotification: [],
					MinutesToEscalation: []
				},

				allGSESite: {
					order: 13,
					enabled: [],
					email: [],
					textMessage: [],
					allDays: [],
					allHours: [],
					days: [],
					hours: [],
					MinutesBetweenNotification: [],
					MinutesToEscalation: []
				},

				CriticalGSEAssetType: {
					order: 6,
					enabled: [],
					email: [],
					textMessage: [],
					allDays: [],
					allHours: [],
					days: [],
					hours: [],
					MinutesBetweenNotification: [],
					MinutesToEscalation: []
				},

				GSEAssetType: {
					order: 14,
					enabled: [],
					email: [],
					textMessage: [],
					allDays: [],
					allHours: [],
					days: [],
					hours: [],
					MinutesBetweenNotification: [],
					MinutesToEscalation: []
				},

				CriticalGSEAssetModel: {
					order: 6,
					enabled: [],
					email: [],
					textMessage: [],
					allDays: [],
					allHours: [],
					days: [],
					hours: [],
					MinutesBetweenNotification: [],
					MinutesToEscalation: []
				},

				GSEAssetModel: {
					order: 14,
					enabled: [],
					email: [],
					textMessage: [],
					allDays: [],
					allHours: [],
					days: [],
					hours: [],
					MinutesBetweenNotification: [],
					MinutesToEscalation: []
				}

			};
		}
		else if (this.widgetObject.VocationalSettingsJSON) {
			this.buttonSelections  = {

				CriticalThing: {
					order: 1,
					enabled: [],
					email: [],
					textMessage: [],
					allDays: [],
					allHours: [],
					days: [],
					hours: [],
					MinutesBetweenNotification: [],
					MinutesToEscalation: []
				},
				AlertThing: {
					order: 10,
					enabled: [],
					email: [],
					textMessage: [],
					allDays: [],
					allHours: [],
					days: [],
					hours: [],
					MinutesBetweenNotification: [],
					MinutesToEscalation: []
				},
				Thing: {
					order: 5,
					enabled: [],
					email: [],
					textMessage: [],
					allDays: [],
					allHours: [],
					days: [],
					hours: [],
					MinutesBetweenNotification: [],
					MinutesToEscalation: []
				},

				criticalAsset: {
					order: 2,
					enabled: [],
					email: [],
					textMessage: [],
					allDays: [],
					allHours: [],
					days: [],
					hours: [],
					MinutesBetweenNotification: [],
					MinutesToEscalation: []
				},
				alertAsset: {
					order: 11,
					enabled: [],
					email: [],
					textMessage: [],
					allDays: [],
					allHours: [],
					days: [],
					hours: [],
					MinutesBetweenNotification: [],
					MinutesToEscalation: []
				},
				asset: {
					order: 6,
					enabled: [],
					email: [],
					textMessage: [],
					allDays: [],
					allHours: [],
					days: [],
					hours: [],
					MinutesBetweenNotification: [],
					MinutesToEscalation: []
				},




				tag: {
					order: 16,
					enabled: [],
					email: [],
					textMessage: [],
					allDays: [],
					allHours: [],
					days: [],
					hours: [],
					MinutesBetweenNotification: [],
					MinutesToEscalation: [],
				},

				JbtStandardObservation: {
					order: 17,
					enabled: [],
					email: [],
					textMessage: [],
					allDays: [],
					allHours: [],
					days: [],
					hours: [],
					MinutesBetweenNotification: [],
					MinutesToEscalation: [],
				},





			};
		}
	}

	InitSettingsCategories() {
		if (this.widgetObject.WidgetSiteId) {
			this.dataCollections.category = ['criticalSite', 'allPBBCriticalSite', 'allPCACriticalSite', 'allGPUCriticalSite', 'criticalGateSystem', 'criticalAsset',
					'site', 'allPBBSite', 'allPCASite',  'allGPUSite', 'gateSystem',  'asset', 
					'tag', 'JbtStandardObservation', 'allGSECriticalSite', 'allGSESite', 'CriticalGSEAssetType', 'GSEAssetType', 'CriticalGSEAssetModel', 'GSEAssetModel'];

		}
		else if (this.widgetObject.VocationalSettingsJSON) {
			this.dataCollections.category = ['criticalAsset', 'asset', 'alertAsset', 'CriticalThing', 'Thing', 'AlertThing', 'tag', 'JbtStandardObservation', ];
		}
	}

	ngAfterViewInit() {

		this.InitSettingsCategories();
		this.InitSettingsButtonSelections();

		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription = this.dataService.fullDataCacheExists$.subscribe((data: any) => {
				if (data === true) {
					this.initialize();
					this.fullDataCacheSubscription.unsubscribe();
				}
			});
		} else {
			this.initialize();
		}

		var taguseralertgroup = "TUA-" + this.dataService.currentUserJSONObject.Email;

		this.signalRCore.joinAdditionalGroup(taguseralertgroup);
		this.signalRAlerts$ = this.signalRCore.broadcastMessages$.pipe(filter((msg: any) => msg.code == this.dataService.currentUserJSONObject.Email)).subscribe((signalRString: any) => {
			// process alert
			console.log("Alert ", signalRString?.object);
			var splitSignalRString: any = signalRString?.object?.split("~");
			var rawMessage = splitSignalRString ? splitSignalRString[1] : "";
			var message: any = rawMessage.split("!");

			var options = {
				closeButton: true,
				debug: false,
				newestOnTop: false,
				progressBar: false,
				positionClass: "toast-top-right",
				preventDuplicates: false,
				onclick: null,
				showDuration: "300",
				hideDuration: "1000",
				timeOut: 0,
				extendedTimeOut: 0,
				showEasing: "swing",
				hideEasing: "linear",
				showMethod: "fadeIn",
				hideMethod: "fadeOut",
				tapToDismiss: false
			};

			//this.toastr.info(message[1], "Alert", options);

		});


		this.signalRAlerts$ = this.signalRCore.broadcastMessages$.pipe(filter((msg: any) => msg.code == "personal-alert-escalation"))
			.subscribe((signalRString: any) => {
				// process message
				console.log("personal-alert-escalation change ", signalRString?.object);

				this.GetPersonEmailForwards();

		});

		this.inputPerson = new FormControl('');

		this.filteredAssociatedPeople$ = this.inputPerson.valueChanges.pipe(
			startWith(''),
			map(value => {
				const name = typeof value === 'string' ? value : value?.name;
				return name ? this._filter(name as string) : this.associatedPeople.slice();
			}),
		);

		// configure settings summary legend
		let arr = [
			"Settings Summary Information",
			"Text color:",
			"Green text: Alert configured to send",
			"White text: Enabled; not all settings are set",
			"Circle icon color:",
			"Black circle: Alert",
			"Red circle: Critical Alert",
			"Circle icon label:",
			"GPU,PBB,PCA: Asset type for alert",
			"A: Alert is for a specific asset",
			"Tag: Alert is for a specific tag",
			"GSE: GSE Asset type for alert"
		  ];
		this.alertSummaryInfo = arr.join("\n");


		this.signalRAlerts$ = this.signalRCore.broadcastMessages$.pipe(filter((msg: any) => msg.code == "email-tag-users"))
			.subscribe((signalRString: any) => {
				// process message
				console.log("email-tag-users change ", signalRString?.object);

				// reload when alerts have changed
				this.initialize();

		});

	}

	ngOnDestroy() {
		//Global.User.DebugMode && console.log('ngOnDestroy invoked...');


	}

	private _filter(name: string): any[] {
		const filterValue = name.toLowerCase();

		return this.associatedPeople.filter(option => option.FullName.toLowerCase().includes(filterValue));
	}

	// public clickAddEscalationPerson()
	// {
	// 	let value = this.inputPerson.value;
	// 	let newPersonToAddId = value.Id;

	// 	if(newPersonToAddId)
	// 	{
	// 		let statement = 'API.AlertManagementInsertPersonEmailForward ' + this.person.Id + ', ' + newPersonToAddId;
	// 		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
	// 			// add to the list

	// 			if (data.length > 0) {
	// 				//console.log(this.dataService.cache.people);
	// 				data.forEach((item) => {
	// 					let person = this.dataService.cache.people.find((person) => {
	// 						return person.Id == item.ForwardToPersonId;
	// 					});
	// 					if (person !== undefined) {
	// 						this.alternateEmailForwards.push({
	// 							text: person.FullName,
	// 							person: person,
	// 							Id: item.Id
	// 						});
	// 					}
	// 				});
	// 				console.log('added escalation:', this.alternateEmailForwards);
	// 			}
	// 		});
	// 	}
	// }

	// public clickRemoveEscalationPerson(personEmailForward: any)
	// {
	// 	console.log('person forward to remove:', personEmailForward.Id);

	// 	let statement = 'API.AlertManagementDeletePersonEmailForward ' + personEmailForward.Id;
	// 	this.dataService.SQLActionAsPromise(statement).then((data: any) => {
	// 		// remove from the list
	// 		let index = this.alternateEmailForwards.findIndex(p => {return p.Id == personEmailForward.Id});
	// 		this.alternateEmailForwards.splice(index, 1);
	// 	});

	// }

	initialize(): void {
		this.widgetObject.WidgetSearchEnabled = true;

		this.widgetObject.displaySettings = {
			headingBackground: "linear-gradient(to bottom,#dcdcdc, #f0f0f0)",
			tagDataSortField: "TagName",
			headingExtraTitle: this.GetHeadingExtraTitle(),
			obscureGraphics: true,
			headingSearchField: true,
			headingSearchFieldDebounce: 50,
			emailAlternatePerson: true,
			emailAlternatePersonValue: "",
			SearchPlaceholder: "Search Individual Tags"
		};
		//Check to see if there is an alternate alarm email person set for the user.
		//Create API Method Here
		this.dataService.SQLActionAsPromise("API.GetPersonRecordByPersonId " + Global.User.currentUser.PersonId).then((person: any) => {
			this.person = person[0];
			// if (this.person.AlternateNotificationEmailPersonId) {
			// 	this.dataService.SQLActionAsPromise("API.GetPersonRecordByPersonId " + this.person.AlternateNotificationEmailPersonId).then((altPerson: any) => {
			// 		this.widgetObject.alternateAlarmNotificationPersonId = altPerson[0].Id;
			// 		this.widgetObject.alternateAlarmNotificationPerson = altPerson[0];

			// 		this.widgetObject.alternateAlarmNotificationPersonOrganization = this.dataService.cache.organizations.find((org) => {
			// 			return org.Id == altPerson[0].OrganizationId;
			// 		});

			// 		this.LoadData();
			// 		console.log(this.buttonSelections);
			// 	});
			// } else {
			// 	this.LoadData();
			// 	console.log(this.buttonSelections);
			// }

			// if (this.person.ReportsToPersonId) {
			// 	this.dataService.SQLActionAsPromise("API.GetPersonRecordByPersonId " + this.person.ReportsToPersonId).then((reportsToPerson: any) => {
			// 		this.widgetObject.reportsToPersonId = reportsToPerson[0].Id;
			// 		this.widgetObject.reportsToPerson = reportsToPerson[0];

			// 		this.widgetObject.reportsToPersonOrganization = this.dataService.cache.organizations.find((org) => {
			// 			return org.Id == reportsToPerson[0].OrganizationId;
			// 		});

			// 		this.LoadData();
			// 		console.log(this.buttonSelections);
			// 	});
			// }

			this.LoadData();
			console.log('button selections:', this.buttonSelections);


			// Get list of people associated with this person
			this.associatedPeople = this.dataService.cache.people.filter((p: any) => {
				return p.OrganizationId == this.person.OrganizationId && p.FullName
			});

			this.GetPersonEmailForwards();
		});


	}

	GetPersonEmailForwards()
	{
		this.dataService.SQLActionAsPromise("API.PersonEmailForwardsForAlertManagement " + Global.User.currentUser.PersonId).then((data: any) => {
			this.alternateEmailForwards = [];
			if (data.length > 0) {
				//console.log(this.dataService.cache.people);
				data.forEach((item) => {
					let person = this.dataService.cache.people.find((person) => {
						return person.Id == item.ForwardToPersonId;
					});
					if (person !== undefined) {
						this.alternateEmailForwards.push({
							text: person.FullNameWithUsername,
							person: person,
							Id: item.Id
						});
					}
				});
				console.log('escalations for currentuser:', this.alternateEmailForwards);
			}
		});

	}

	LoadData(): void {
		this.showWidgetBody = true;
		console.log('widgetobject:', this.widgetObject);
		if (this.widgetObject.WidgetSiteId) {
			this.site = this.dataService.cache.sites.find((site) => {
				return site.Id == this.widgetObject.WidgetSiteId;
			});
		} else if (this.widgetObject.VocationalSettingsJSON) {
			var VocationalSettings = JSON.parse(this.widgetObject.VocationalSettingsJSON);
			Global.User.DebugMode && console.log("VocationalSettings ", VocationalSettings);
			let fleetId = VocationalSettings.id;

			if(VocationalSettings.child.id) {
				this.widgetObject.WidgetAssetId = VocationalSettings.child.id;
				this.widgetObject.WidgetAssetName = this.dataService.cache.assetsObject[this.widgetObject.WidgetAssetId]?.Name;
			}

			this.dataService.GetFleets().subscribe((data: any) => {
				if(data.length > 0) {
					this.myFleet = data.find((fleet) => {
						return ( fleet.FleetId === fleetId );
					});
					this.widgetObject.FleetName = this.myFleet?.FleetName;

					this.dataService.GetFleetAssets(this.myFleet.FleetId).subscribe((assets: any) => {
						this.fleetAssets = assets;

						this.GetStandardObservations();

					});
				}
			});
		}
		else {
			return;
		}

		//this.GetGatesListForSideBar();
		this.GetAlarmTagsForAsset();
		// Global.User.currentUser.Id
		this.dataService
			.SQLActionAsPromise(
				'API.RetrieveEmailTagUsersRecordsByUserId ' +
					Global.User.currentUser.Id
			)
			.then((records: any) => {
				console.log('email tag user records:', records);
				this.dbEmailTagUsersUnfiltered = records;

				if (this.dbEmailTagUsersUnfiltered.length > 0) {

					//get all the tags from the tagids
					// var currentSelectedTagIds;
					// this.dbEmailTagUsers.forEach(alert =>
					// 	{
					// 		if(alert.tagId) {
					// 			currentSelectedTagIds.push(alert.tagId);
					// 		}
					// 	});
					// var currentSelectedTagIdsString = currentSelectedTagIds.join(',');
					// this.dataService
					// 	.SQLActionAsPromise(
					// 		"API.GetTagsByListOfIds '" + currentSelectedTagIdsString + "'"
					// 	)
					// 	.then((data: any) => {
					// 		console.log('data:', data);
					// 		this.selectedTags = data;


							this.dbEmailTagUsers = this.dbEmailTagUsersUnfiltered.filter((etu) => {
								var buttonSelectionsMade = false;

								this.SetButtonSelectionsForCategory('criticalSite', etu ) == true ? buttonSelectionsMade = true : noop;
								this.SetButtonSelectionsForCategory('site', etu) == true ? buttonSelectionsMade = true : noop;

								this.SetButtonSelectionsForCategory("criticalGateSystem", etu) == true ? buttonSelectionsMade = true : noop;
								this.SetButtonSelectionsForCategory("gateSystem", etu) == true ? buttonSelectionsMade = true : noop;

								this.SetButtonSelectionsForCategory("criticalAsset", etu) == true ? buttonSelectionsMade = true : noop;
								this.SetButtonSelectionsForCategory("alertAsset", etu) == true ? buttonSelectionsMade = true : noop;
								this.SetButtonSelectionsForCategory("asset", etu) == true ? buttonSelectionsMade = true : noop;

								this.SetButtonSelectionsForCategory("allPBBCriticalSite", etu) == true ? buttonSelectionsMade = true : noop;
								this.SetButtonSelectionsForCategory("allPBBSite", etu) == true ? buttonSelectionsMade = true : noop;

								this.SetButtonSelectionsForCategory("allPCACriticalSite", etu) == true ? buttonSelectionsMade = true : noop;
								this.SetButtonSelectionsForCategory("allPCASite", etu) == true ? buttonSelectionsMade = true : noop;

								this.SetButtonSelectionsForCategory("allGPUCriticalSite", etu) == true ? buttonSelectionsMade = true : noop;
								this.SetButtonSelectionsForCategory("allGPUSite", etu) == true ? buttonSelectionsMade = true : noop;

								this.SetButtonSelectionsForCategory("allGSECriticalSite", etu) == true ? buttonSelectionsMade = true : noop;
								this.SetButtonSelectionsForCategory("allGSESite", etu) == true ? buttonSelectionsMade = true : noop;

								this.SetButtonSelectionsForCategory("CriticalThing", etu) == true ? buttonSelectionsMade = true : noop;
								this.SetButtonSelectionsForCategory("AlertThing", etu) == true ? buttonSelectionsMade = true : noop;
								this.SetButtonSelectionsForCategory("Thing", etu) == true ? buttonSelectionsMade = true : noop;


								this.SetButtonSelectionsForCategory("tag", etu) == true ? buttonSelectionsMade = true : noop;

								if( (etu.JbtStandardObservationSiteId != null && etu.JbtStandardObservationSiteId == this.widgetObject.WidgetSiteId) ||
									(etu.GroupThingId != null && etu.GroupThingId == this.myFleet?.FleetId) )
								{
									this.SetButtonSelectionsForCategory('JbtStandardObservation', etu) == true ? buttonSelectionsMade = true : noop;

									this.SetButtonSelectionsForCategory('CriticalGSEAssetType', etu) == true ? buttonSelectionsMade = true : noop;
									this.SetButtonSelectionsForCategory('GSEAssetType', etu) == true ? buttonSelectionsMade = true : noop;

									this.SetButtonSelectionsForCategory('CriticalGSEAssetModel', etu) == true ? buttonSelectionsMade = true : noop;
									this.SetButtonSelectionsForCategory('GSEAssetModel', etu) == true ? buttonSelectionsMade = true : noop;

								}
								else if(etu.JbtStandardObservationSiteId != undefined || etu.GroupThingId  != undefined)
								{
									// for other Sites.. add this StdObs to the selection summary

									this.GenerateSelectionsSummaryForOtherSites('Alert', 'Tag', 'Alerts for StdObs', 'JbtStandardObservation', etu);

									this.GenerateSelectionsSummaryForOtherSites('Critical', 'GSE', 'Critical Alerts for GSE Asset Type', 'CriticalGSEAssetType', etu);
									this.GenerateSelectionsSummaryForOtherSites('Alert', 'GSE', 'Alerts for GSE Asset Type', 'GSEAssetType', etu);

									this.GenerateSelectionsSummaryForOtherSites('Critical', 'GSE', 'Critical Alerts for GSE Asset Model', 'CriticalGSEAssetModel', etu);
									this.GenerateSelectionsSummaryForOtherSites('Alert', 'GSE', 'Alerts for GSE Asset Model', 'GSEAssetModel', etu);

								}

								return buttonSelectionsMade ? etu : null;
							});

							// filter out the Std Obs from other sites
							this.dbEmailTagUsers = this.dbEmailTagUsers.filter(etu => etu.JbtStandardObservationSiteId == this.widgetObject.WidgetSiteId
																						|| etu.GroupThingId == this.myFleet?.FleetId
																						|| (etu.JbtStandardObservationSiteId == null && etu.GroupThingId == null));

							console.log("buttonSelections:", this.buttonSelections);
							console.log("dbEmailTagUsers:", this.dbEmailTagUsers);
							this.ValidateAtLeastOneCategoryEnabled();

							this.ValidateAllSelections();
							this.GenerateSelectionsSummary();
				}
			});

		this.sites =
			Global.User.currentUser.Security.Aggregate.Collections.SiteIds.map(
				(siteId) => {
					var site = this.dataService.cache.sites.find((site) => {
						return site.Id == siteId;
					});
					if(site) {
						return {
							Id: site.Id,
							Name: site.Name,
						};
					}
				}
			);
		console.log('sites', this.sites);


		// GSE
		this.GetGSEAssetTypes();
	}

	//GenerateSelectionsSummaryForOtherSites - builds the list of all the selections for the user.
	// 		** Used for sites that are not the currently selected one in the widget
	//
	// 		alertTypeText - Alert or Critical - where Critical gets red color
	//		categoryText - used in display in circle
	//		summaryPrefixText - the first part of the text used in the summary
	//		category - the button selection category
	//		etu - the email tag user alert object

	GenerateSelectionsSummaryForOtherSites(alertTypeText, categoryText, summaryPrefixText, category, dbEntity)
	{

		var categoryFieldNamePrefix = category.substring(0, 1).toUpperCase() + category.substring(1);

		var categoryFieldId = dbEntity[categoryFieldNamePrefix + "Id"];

		if (categoryFieldId) {

			// for other Sites.. add this StdObs to the selection summary
			var site = this.dataService.cache.sites.find((site) => {
				return site.Id == dbEntity.JbtStandardObservationSiteId;
			});

			var obj;
			var objectName;
			if(category == 'JbtStandardObservation') {
				obj = this.dataService.cache.jbtStandardObservations.find((so) => {
					return so.Id == dbEntity.JbtStandardObservationId;
				});
				objectName = obj.Name;
			} else if(category == 'CriticalGSEAssetType') {
				obj = this.gseAssetTypes.find((o) => {
					return o.Id == dbEntity.CriticalGSEAssetTypeId;
				});
				objectName = obj.Name;

			} else if(category == 'CriticalGSEAssetModel') {
				obj = this.gseAssetModels.find((o) => {
					return o.Id == dbEntity.CriticalGSEAssetModelId;
				});
				objectName = obj.SelectorName;

			} else if(category == 'GSEAssetType') {
				obj = this.gseAssetTypes.find((o) => {
					return o.Id == dbEntity.GSEAssetTypeId;
				});
				objectName = obj.Name;

			} else if(category == 'GSEAssetModel') {
				obj = this.gseAssetModels.find((o) => {
					return o.Id == dbEntity.GSEAssetModelId;
				});
				objectName = obj.SelectorName;

			}


			var summary = {
				alertType: alertTypeText,
				category: categoryText,
				text: summaryPrefixText + ' ' + site?.Name + ' ' + objectName,			// TODO need to display the fleet name or the site name
				notificationActive: this.IsConfiguredToSendOutNotifications(dbEntity)
			};

			this.alertSummaryFromOtherSites.push(summary);
		}
	}


	//GenerateSelectionsSummary - builds the list of all the selections for the user
	// alertSummaryCount - the number of items - displayed in the header
	// summary
	// 		alertType - Alert or Critical - where Critical gets red color
	//		category - used in display in circle
	//		text - the text used in the summary

	GenerateSelectionsSummary()
	{
		this.alertSummary = [];
		var alertSummaryTemp = [];

		this.dbEmailTagUsersUnfiltered.forEach(alert =>
			{
				this.dataCollections.category.forEach(category => {
					var capitalizedCategory = category.substring(0, 1).toUpperCase() + category.substring(1);

					var alertCategoryId = alert[capitalizedCategory + 'Id'];
					var summary = {
						alertType: "",
						category: "",
						text: "",
						fleetId: null,
						notificationActive: "warning"
					};
					if(alertCategoryId != undefined) {
						console.log('alertCat', alertCategoryId);
						if (capitalizedCategory == "GateSystem") {
							var gate = this.dataService.cache.systems.find((gate) => {
								return gate.Id == alertCategoryId;
							});
							if(gate != undefined) {
								summary.alertType = 'Alert';
								summary.category = 'GS';
								summary.text = 'Alerts for Site ' + gate.Site.Name + ' Gate ' + gate.Name;
							}
							else {
								console.log("Warning: GateSystem does not exist ", alertCategoryId);
							}

						} else if (capitalizedCategory == "CriticalGateSystem") {
							var gate = this.dataService.cache.systems.find((gate) => {
								return gate.Id == alertCategoryId;
							});
							if(gate != undefined) {
								summary.alertType = 'Critical';
								summary.category = 'GS';
								summary.text = 'Critical alerts for Site ' + gate.Site.Name + ' Gate ' + gate.Name;
							}
							else {
								console.log("Warning: CriticalGateSystem does not exist ", alertCategoryId);
							}

						} else if (capitalizedCategory == "Asset") {
							var asset = this.dataService.cache.assets.find((asset) => {
								return asset.Id == alertCategoryId;
							});
							if(asset != undefined) {
								
								summary.category = 'A';
								let fleetAsset = this.fleetAssets?.find((a) => a.Id == alertCategoryId);
								if(fleetAsset == null) {
									summary.alertType = 'Alert';

									summary.text = 'Alerts for Asset ' + asset.Site.Name + '-' + asset.ParentSystem.Name + '-' + asset.Name;
								}
								else {
									summary.alertType = 'Amber';

									summary.text = 'Amber Alerts for Asset ' + fleetAsset.FleetName + '-' + asset.Name;
								}
							}
							else {
								console.log("Warning: Asset does not exist ", alertCategoryId);
							}

						} else if (capitalizedCategory == "CriticalAsset") {
							var asset = this.dataService.cache.assets.find((asset) => {
								return asset.Id == alertCategoryId;
							});
							if(asset != undefined) {
								
								summary.category = 'A';
								summary.alertType = 'Critical';
								let fleetAsset = this.fleetAssets?.find((a) => a.Id == alertCategoryId);
								if(fleetAsset == null) {
									
									summary.text = 'Critical alerts for Asset ' + asset.Site.Name + '-' + asset.ParentSystem.Name + '-' + asset.Name;
								}
								else {
									
									summary.text = 'Red alerts for Asset ' + fleetAsset.FleetName + '-' + asset.Name;
								}
							}
							else {
								console.log("Warning: CriticalAsset does not exist ", alertCategoryId);
							}
						} else if (capitalizedCategory == "AlertAsset") {
							var asset = this.dataService.cache.assets.find((asset) => {
								return asset.Id == alertCategoryId;
							});
							if(asset != undefined) {
								summary.alertType = 'Alert';
								summary.category = 'A';
								let fleetAsset = this.fleetAssets?.find((a) => a.Id == alertCategoryId);
								if(fleetAsset == null) {
									summary.text = 'Alerts for Asset ' + asset.Site.Name + '-' + asset.ParentSystem.Name + '-' + asset.Name;
								}
								else {
									summary.text = 'Alerts for Asset ' + fleetAsset.FleetName + '-' + asset.Name;
								}
							}
							else {
								console.log("Warning: AlertAsset does not exist ", alertCategoryId);
							}

						} else if (capitalizedCategory == "Tag") {

							summary.alertType = 'Alert';
							summary.category = 'Tag';

							let fleetAsset = this.fleetAssets?.find((a) => a.Id == alert.TagAssetId);
							if(fleetAsset == null) {
								summary.text = 'Alerts for Tag ' + alert.TagSiteName + '-' + alert.TagGateName + '-' + alert.TagAssetName + '-' + alert.TagStandardName;
							}
							else {
								summary.text = 'Alerts for Tag ' + fleetAsset.FleetName + '-' + alert.TagAssetName + '-' + alert.TagStandardName;
							}

						} else if (capitalizedCategory == "JbtStandardObservation" && alert['JbtStandardObservationSiteId'] != null) {
							var so = this.dataService.cache.jbtStandardObservations.find((so) => {
								return so.Id == alertCategoryId;
							});
							var site = this.dataService.cache.sites.find((site) => {
								return site.Id == alert['JbtStandardObservationSiteId'];
							});
							if(so != undefined && site != undefined) {
								summary.alertType = 'Alert';
								summary.category = 'Tag';
								summary.text = 'Alerts for StdObs ' + site.Name + '-' + so.Name;
							}
							else {
								console.log("Warning: stdobs or site does not exist. stdobs= ", alertCategoryId +  "site= %O", alert['JbtStandardObservationSiteId']);
							}

						} else if (capitalizedCategory == "JbtStandardObservation" && alert['GroupThingId'] != null) {
							var so = this.dataService.cache.jbtStandardObservations.find((so) => {
								return so.Id == alertCategoryId;
							});

							// get the fleet
							this.dataService.GetFleets().subscribe((data) => {
								if(data.length > 0) {
									let fleet = data.find((fleet) => {
										return ( fleet.FleetId == alert['GroupThingId'] );
									});

									if(so != undefined && fleet != undefined) {
										summary.alertType = 'Alert';
										summary.category = 'Tag';
										summary.text = 'Alerts for StdObs ' + fleet.FleetName + '-' + so.Name;

										summary.fleetId = fleet.FleetId;

									}
									else {
										console.log("Warning: stdobs or site does not exist. stdobs= ", alertCategoryId +  "flet= %O", alert['GroupThingId']);
									}
								}
							});

						} else if (capitalizedCategory == "CriticalSite") {
							var site = this.dataService.cache.sites.find((site) => {
								return site.Id == alertCategoryId;
							});
							if(site != undefined) {
								summary.alertType = 'Critical';
								summary.category = 'S';
								summary.text = 'Critical Alerts for Site ' + site.Name
							}
							else {
								console.log("Warning: CriticalSite does not exist ", alertCategoryId);
							}

						} else if (capitalizedCategory == "Site") {
							var site = this.dataService.cache.sites.find((site) => {
								return site.Id == alertCategoryId;
							});
							if(site != undefined) {
								summary.alertType = 'Alert';
								summary.category = 'S';
								summary.text = 'Alerts for Site ' + site.Name
							}
							else {
								console.log("Warning: Site does not exist ", alertCategoryId);
							}

						} else if (capitalizedCategory == "CriticalThing") {
							this.dataService.GetFleets().subscribe((data) => {
								if(data.length > 0) {
									let fleet = data.find((fleet) => {
										return ( fleet.FleetId === parseInt(alertCategoryId) );
									});

									if(fleet != undefined) {
										summary.alertType = 'Critical';
										summary.category = 'F';
										summary.text = 'Red Alerts for Fleet ' + fleet.FleetName
									}
									else {
										console.log("Warning: CriticalFleet does not exist ", alertCategoryId);
									}
								}

							});

						} else if (capitalizedCategory == "AlertThing") {
							this.dataService.GetFleets().subscribe((data) => {
								if(data.length > 0) {
									let fleet = data.find((fleet) => {
										return ( fleet.FleetId === parseInt(alertCategoryId) );
									});

									if(fleet != undefined) {
										summary.alertType = 'Alert';
										summary.category = 'F';
										summary.text = 'Alerts for Fleet ' + fleet.FleetName
									}
									else {
										console.log("Warning: AlertFleet does not exist ", alertCategoryId);
									}
								}

							});


						} else if (capitalizedCategory == "Thing") {
							this.dataService.GetFleets().subscribe((data) => {
								if(data.length > 0) {
									let fleet =  data.find((fleet) => {
										return ( fleet.FleetId === parseInt(alertCategoryId) );
									});

									if(fleet != undefined) {
										summary.alertType = 'Amber';
										summary.category = 'F';
										summary.text = 'Amber Faults for Fleet ' + fleet.FleetName
									}
									else {
										console.log("Warning: fleet does not exist ", alertCategoryId);
									}

								}

							});

						} else if (capitalizedCategory == "AllPBBCriticalSite") {
							var site = this.dataService.cache.sites.find((site) => {
								return site.Id == alertCategoryId;
							});
							if(site != undefined) {
								summary.alertType = 'Critical';
								summary.category = 'PBB';
								summary.text = 'Critical Alerts for PBBs at Site ' + site.Name
							}
							else {
								console.log("Warning: AllPBBCriticalSite does not exist ", alertCategoryId);
							}

						} else if (capitalizedCategory == "AllPBBSite") {
							var site = this.dataService.cache.sites.find((site) => {
								return site.Id == alertCategoryId;
							});
							if(site != undefined) {
								summary.alertType = 'Alert';
								summary.category = 'PBB';
								summary.text = 'Alerts for PBBs at Site ' + site.Name
							}
							else {
								console.log("Warning: AllPBBSite does not exist ", alertCategoryId);
							}

						} else if (capitalizedCategory == "AllPCACriticalSite") {
							var site = this.dataService.cache.sites.find((site) => {
								return site.Id == alertCategoryId;
							});
							if(site != undefined) {
								summary.alertType = 'Critical';
								summary.category = 'PCA';
								summary.text = 'Critical Alerts for PCAs at Site ' + site.Name
							}
							else {
								console.log("Warning: AllPCACriticalSite does not exist ", alertCategoryId);
							}

						} else if (capitalizedCategory == "AllPCASite") {
							var site = this.dataService.cache.sites.find((site) => {
								return site.Id == alertCategoryId;
							});
							if(site != undefined) {
								summary.alertType = 'Alert';
								summary.category = 'PCA';
								summary.text = 'Alerts for PCAs at Site ' + site.Name
							}
							else {
								console.log("Warning: AllPCASite does not exist ", alertCategoryId);
							}

						} else if (capitalizedCategory == "AllGPUCriticalSite") {
							var site = this.dataService.cache.sites.find((site) => {
								return site.Id == alertCategoryId;
							});
							if(site != undefined) {
								summary.alertType = 'Critical';
								summary.category = 'GPU';
								summary.text = 'Critical Alerts for GPUs at Site ' + site.Name
							}
							else {
								console.log("Warning: AllPCASite does not exist ", alertCategoryId);
							}

						} else if (capitalizedCategory == "AllGPUSite") {
							var site = this.dataService.cache.sites.find((site) => {
								return site.Id == alertCategoryId;
							});
							if(site != undefined) {
								summary.alertType = 'Alert';
								summary.category = 'GPU';
								summary.text = 'Alerts for GPUs at Site ' + site.Name
							}
							else {
								console.log("Warning: AllGPUSite does not exist ", alertCategoryId);
							}

						} else if (capitalizedCategory == "AllGSECriticalSite") {
							var site = this.dataService.cache.sites.find((site) => {
								return site.Id == alertCategoryId;
							});
							if(site != undefined) {
								summary.alertType = 'Critical';
								summary.category = 'GSE';
								summary.text = 'Critical Alerts for GSEs at Site ' + site.Name
							}
							else {
								console.log("Warning: AllGSECriticalSite does not exist ", alertCategoryId);
							}


						} else if (capitalizedCategory == "AllGSESite") {
							var site = this.dataService.cache.sites.find((site) => {
								return site.Id == alertCategoryId;
							});
							if(site != undefined) {
								summary.alertType = 'Alert';
								summary.category = 'GSE';
								summary.text = 'Alerts for GSEs at Site ' + site.Name
							}
							else {
								console.log("Warning: AllGSESite does not exist ", alertCategoryId);
							}

						} else if (capitalizedCategory == "CriticalGSEAssetType") {
							var type = this.gseAssetTypes.find((type) => {
								return type.Id == alertCategoryId;
							});
							var site = this.dataService.cache.sites.find((site) => {
								return site.Id == alert['JbtStandardObservationSiteId'];
							});
							if(type != undefined && site != undefined) {
								summary.alertType = 'Critical';
								summary.category = 'GSE';
								summary.text = 'Critical Alerts for GSE Asset Types ' + site.Name + '-' + type.Name;
							}
							else {
								console.log("Warning: CriticalGSEAssetType or site does not exist. type= ", alertCategoryId +  "site= %O", alert['JbtStandardObservationSiteId']);
							}

						} else if (capitalizedCategory == "GSEAssetType") {
							var type = this.gseAssetTypes.find((type) => {
								return type.Id == alertCategoryId;
							});
							var site = this.dataService.cache.sites.find((site) => {
								return site.Id == alert['JbtStandardObservationSiteId'];
							});
							if(type != undefined && site != undefined) {
								summary.alertType = 'Alert';
								summary.category = 'GSE';
								summary.text = 'Alerts for GSE Asset Types ' + site.Name + '-' + type.Name;
							}
							else {
								console.log("Warning: GSEAssetType or site does not exist. type= ", alertCategoryId +  "site= %O", alert['JbtStandardObservationSiteId']);
							}


						} else if (capitalizedCategory == "CriticalGSEAssetModel") {
							var model = this.gseAssetModels.find((type) => {
								return type.Id == alertCategoryId;
							});
							var site = this.dataService.cache.sites.find((site) => {
								return site.Id == alert['JbtStandardObservationSiteId'];
							});
							if(model != undefined && site != undefined) {
								summary.alertType = 'Critical';
								summary.category = 'GSE';
								summary.text = 'Critical Alerts for GSE Asset Model ' + site.Name + '-' + model.SelectorName;
							}
							else {
								console.log("Warning: CriticalGSEAssetModel or site does not exist. model= ", alertCategoryId +  "site= %O", alert['JbtStandardObservationSiteId']);
							}

						} else if (capitalizedCategory == "GSEAssetModel") {
							var model = this.gseAssetModels.find((type) => {
								return type.Id == alertCategoryId;
							});
							var site = this.dataService.cache.sites.find((site) => {
								return site.Id == alert['JbtStandardObservationSiteId'];
							});
							if(model != undefined && site != undefined) {
								summary.alertType = 'Alert';
								summary.category = 'GSE';
								summary.text = 'Alerts for GSE Asset Model ' + site.Name + '-' + model.SelectorName;
							}
							else {
								console.log("Warning: GSEAssetModel or site does not exist. model= ", alertCategoryId +  "site= %O", alert['JbtStandardObservationSiteId']);
							}

						}


						summary.notificationActive = this.IsConfiguredToSendOutNotifications(alert);

						if(summary.text != '') {
							alertSummaryTemp.push(summary);
						}
					}
					//console.log('alertCat', alertCat);
				});


			});

		//this.alertSummary = alertSummaryTemp.concat(this.alertSummaryFromOtherSites).toArray();
		this.alertSummary = alertSummaryTemp;

		this.alertSummaryCount = this.alertSummary.length;
	}

	GetGSEAssetTypes()
	{
		var assetTypeList = [86292, 86293, 86294, 86295, 86296, 86297, 86304, 86305, 86306, 86307];// temp until we have a GSE indicator
		this.gseAssetTypes = this.dataService.cache.assetTypes.filter((assetType) => {
			return assetTypeList.contains(assetType.Id); // >= 86293 && assetType.Id <= 86300;	// temp until we have a GSE indicator
		}).sort((a, b) => {
			let fa = a.Name.toLowerCase(),
				fb = b.Name.toLowerCase();

			if (fa < fb) {
				return -1;
			}
			if (fa > fb) {
				return 1;
			}
			return 0;
		});

		this.gseAssetModels = this.dataService.cache.assetModels.filter((assetModel) => {
			return assetTypeList.contains(assetModel.AssetTypeId); //assetModel.AssetTypeId >= 86293 && assetModel.AssetTypeId <= 86300;;	// temp until we have a GSE indicator
		});


		this.gseAssetTypes.forEach((assetType) => {

			var myModels = this.dataService.cache.assetModels.filter((assetModel) => {
				return assetModel.AssetTypeId == assetType.Id;	// temp until we have a GSE indicator
			}).sort((a, b) => {
				let fa = a.SelectorName.toLowerCase(),
					fb = b.SelectorName.toLowerCase();

				if (fa < fb) {
					return -1;
				}
				if (fa > fb) {
					return 1;
				}
				return 0;
			});

			assetType.models = myModels;

		});
	}

	GetStandardObservations()
	{
		let assetIdListArray = new Array();

		if(this.widgetObject.WidgetSiteId) {
			var assets = this.dataService.cache.assets.filter((a) => {
				return a.SiteId == this.widgetObject.WidgetSiteId;
			});
			assets.forEach(a => assetIdListArray.push(a.Id));
			this.GetStandardObservationsByAssetList(assetIdListArray);
		}
		else {
			this.dataService.GetFleetAssets(this.myFleet.FleetId).subscribe((data: any) => {
				data.forEach(record => {
					assetIdListArray.push(record.AssetId);
				});
				this.GetStandardObservationsByAssetList(assetIdListArray);
			});
		}
	}

	GetStandardObservationsByAssetList(assetList) {
		this.dataService.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds(assetList.join(','), true).subscribe((data) => {
			if(data) {
				var jbtStandardObservationIds = [];
				data.forEach(tag => jbtStandardObservationIds.push(tag.JBTStandardObservationId));

				this.alarmStandardObservationsForSite = this.dataService.cache.jbtStandardObservations.filter((so) => {
					return jbtStandardObservationIds.contains(so.Id);
				}).sort((a, b) => {
					let fa = a.Name.toLowerCase(),
						fb = b.Name.toLowerCase();

					if (fa < fb) {
						return -1;
					}
					if (fa > fb) {
						return 1;
					}
					return 0;
				});
			}

			//this.ValidateAllSelections();
			//this.GenerateSelectionsSummary();
		});
	}

	logValue(value: any) {
		console.log(value);
	}

	//TODO
	// vm.widget.loadAlternateEmailForwards = function ($query) {
	//     console.log("$query = %O", $query);
	//     var searchResults = {};

	//     return $q.all([
	//         dataService.GetIOPSResource("People")
	//             .filter(new $odata.Func("contains", "GivenName", $query), true)
	//             .query()
	//             .$promise
	//             .then(function (data) {
	//                 searchResults.GivenName = data;
	//                 return data;

	//             }),
	//         dataService.GetIOPSResource("People")
	//             .filter(new $odata.Func("contains", "FamilyName", $query), true)
	//             .query()
	//             .$promise
	//             .then(function (data) {
	//                 searchResults.FamilyName = data;
	//                 return data;

	//             })

	//     ]).then(function () {
	//         var resultData = searchResults.GivenName.concat(searchResults.FamilyName).distinct(function (a, b) { return a.Id == b.Id });
	//         console.log("Search results data = ", resultData);
	//         return resultData.select(function (person) {
	//             return {
	//                 text: person.GivenName + ' ' + person.FamilyName,
	//                 person: person
	//             }
	//         });
	//     });

	// }

	// vm.widget.alternateEmailForwardAdded = function ($tag) {
	//     console.log("Alternate Email Forward Added = %O", $tag);
	//     dataService.AddEntity("PersonEmailForwards", { PersonId: Global.User.PersonId, ForwardToPersonId: $tag.person.Id }).then(function(addedEntity) {
	//         vm.widget.alternateEmailForwards.first(function(ef){return ef.person.Id == addedEntity.ForwardToPersonId}).Id = addedEntity.Id;
	//         console.log("vm.widget.alternateEmailForwards = %O", vm.widget.alternateEmailForwards);
	//     });

	// }

	// vm.widget.alternateEmailForwardRemoved = function ($tag) {
	//     console.log("Alternate Email Forward Removed = %O", $tag);

	//     dataService.DeleteEntity("PersonEmailForwards", { Id: $tag.Id });
	// }

	// vm.widget.alternateEmailPeople = [];

	SetButtonSelectionsForCategory(category, dbEntity): boolean {
		var returnValue = false;
		var categoryFieldNamePrefix = category.substring(0, 1).toUpperCase() + category.substring(1);

		var categoryFieldId = dbEntity[categoryFieldNamePrefix + "Id"];




		if (categoryFieldId && this.dataCollections.category.contains(category)) {

			// for tag - flip to the Std Obs Id
			if(categoryFieldNamePrefix == "Tag") {
				let tag = this.alarmTags.find((t) => {
					return t.TagId == categoryFieldId
				});

				categoryFieldId = tag?.JBTStandardObservationId;
			}

			if(categoryFieldId == null) {
				return false;
			}


			this.buttonSelections[category].enabled[categoryFieldId] = true;

			this.buttonSelections[category].email[categoryFieldId] = dbEntity["Email"] != undefined ? dbEntity["Email"] == "True" || dbEntity["Email"] == true : undefined;

			this.buttonSelections[category].textMessage[categoryFieldId] = dbEntity["TextMessage"] != undefined ? dbEntity["TextMessage"] == "True" || dbEntity["TextMessage"] == true : undefined;

			this.buttonSelections[category].allDays[categoryFieldId] = dbEntity["SendAllDays"] != undefined ? dbEntity["SendAllDays"] == "True" || dbEntity["SendAllDays"] == true : undefined;

			this.buttonSelections[category].allHours[categoryFieldId] = dbEntity["SendAllHours"] != undefined ? dbEntity["SendAllHours"] == "True" || dbEntity["SendAllHours"] == true : undefined;

			this.buttonSelections[category].MinutesBetweenNotification[categoryFieldId] = dbEntity["MinutesBetweenNotification"];

			this.buttonSelections[category].MinutesToEscalation[categoryFieldId] = dbEntity["MinutesToEscalation"];

			this.dataCollections.daysOfWeek.forEach((dayOfWeek) => {
				// if (dbEntity['Send' + dayOfWeek]) {
				if (!this.buttonSelections[category].days[dayOfWeek]) {
					this.buttonSelections[category].days[dayOfWeek] = [];
				}
				this.buttonSelections[category].days[dayOfWeek][categoryFieldId] = dbEntity["Send" + dayOfWeek] != undefined ? dbEntity["Send" + dayOfWeek] == "True" || dbEntity["Send" + dayOfWeek] == true : undefined;
				// }
			});

			//Preset the hours buttons
			_.range(0, 24).forEach((hour) => {
				var hourAsTwoDigitString = hour.toString().padStart(2, "0");
				// if (dbEntity['SendHour' + hourAsTwoDigitString]) {
				if (!this.buttonSelections[category].hours[hour]) {
					this.buttonSelections[category].hours[hour] = [];
				}
				this.buttonSelections[category].hours[hour][categoryFieldId] = dbEntity["SendHour" + hourAsTwoDigitString] != undefined ?
															dbEntity["SendHour" + hourAsTwoDigitString] == "True" || dbEntity["SendHour" + hourAsTwoDigitString] == true : undefined;
				// }
			});

			returnValue = true;
		}

		return returnValue;
	}

	// GetGatesListForSideBar(): void {
	// 	this.gates = this.dataService.cache.systems
	// 		.filter((gs) => {
	// 			return (
	// 				gs.SiteId == this.widgetObject.WidgetSiteId &&
	// 				gs.TypeId == 3
	// 			);
	// 		})
	// 		.sort((g1, g2) =>
	// 			this.utilityService
	// 				.GetGateNameSortValue(g1.Name)
	// 				.toString()
	// 				.localeCompare(
	// 					this.utilityService.GetGateNameSortValue(g2.Name),
	// 					'en',
	// 					{ numeric: true }
	// 				)
	// 		);
	// 	if (this.gates.length > 0) {
	// 		this.widgetObject.WidgetGateSystemId =
	// 			this.widgetObject.WidgetGateSystemId || this.gates[0].Id;
	// 		this.gateSystem =
	// 			this.dataService.cache.systemsObject[
	// 				this.widgetObject.WidgetGateSystemId
	// 			];
	// 		this.GetAssetsListForSideBar();
	// 	}
	// 	console.log('Gates For Raw Tag Widget = %O', this.gates);
	// }

	GetAssetsListForSideBar(): void {
		if (this.widgetObject.WidgetGateSystemId) {
			var assets = this.dataService.cache.assets.filter((a) => {
				return a.ParentSystemId == this.widgetObject.WidgetGateSystemId;
			});

			assets.forEach((a) => {
				switch (a.Name) {
					case "PBB":
						a.sortNumber = 1;
						break;
					case "PCA":
						a.sortNumber = 2;
						break;
					case "GPU":
						a.sortNumber = 3;
						break;

					default:
						a.sortNumber = 0;
				}
				this.dataService.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventory(a.Id);
			});

			this.assets = _.sortBy(assets, "sortNumber");

			console.log('assets:', this.assets);

			this.widgetObject.WidgetAssetId = this.widgetObject.WidgetAssetId || this.assets.length > 0 ? this.assets[0].Id : null;

			this.asset = this.dataService.cache.assetsObject[this.widgetObject.WidgetAssetId];
			//console.clear();
			console.log("New Asset = %O", this.asset);
			this.GetAlarmTagsForAsset();

			console.log("AlarmTags For Asset = %O", this.alarmTags);
		}
	}

	GetAlarmTagsForAsset(): void {
		if (this.widgetObject.WidgetAssetId) {
			this.asset = this.dataService.cache.assetsObject[ this.widgetObject.WidgetAssetId ];

			// GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds(this.widgetObject.WidgetAssetId, true) // alarms only
			this.dataService
				.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventory(
					this.widgetObject.WidgetAssetId
				)
				.subscribe((data) => {
					Global.User.DebugMode && console.log('Monitoring asset = %O', this.asset);
					this.alarmTags = this.asset?.Tags.filter((tag: any) => {
						return (
							tag.isAlarm || tag.isCritical ||
							(tag.JBTStandardObservation &&
								(tag.JBTStandardObservation.JBTStandardObservationSeverityLevelId == 1 ||
									tag.JBTStandardObservation.JBTStandardObservationSeverityLevelId == 3)
							)
						);
					}).sort((a, b) => {
						let fa = a.JBTStandardObservation.Name.toLowerCase(),
							fb = b.JBTStandardObservation.Name.toLowerCase();

						if (fa < fb) {
							return -1;
						}
						if (fa > fb) {
							return 1;
						}
						return 0;
					});

					Global.User.DebugMode && console.log('Asset Alarm Tags = %O', this.alarmTags);

					this.dataLoaded = true;

			});
		}
	}


	IsEnabled(category, id)
	{
		var enabled = 0;
		if (this.buttonSelections[category].enabled[id] != undefined && this.buttonSelections[category].enabled[id] == true) {
			enabled = 1;
		}
		return enabled;
	}

	// SelectedTextForPanelDescriptionTags
	SelectedTextForPanelDescriptionGSEAssetCategoryTypeSubsection(category)
	{
		var countOfModelsEnabled = 0;
		if(category == 'CriticalGSEAssetModel' || category == 'GSEAssetModel')
		{
			this.gseAssetTypes.forEach(type =>
				{
					countOfModelsEnabled = 0;
					type.models.forEach(model =>
						{
							if(this.buttonSelections[category].enabled[model.Id] != undefined
								&& this.buttonSelections[category].enabled[model.Id] == true)
							{
								++countOfModelsEnabled;
							}
						});
					type.countOfModelsEnabled = countOfModelsEnabled;
				});
		}
	}

	// SelectedTextForPanelDescription - Build the text string to be displayed in the header for categories which have multiple levels
	// input: category (string)
	SelectedTextForPanelDescriptionTagCategory(category)
	{
		var count = 0;
		if(category == 'JbtStandardObservation' || category == 'CriticalGSEAssetType' || category == 'GSEAssetType'  || category == 'CriticalGSEAssetModel' || category == 'GSEAssetModel')
		{
			count = this.buttonSelections[category].enabled.filter(item => {
				return item == true;
			}).length;
		}
		else if(category == 'tag')
		{
			this.alarmTags?.forEach(tag =>
				{
					if(this.buttonSelections[category].enabled[tag.JBTStandardObservationId] != undefined
					   && this.buttonSelections[category].enabled[tag.JBTStandardObservationId] == true)
				   {
					   ++count;
				   }
				});
		}
		this.validation[category].description = count > 0 ? count + " enabled" : "";
	}

	// SelectedTextForPanelDescription - Build the text string to be displayed in the header
	// input: category (string)
	SelectedTextForPanelDescription(category, tagid?)
	{
		var SelectionsForPanelDescription = [];
		var DaysSelectionsForPanelDescription = [];
		var HoursSelectionsForPanelDescription = [];

		// id will change depending on category
		var id = category == 'tag' || category == 'JbtStandardObservation' ||
					category == 'CriticalGSEAssetType' || category == 'GSEAssetType' ||
					category == 'CriticalGSEAssetModel' || category == 'GSEAssetModel' ? tagid : this.GetIdForCategory(category);

		if (this.buttonSelections[category].enabled[id] == undefined || this.buttonSelections[category].enabled[id] == false) {
			return;
		}

		this.buttonSelections[category].MinutesBetweenNotification[id] ? SelectionsForPanelDescription.push("Wait " + this.buttonSelections[category].MinutesBetweenNotification[id] + " mins") : "";
		this.buttonSelections[category].email[id] ? SelectionsForPanelDescription.push("Email") : "";
		this.buttonSelections[category].textMessage[id] ? SelectionsForPanelDescription.push("Text") : "";

		if (this.buttonSelections[category].allDays[id]) {
			SelectionsForPanelDescription.push("All Days");
		} else {
			this.dataCollections.daysOfWeek.forEach((dayOfWeek) => {
				if (this.buttonSelections[category].days[dayOfWeek] != undefined && this.buttonSelections[category].days[dayOfWeek][id] != undefined && this.buttonSelections[category].days[dayOfWeek][id] == true) {
					DaysSelectionsForPanelDescription.push(dayOfWeek);
				}
			});

			if (DaysSelectionsForPanelDescription.length > 0) {
				SelectionsForPanelDescription.push("Days: (" + DaysSelectionsForPanelDescription.join(", ") + ")");
			}
		}

		if (this.buttonSelections[category].allHours[id]) {
			SelectionsForPanelDescription.push("All Hours");
		} else {
			this.dataCollections.hoursOfDay00to11.concat(this.dataCollections.hoursOfDay12to23).forEach((hourOfDay) => {
				if (this.buttonSelections[category].hours[hourOfDay] != undefined && this.buttonSelections[category].hours[hourOfDay][id] != undefined && this.buttonSelections[category].hours[hourOfDay][id] == true) {
					HoursSelectionsForPanelDescription.push(hourOfDay);
				}
			});

			if (HoursSelectionsForPanelDescription.length > 0) {
				SelectionsForPanelDescription.push("Hours: (" + HoursSelectionsForPanelDescription.join(", ") + ")");
			}
		}

		if (this.buttonSelections[category].MinutesToEscalation[id] && this.widgetObject.reportsToPerson) {
			SelectionsForPanelDescription.push("Escalate To " + this.widgetObject.reportsToPerson.GivenName + " " + this.widgetObject.reportsToPerson.FamilyName + " after " + this.buttonSelections[category].MinutesToEscalation[id] + " mins");
		}


		if(category == 'tag' || category == 'JbtStandardObservation' || category == 'CriticalGSEAssetType' || category == 'GSEAssetType' || category == 'CriticalGSEAssetModel' || category == 'GSEAssetModel') {
			this.validation[category].tagDescription[id] = SelectionsForPanelDescription.join(", ");
		}
		else {
			this.validation[category].description = SelectionsForPanelDescription.join(", ");
		}

	}

	// UserAbleToSendTextMessage - Determines if user has a cell phone carrier. if not, gives message to be displayed.
	// input: none  output: string
	// called by alert-configuration.component.html
		// deprecated
	UserAbleToSendTextMessage(): string {
		return this.person.CellPhoneCarrierId == undefined ? "No Carrier found. Go to Personal Information to add your carrier." : "";
	}

	// AnyDaysSelected - Determines if any days are selected for this category
	// input: category (string), id (long)  output: boolean
	// called by alert-configuration.component.html
		// deprecated

	AnyDaysSelected(category, id): boolean {
		var selectedAnyDays = false;
		this.dataCollections.daysOfWeek.forEach((dayOfWeek) => {
			if (this.buttonSelections[category].days[dayOfWeek] != undefined && this.buttonSelections[category].days[dayOfWeek][id] != undefined && this.buttonSelections[category].days[dayOfWeek][id] == true) {
				selectedAnyDays = true;
			}
		});
		return selectedAnyDays;
	}

	// AnyHoursSelected - Determines if any hours are selected for this category
	// input: category (string), id (long)  output: boolean
	// called by alert-configuration.component.html
		// deprecated

	AnyHoursSelected(category, id): boolean {
		var selectedAnyHours = false;
		this.dataCollections.hoursOfDay00to11.concat(this.dataCollections.hoursOfDay12to23).forEach((hourOfDay) => {
			if (this.buttonSelections[category].hours[hourOfDay] != undefined && this.buttonSelections[category].hours[hourOfDay][id] != undefined && this.buttonSelections[category].hours[hourOfDay][id] == true) {
				selectedAnyHours = true;
			}
		});
		return selectedAnyHours;
	}

	// ValidateAtLeastOneCategoryEnabled - Checks each of the Categories to make sure at least 1 is enabled
	ValidateAtLeastOneCategoryEnabled() {
		this.oneAlertEnabled = "Enable at least one alert type";
		this.dataCollections.category.forEach(cat =>
			{
				if(cat == 'tag')
				{
					this.alarmTags?.forEach(tag =>
					 	{
					 		if(this.buttonSelections[cat].enabled[tag.Id] != undefined
								&& this.buttonSelections[cat].enabled[tag.Id] == true)
							{
								this.oneAlertEnabled = "";
							}
					 	});
				}
				else if(cat == 'JbtStandardObservation')
				{
					this.alarmStandardObservationsForSite?.forEach(so =>
						{
							if(this.buttonSelections[cat].enabled[so.Id] != undefined
							   && this.buttonSelections[cat].enabled[so.Id] == true)
						   {
							   this.oneAlertEnabled = "";
						   }
						});
				}
				else
				{
					var Id = this.GetIdForCategory(cat);
					if(this.buttonSelections[cat].enabled[Id] != undefined
						&& this.buttonSelections[cat].enabled[Id] == true)
					{
						this.oneAlertEnabled = "";
					}
				}

			});
	}

	//category: ['criticalSite', 'site', 'criticalGateSystem', 'gateSystem', 'criticalAsset', 'asset',
	//'allPBBCriticalSite', 'allPBBSite', 'allPCACriticalSite', 'allPCASite', 'allGPUCriticalSite', 'allGPUSite',

	// GetIdForCategory - Given a category return its corresponding Id (Site, Gate, or Asset)
	GetIdForCategory(cat): number {
		var Id = this.widgetObject.WidgetSiteId;
		if (cat == "gateSystem" || cat == "criticalGateSystem") {
			Id = this.widgetObject.WidgetGateSystemId;
		} else if (cat == "asset" || cat == "criticalAsset" || cat == "alertAsset") {
			Id = this.widgetObject.WidgetAssetId;
		} else if (cat == "Thing" || cat == "CriticalThing" || cat == "AlertThing" ) {
			Id = this.myFleet?.FleetId;
		}
		return Id;
	}

	// ValidateAllSelections - Calls Validate foreach category
	ValidateAllSelections() {
		console.log("validate selections");
		this.dataCollections.category.forEach(cat =>
			{
				if(cat == 'tag')
				{
					this.SelectedTextForPanelDescriptionTagCategory(cat);

					this.alarmTags?.forEach(tag =>
						{
							this.ValidateSelectionsSubsections(cat, tag.JBTStandardObservationId);
							this.SelectedTextForPanelDescription(cat, tag.JBTStandardObservationId);
						});
				}
				else if(cat == 'JbtStandardObservation')
				{
					this.SelectedTextForPanelDescriptionTagCategory(cat);

					this.alarmStandardObservationsForSite?.forEach(so =>
						{
							this.ValidateSelectionsSubsections(cat, so.Id);
							this.SelectedTextForPanelDescription(cat, so.Id);
						});
				}
				else if(cat == 'CriticalGSEAssetType' || cat == 'GSEAssetType')
				{
					this.SelectedTextForPanelDescriptionTagCategory(cat);

					this.gseAssetTypes?.forEach(type =>
						{
							type.isTypeEnabled = this.IsEnabled(cat, type.Id);
							this.ValidateSelectionsSubsections(cat, type.Id);
							this.SelectedTextForPanelDescription(cat, type.Id);
						});
				}
				else if(cat == 'CriticalGSEAssetModel' || cat == 'GSEAssetModel')
				{
					this.SelectedTextForPanelDescriptionTagCategory(cat);
					this.SelectedTextForPanelDescriptionGSEAssetCategoryTypeSubsection(cat);

					this.gseAssetModels?.forEach(model =>
						{
							this.ValidateSelectionsSubsections(cat, model.Id);
							this.SelectedTextForPanelDescription(cat, model.Id);
						});
				}
				else
				{
					this.ValidateSelections(cat, this.GetIdForCategory(cat));
					this.SelectedTextForPanelDescription(cat, this.GetIdForCategory(cat));
				}
			});
		console.log("validate selections complete");
	}

	IsConfiguredToSendOutNotifications(alert) : string {

		var isDays = this.dataCollections.daysOfWeek.some((dayOfWeek) => { return alert['Send' + dayOfWeek] == true;});
		var isHours = this.dataCollections.hoursOfDay00to11.concat(this.dataCollections.hoursOfDay12to23).some((hourOfDay) => {
			var hourAsTwoDigitString = hourOfDay.toString().padStart(2, "0");
			return alert["SendHour" + hourAsTwoDigitString] == true;});

		return (alert.Email || alert.TextMessage) && (alert.SendAllDays || isDays) && (alert.SendAllHours || isHours) ? 'success' : 'normal';
	}

	// ValidateSelections - Checks the following validation cases
	//  1. validate email and/or text selection. either email or text must be selected to be valid
	//  2. validation days. either "All Days" or at least 1 day must be selected
	//  3. validation hours. either "All Hours" or at least 1 hour must be selected
	// input: category (string), id (int)  output: none (sets the validation strings)
	ValidateSelections(category, id) {
		// only validate enabled categories
		if (this.buttonSelections[category].enabled[id] != undefined && this.buttonSelections[category].enabled[id] == true) {
			// init validation
			this.validation[category].days = ""; this.validation[category].emailOrText = ""; this.validation[category].hours = ""; this.validation[category].oneAlert = "";

			// validate email and/or text selection
			if ((this.buttonSelections[category].email[id] == undefined || this.buttonSelections[category].email[id] == false) && (this.buttonSelections[category].textMessage[id] == undefined || this.buttonSelections[category].textMessage[id] == false)) {
				// check at least 1 email or text
				this.validation[category].emailOrText = "select Email and/or Text Message";
			}

			// validation days
			if (this.buttonSelections[category].allDays[id] == undefined || this.buttonSelections[category].allDays[id] == false) {
				// check at least 1 day is selected
				var selectedAnyDays = false;
				this.dataCollections.daysOfWeek.forEach((dayOfWeek) => {
					if (this.buttonSelections[category].days[dayOfWeek] != undefined && this.buttonSelections[category].days[dayOfWeek][id] != undefined && this.buttonSelections[category].days[dayOfWeek][id] == true) {
						selectedAnyDays = true;
					}
				});
				if (selectedAnyDays == false) {
					this.validation[category].days = "select All Days or at least 1 day";
				}
			}

			// validation hours
			if (this.buttonSelections[category].allHours[id] == undefined || this.buttonSelections[category].allHours[id] == false) {
				// check at least 1 hour is selected
				var selectedAnyHours = false;
				this.dataCollections.hoursOfDay00to11.concat(this.dataCollections.hoursOfDay12to23).forEach((hourOfDay) => {
					if (this.buttonSelections[category].hours[hourOfDay] != undefined && this.buttonSelections[category].hours[hourOfDay][id] != undefined && this.buttonSelections[category].hours[hourOfDay][id] == true) {
						selectedAnyHours = true;
					}
				});

				if (selectedAnyHours == false) {
					this.validation[category].hours = "select All Hours or at least 1 hour";
				}
			}
		}
	}


	ValidateSelectionsSubsections(category, id)
	{
		// only validate enabled categories
		if(this.buttonSelections[category].enabled[id] != undefined && this.buttonSelections[category].enabled[id] == true)
		{
			// init validation
			this.validation[category].days[id] = ""; this.validation[category].emailOrText[id] = ""; this.validation[category].hours[id] = ""; this.validation[category].oneAlert = "";

			// validate email and/or text selection
			if( (this.buttonSelections[category].email[id] == undefined
					|| this.buttonSelections[category].email[id] == false)
				&& (this.buttonSelections[category].textMessage[id] == undefined
					|| this.buttonSelections[category].textMessage[id] == false) )
			{
				// check at least 1 email or text
				this.validation[category].emailOrText[id] = "select Email and/or Text Message";
			}

			// validation days
			if(this.buttonSelections[category].allDays[id] == undefined
				|| this.buttonSelections[category].allDays[id] == false)
			{
				// check at least 1 day is selected
				var selectedAnyDays = false;
				this.dataCollections.daysOfWeek.forEach((dayOfWeek) =>
				{
					if(this.buttonSelections[category].days[dayOfWeek] != undefined
						&& this.buttonSelections[category].days[dayOfWeek][id] != undefined
						&& this.buttonSelections[category].days[dayOfWeek][id] == true)
					{
						selectedAnyDays = true;
					}
				});
				if(selectedAnyDays==false)
				{
					this.validation[category].days[id] = "select All Days or at least 1 day";
				}
			}

			// validation hours
			if(this.buttonSelections[category].allHours[id] == undefined
				|| this.buttonSelections[category].allHours[id] == false)
			{
				// check at least 1 hour is selected
				var selectedAnyHours = false;
				this.dataCollections.hoursOfDay00to11
						.concat(this.dataCollections.hoursOfDay12to23)
						.forEach((hourOfDay) => {
							if(this.buttonSelections[category].hours[hourOfDay] != undefined
								&& this.buttonSelections[category].hours[hourOfDay][id] != undefined
								&& this.buttonSelections[category].hours[hourOfDay][id]==true)
							{
								selectedAnyHours = true;
							}
						});

				if(selectedAnyHours==false)
				{
					this.validation[category].hours[id] = "select All Hours or at least 1 hour";
				}
			}
		}
	}


	ProcessButtonSelections() {
		setTimeout(() => {
			console.log("buttonSelections = %O", this.buttonSelections);

			var allSelectedButtonsAsDatabaseRecords = [];
			this.GetDatabaseEntityImageForSelectionCategory("criticalSite", allSelectedButtonsAsDatabaseRecords);

			this.GetDatabaseEntityImageForSelectionCategory("site", allSelectedButtonsAsDatabaseRecords);

			this.GetDatabaseEntityImageForSelectionCategory("criticalGateSystem", allSelectedButtonsAsDatabaseRecords);
			this.GetDatabaseEntityImageForSelectionCategory("gateSystem", allSelectedButtonsAsDatabaseRecords);

			this.GetDatabaseEntityImageForSelectionCategory("criticalAsset", allSelectedButtonsAsDatabaseRecords);
			this.GetDatabaseEntityImageForSelectionCategory("alertAsset", allSelectedButtonsAsDatabaseRecords);
			this.GetDatabaseEntityImageForSelectionCategory("asset", allSelectedButtonsAsDatabaseRecords);

			this.GetDatabaseEntityImageForSelectionCategory("allPCACriticalSite", allSelectedButtonsAsDatabaseRecords);
			this.GetDatabaseEntityImageForSelectionCategory("allPCASite", allSelectedButtonsAsDatabaseRecords);

			this.GetDatabaseEntityImageForSelectionCategory("allPBBCriticalSite", allSelectedButtonsAsDatabaseRecords);
			this.GetDatabaseEntityImageForSelectionCategory("allPBBSite", allSelectedButtonsAsDatabaseRecords);

			this.GetDatabaseEntityImageForSelectionCategory("allGPUCriticalSite", allSelectedButtonsAsDatabaseRecords);
			this.GetDatabaseEntityImageForSelectionCategory("allGPUSite", allSelectedButtonsAsDatabaseRecords);

			this.GetDatabaseEntityImageForSelectionCategory("allGSECriticalSite", allSelectedButtonsAsDatabaseRecords);
			this.GetDatabaseEntityImageForSelectionCategory("allGSESite", allSelectedButtonsAsDatabaseRecords);

			this.GetDatabaseEntityImageForSelectionCategory("CriticalGSEAssetType", allSelectedButtonsAsDatabaseRecords);
			this.GetDatabaseEntityImageForSelectionCategory("GSEAssetType", allSelectedButtonsAsDatabaseRecords);

			this.GetDatabaseEntityImageForSelectionCategory("CriticalGSEAssetModel", allSelectedButtonsAsDatabaseRecords);
			this.GetDatabaseEntityImageForSelectionCategory("GSEAssetModel", allSelectedButtonsAsDatabaseRecords);

			this.GetDatabaseEntityImageForSelectionCategory("CriticalThing", allSelectedButtonsAsDatabaseRecords);
			this.GetDatabaseEntityImageForSelectionCategory("AlertThing", allSelectedButtonsAsDatabaseRecords);
			this.GetDatabaseEntityImageForSelectionCategory("Thing", allSelectedButtonsAsDatabaseRecords);

			this.GetDatabaseEntityImageForSelectionCategory(
				'tag',
				allSelectedButtonsAsDatabaseRecords
			);
			this.GetDatabaseEntityImageForSelectionCategory(
				'JbtStandardObservation',
				allSelectedButtonsAsDatabaseRecords
			);
			console.log("DB array Images = %O", allSelectedButtonsAsDatabaseRecords);

			//debugger;

			//Modify the existing entities
			allSelectedButtonsAsDatabaseRecords.forEach((sbEntity) => {
				var dbEntityMatch = this.dbEmailTagUsers?.find((dbEntity) => {
					return (
						(sbEntity.SiteId || -1) == (dbEntity.SiteId || -2) ||
						(sbEntity.CriticalSiteId || -1) ==
							(dbEntity.CriticalSiteId || -2) ||
						(sbEntity.GateSystemId || -1) ==
							(dbEntity.GateSystemId || -2) ||
						(sbEntity.CriticalGateSystemId || -1) ==
							(dbEntity.CriticalGateSystemId || -2) ||
						(sbEntity.AssetId || -1) == (dbEntity.AssetId || -2) ||
						(sbEntity.CriticalAssetId || -1) ==
							(dbEntity.CriticalAssetId || -2) ||
						(sbEntity.AlertAssetId || -1) ==
							(dbEntity.AlertAssetId || -2) ||
						(sbEntity.ThingId || -1) == (dbEntity.ThingId || -2) ||
						(sbEntity.CriticalThingId || -1) ==
							(dbEntity.CriticalThingId || -2) ||
						(sbEntity.AlertThingId || -1) ==
								(dbEntity.AlertThingId || -2) ||		
						(sbEntity.TagId || -1) == (dbEntity.TagId || -2) ||
						(sbEntity.AllPBBSiteId || -1) ==
							(dbEntity.AllPBBSiteId || -2) ||
						(sbEntity.AllPBBCriticalSiteId || -1) ==
							(dbEntity.AllPBBCriticalSiteId || -2) ||
						(sbEntity.AllPCASiteId || -1) ==
							(dbEntity.AllPCASiteId || -2) ||
						(sbEntity.AllPCACriticalSiteId || -1) ==
							(dbEntity.AllPCACriticalSiteId || -2) ||
						(sbEntity.AllGPUSiteId || -1) ==
							(dbEntity.AllGPUSiteId || -2) ||
						(sbEntity.AllGPUCriticalSiteId || -1) ==
							(dbEntity.AllGPUCriticalSiteId || -2) ||
						(sbEntity.AllGSESiteId || -1) ==
							(dbEntity.AllGSESiteId || -2) ||
						(sbEntity.AllGSECriticalSiteId || -1) ==
							(dbEntity.AllGSECriticalSiteId || -2) ||
						(sbEntity.GSEAssetTypeId || -1) ==
							(dbEntity.GSEAssetTypeId || -2) ||
						(sbEntity.CriticalGSEAssetTypeId || -1) ==
							(dbEntity.CriticalGSEAssetTypeId || -2) ||
						(sbEntity.GSEAssetModelId || -1) ==
							(dbEntity.GSEAssetModelId || -2) ||
						(sbEntity.CriticalGSEAssetModelId || -1) ==
							(dbEntity.CriticalGSEAssetModelId || -2) ||
						(sbEntity.JbtStandardObservationId || -1) ==
							(dbEntity.JbtStandardObservationId || -2)
					);
				});
				if (dbEntityMatch) {
					//debugger;
					var modified = false;
					Object.keys(sbEntity).forEach((fieldName) => {
						if (dbEntityMatch[fieldName] != sbEntity[fieldName]) {
							modified = true;
							dbEntityMatch[fieldName] = sbEntity[fieldName];
						}
					});

					if (modified) {

						let propertyArray = [
							'AllGPUCriticalSiteId',
							'AllGPUSiteId',
							'AllGSECriticalSiteId',
							'AllGSESiteId',
							'AllPBBCriticalSiteId',
							'AllPBBSiteId',
							'AllPCACriticalSiteId',
							'AllPCASiteId',
							'AssetId',
							'CreationDate',
							'CreatorUserId',
							'CriticalAssetId',
							'AlertAssetId',
							'CriticalGateSystemId',
							'CriticalGSEAssetModelId',
							'CriticalGSEAssetTypeId',
							'CriticalSiteId',
							'CriticalThingId',
							'AlertThingId',
							'Email',
							'GateSystemId',
							'GroupThingId',
							'GSEAssetModelId',
							'GSEAssetTypeId',
							'JbtStandardObservation',
							'JbtStandardObservationSiteId',
							'LastModifiedDate',
							'LastModifiedUserId',
							'MinutesBetweenNotification',
							'MinutesToEscalation',
							'SendAllDays',
							'SendAllHours',
							'SendFri',
							'SendHour00',
							'SendHour01',
							'SendHour02',
							'SendHour03',
							'SendHour04',
							'SendHour05',
							'SendHour06',
							'SendHour07',
							'SendHour08',
							'SendHour09',
							'SendHour10',
							'SendHour11',
							'SendHour12',
							'SendHour13',
							'SendHour14',
							'SendHour15',
							'SendHour16',
							'SendHour17',
							'SendHour18',
							'SendHour19',
							'SendHour20',
							'SendHour21',
							'SendHour22',
							'SendHour23',
							'SendMon',
							'SendSat',
							'SendSun',
							'SendThu',
							'SendTue',
							'SendWed',
							'SiteId',
							'TagId',
							'TextMessage',
							'ThingId',
						];
						dbEntityMatch.LastModifiedUserId =
							Global.User.currentUser.Id;
						let jsDate = new Date();
						dbEntityMatch.LastModifiedDate = moment(jsDate).format("YYYY/MM/DD HH:mm:ss");
						dbEntityMatch.UserTimeZoneOffset = this.utilityService.GetUserTimeZoneOffsetHours();
						dbEntityMatch.UserTimeZoneName = moment.tz.guess();

						for (const objectProperty in dbEntityMatch) {
							if (dbEntityMatch[objectProperty] === undefined) {
								dbEntityMatch[objectProperty] = null;
							}
						}
						propertyArray.forEach((propertyAsString) => {
							if (dbEntityMatch[propertyAsString] === undefined) {
								dbEntityMatch[propertyAsString] = null;
							}
						});
						console.log('dbEntityMatch:', dbEntityMatch);
						let statement =
							"API.AlertManagementEditExistingAlarmRecord " +
							"@Id= " +
							dbEntityMatch.Id +
							", @UserId = " +
							dbEntityMatch.UserId +
							", @Email = " +
							dbEntityMatch.Email +
							", @TextMessage = " +
							dbEntityMatch.TextMessage +
							", @CriticalSiteId = " +
							dbEntityMatch.CriticalSiteId +
							", @SiteId = " +
							dbEntityMatch.SiteId +
							", @CriticalGateSystemId = " +
							dbEntityMatch.CriticalGateSystemId +
							", @GateSystemId = " +
							dbEntityMatch.GateSystemId +
							", @CriticalAssetId = " +
							dbEntityMatch.CriticalAssetId +
							", @AlertAssetId = " +
							dbEntityMatch.AlertAssetId +
							", @AssetId = " +
							dbEntityMatch.AssetId +
							", @TagId = " +
							dbEntityMatch.TagId +
							", @JbtStandardObservationId = " +
							dbEntityMatch.JbtStandardObservationId +
							", @JbtStandardObservationSiteId = " +
							dbEntityMatch.JbtStandardObservationSiteId +
							", @AllPBBCriticalSiteId = " +
							dbEntityMatch.AllPBBCriticalSiteId +
							", @AllPBBSiteId = " +
							dbEntityMatch.AllPBBSiteId +
							", @AllPCACriticalSiteId = " +
							dbEntityMatch.AllPCACriticalSiteId +
							", @AllPCASiteId = " +
							dbEntityMatch.AllPCASiteId +
							", @AllGPUCriticalSiteId = " +
							dbEntityMatch.AllGPUCriticalSiteId +
							", @AllGPUSiteId = " +
							dbEntityMatch.AllGPUSiteId +
							", @AllGSECriticalSiteId = " +
							dbEntityMatch.AllGSECriticalSiteId +
							", @AllGSESiteId = " +
							dbEntityMatch.AllGSESiteId +

							", @CriticalGSEAssetModelId = " +
							dbEntityMatch.CriticalGSEAssetModelId +
							", @GSEAssetModelId = " +
							dbEntityMatch.GSEAssetModelId +

							", @CriticalGSEAssetTypeId = " +
							dbEntityMatch.CriticalGSEAssetTypeId +
							", @GSEAssetTypeId = " +
							dbEntityMatch.GSEAssetTypeId +

							", @CriticalThingId = " +
							dbEntityMatch.CriticalThingId +
							", @AlertThingId = " +
							dbEntityMatch.AlertThingId +
							", @ThingId = " +
							dbEntityMatch.ThingId +

							", @GroupThingId = " +
							dbEntityMatch.GroupThingId +

							', @LastModifiedUserId = ' +
							dbEntityMatch.LastModifiedUserId +
							", @LastModifiedDate = '" +
							dbEntityMatch.LastModifiedDate +
							"', @MinutesBetweenNotification = " +
							dbEntityMatch.MinutesBetweenNotification +
							", @MinutesToEscalation = " +
							dbEntityMatch.MinutesToEscalation +
							", @SendAllHours = " +
							dbEntityMatch.SendAllHours +
							", @SendAllDays = " +
							dbEntityMatch.SendAllDays +
							", @SendHour00 = " +
							dbEntityMatch.SendHour00 +
							", @SendHour01 = " +
							dbEntityMatch.SendHour01 +
							", @SendHour02 = " +
							dbEntityMatch.SendHour02 +
							", @SendHour03 = " +
							dbEntityMatch.SendHour03 +
							", @SendHour04 = " +
							dbEntityMatch.SendHour04 +
							", @SendHour05 = " +
							dbEntityMatch.SendHour05 +
							", @SendHour06 = " +
							dbEntityMatch.SendHour06 +
							", @SendHour07 = " +
							dbEntityMatch.SendHour07 +
							", @SendHour08 = " +
							dbEntityMatch.SendHour08 +
							", @SendHour09 = " +
							dbEntityMatch.SendHour09 +
							", @SendHour10 = " +
							dbEntityMatch.SendHour10 +
							", @SendHour11 = " +
							dbEntityMatch.SendHour11 +
							", @SendHour12 = " +
							dbEntityMatch.SendHour12 +
							", @SendHour13 = " +
							dbEntityMatch.SendHour13 +
							", @SendHour14 = " +
							dbEntityMatch.SendHour14 +
							", @SendHour15 = " +
							dbEntityMatch.SendHour15 +
							", @SendHour16 = " +
							dbEntityMatch.SendHour16 +
							", @SendHour17 = " +
							dbEntityMatch.SendHour17 +
							", @SendHour18 = " +
							dbEntityMatch.SendHour18 +
							", @SendHour19 = " +
							dbEntityMatch.SendHour19 +
							", @SendHour20 = " +
							dbEntityMatch.SendHour20 +
							", @SendHour21 = " +
							dbEntityMatch.SendHour21 +
							", @SendHour22 = " +
							dbEntityMatch.SendHour22 +
							", @SendHour23 = " +
							dbEntityMatch.SendHour23 +
							", @SendSun = " +
							dbEntityMatch.SendSun +
							", @SendMon = " +
							dbEntityMatch.SendMon +
							", @SendTue = " +
							dbEntityMatch.SendTue +
							", @SendWed = " +
							dbEntityMatch.SendWed +
							", @SendThu = " +
							dbEntityMatch.SendThu +
							", @SendFri = " +
							dbEntityMatch.SendFri +
							", @SendSat = " +
							dbEntityMatch.SendSat +
							", @UserTimeZoneOffset = " +
							dbEntityMatch.UserTimeZoneOffset +
							", @UserTimeZoneName = '" +
							dbEntityMatch.UserTimeZoneName + "'"
							;
						this.dataService.SQLActionAsPromise(statement).then((data: any) => {
							console.log('modified data:', data);
							let findIndex = this.dbEmailTagUsers.findIndex((record) => {
								return record.Id === dbEntityMatch.Id;
							});

							this.dbEmailTagUsers[findIndex] = data[0];
						});
						// dbEntityMatch.$save();
						// this.signalR.signalAllClientsInGroup("Admin", "EmailTagUser Modified", dbEntityMatch.Id);
						console.log("Modified %O", dbEntityMatch);

						this.ValidateAllSelections();
						this.GenerateSelectionsSummary();

					}
				}
			});

			//Create the new entities
			var entitiesToCreate = allSelectedButtonsAsDatabaseRecords.filter(
				(sbEntity) => {
					var dbEntityMatch = this.dbEmailTagUsers?.find(
						(dbEntity) => {
							return (
								(sbEntity.SiteId || -1) ==
									(dbEntity.SiteId || -2) ||
								(sbEntity.CriticalSiteId || -1) ==
									(dbEntity.CriticalSiteId || -2) ||
								(sbEntity.GateSystemId || -1) ==
									(dbEntity.GateSystemId || -2) ||
								(sbEntity.CriticalGateSystemId || -1) ==
									(dbEntity.CriticalGateSystemId || -2) ||
								(sbEntity.AssetId || -1) ==
									(dbEntity.AssetId || -2) ||
								(sbEntity.CriticalAssetId || -1) ==
									(dbEntity.CriticalAssetId || -2) ||
								(sbEntity.AlertAssetId || -1) ==
									(dbEntity.AlertAssetId || -2) ||
								(sbEntity.TagId || -1) ==
									(dbEntity.TagId || -2) ||
								(sbEntity.AllPBBSiteId || -1) ==
									(dbEntity.AllPBBSiteId || -2) ||
								(sbEntity.AllPBBCriticalSiteId || -1) ==
									(dbEntity.AllPBBCriticalSiteId || -2) ||
								(sbEntity.AllPCASiteId || -1) ==
									(dbEntity.AllPCASiteId || -2) ||
								(sbEntity.AllPCACriticalSiteId || -1) ==
									(dbEntity.AllPCACriticalSiteId || -2) ||
								(sbEntity.AllGPUSiteId || -1) ==
									(dbEntity.AllGPUSiteId || -2) ||
								(sbEntity.AllGPUCriticalSiteId || -1) ==
									(dbEntity.AllGPUCriticalSiteId || -2) ||
								(sbEntity.AllGSESiteId || -1) ==
									(dbEntity.AllGSESiteId || -2) ||
								(sbEntity.AllGSECriticalSiteId || -1) ==
									(dbEntity.AllGSECriticalSiteId || -2) ||
								(sbEntity.GSEAssetTypeId || -1) ==
									(dbEntity.GSEAssetTypeId || -2) ||
								(sbEntity.CriticalGSEAssetTypeId || -1) ==
									(dbEntity.CriticalGSEAssetTypeId || -2) ||
								(sbEntity.GSEAssetModelId || -1) ==
									(dbEntity.GSEAssetModelId || -2) ||
								(sbEntity.CriticalGSEAssetModelId || -1) ==
									(dbEntity.CriticalGSEAssetModelId || -2) ||

								(sbEntity.ThingId || -1) ==
									(dbEntity.ThingId || -2) ||
								(sbEntity.CriticalThingId || -1) ==
									(dbEntity.CriticalThingId || -2) ||
								(sbEntity.AlertThingId || -1) ==
									(dbEntity.AlertThingId || -2) ||

								(sbEntity.JbtStandardObservationId || -1) ==
									(dbEntity.JbtStandardObservationId || -2)
							);
						}
					);
					return !dbEntityMatch;
				}
			);

			//debugger;
			entitiesToCreate.forEach((entityToCreate) => {
				let propertyArray = [
					'AllGPUCriticalSiteId',
					'AllGPUSiteId',
					'AllGSECriticalSiteId',
					'AllGSESiteId',
					'AllPBBCriticalSiteId',
					'AllPBBSiteId',
					'AllPCACriticalSiteId',
					'AllPCASiteId',
					'AssetId',
					'CreationDate',
					'CreatorUserId',
					'CriticalAssetId',
					'AlertAssetId',
					'CriticalGateSystemId',
					'CriticalGSEAssetModelId',
					'CriticalGSEAssetTypeId',
					'CriticalSiteId',
					'CriticalThingId',
					'AlertThingId',
					'Email',
					'GateSystemId',
					'GroupThingId',
					'GSEAssetModelId',
					'GSEAssetTypeId',
					'JbtStandardObservationId',
					'JbtStandardObservationSiteId',
					'LastModifiedDate',
					'LastModifiedUserId',
					'MinutesBetweenNotification',
					'MinutesToEscalation',
					'SendAllDays',
					'SendAllHours',
					'SendFri',
					'SendHour00',
					'SendHour01',
					'SendHour02',
					'SendHour03',
					'SendHour04',
					'SendHour05',
					'SendHour06',
					'SendHour07',
					'SendHour08',
					'SendHour09',
					'SendHour10',
					'SendHour11',
					'SendHour12',
					'SendHour13',
					'SendHour14',
					'SendHour15',
					'SendHour16',
					'SendHour17',
					'SendHour18',
					'SendHour19',
					'SendHour20',
					'SendHour21',
					'SendHour22',
					'SendHour23',
					'SendMon',
					'SendSat',
					'SendSun',
					'SendThu',
					'SendTue',
					'SendWed',
					'SiteId',
					'TagId',
					'TextMessage',
					'ThingId',
				];

				entityToCreate.UserId = Global.User.currentUser.Id;
				let jsDate = new Date();
				entityToCreate.CreationDate = moment(jsDate).format("YYYY/MM/DD HH:mm:ss");
				entityToCreate.UserTimeZoneOffset = this.utilityService.GetUserTimeZoneOffsetHours();
				entityToCreate.UserTimeZoneName = moment.tz.guess();

				for (const objectProperty in entityToCreate) {
					if (entityToCreate[objectProperty] === undefined) {
						entityToCreate[objectProperty] = null;
					}
				}
				propertyArray.forEach((propertyAsString) => {
					if (entityToCreate[propertyAsString] === undefined) {
						entityToCreate[propertyAsString] = null;
					}
				});
				console.log('entityToCreate:', entityToCreate);
				// //TODO create stored procedure for adding record in this table
				// let statement = "API.DashboardUpdateWidgetRecordSettings " + "@ParentDashboardId = " + this.widgetObject.Id + ", @Id = " + this.widgetObject.WidgetId;

				let statement =
					"API.AlertManagementInsertNewAlarmRecord " +
					"@UserId = " +
					entityToCreate.UserId +
					", @Email = " +
					entityToCreate.Email +
					", @TextMessage = " +
					entityToCreate.TextMessage +
					", @CriticalSiteId = " +
					entityToCreate.CriticalSiteId +
					", @SiteId = " +
					entityToCreate.SiteId +
					", @CriticalGateSystemId = " +
					entityToCreate.CriticalGateSystemId +
					", @GateSystemId = " +
					entityToCreate.GateSystemId +
					", @CriticalAssetId = " +
					entityToCreate.CriticalAssetId +
					", @AlertAssetId = " +
					entityToCreate.AlertAssetId +
					", @AssetId = " +
					entityToCreate.AssetId +
					", @TagId = " +
					entityToCreate.TagId +
					", @JbtStandardObservationId = " +
					entityToCreate.JbtStandardObservationId +
					", @JbtStandardObservationSiteId = " +
					entityToCreate.JbtStandardObservationSiteId +
					", @AllPBBCriticalSiteId = " +
					entityToCreate.AllPBBCriticalSiteId +
					", @AllPBBSiteId = " +
					entityToCreate.AllPBBSiteId +
					", @AllPCACriticalSiteId = " +
					entityToCreate.AllPCACriticalSiteId +
					", @AllPCASiteId = " +
					entityToCreate.AllPCASiteId +
					", @AllGPUCriticalSiteId = " +
					entityToCreate.AllGPUCriticalSiteId +
					", @AllGPUSiteId = " +
					entityToCreate.AllGPUSiteId +
					", @AllGSECriticalSiteId = " +
					entityToCreate.AllGSECriticalSiteId +
					", @AllGSESiteId = " +
					entityToCreate.AllGSESiteId +
					", @CriticalGSEAssetModelId = " +
					entityToCreate.CriticalGSEAssetModelId +
					", @GSEAssetModelId = " +
					entityToCreate.GSEAssetModelId +
					", @CriticalGSEAssetTypeId = " +
					entityToCreate.CriticalGSEAssetTypeId +
					", @GSEAssetTypeId = " +
					entityToCreate.GSEAssetTypeId +
					", @CriticalThingId = " +
					entityToCreate.CriticalThingId +
					", @AlertThingId = " +
					entityToCreate.AlertThingId +
					", @ThingId = " +
					entityToCreate.ThingId +
					", @GroupThingId = " +
					entityToCreate.GroupThingId +
					', @CreatorUserId = ' +
					entityToCreate.CreatorUserId +
					", @LastModifiedUserId = " +
					entityToCreate.LastModifiedUserId +
					", @CreationDate = '" +
					entityToCreate.CreationDate +
					"', @LastModifiedDate = " +
					entityToCreate.LastModifiedDate +
					", @MinutesBetweenNotification = " +
					entityToCreate.MinutesBetweenNotification +
					", @MinutesToEscalation = " +
					entityToCreate.MinutesToEscalation +
					", @SendAllHours = " +
					entityToCreate.SendAllHours +
					", @SendAllDays = " +
					entityToCreate.SendAllDays +
					", @SendHour00 = " +
					entityToCreate.SendHour00 +
					", @SendHour01 = " +
					entityToCreate.SendHour01 +
					", @SendHour02 = " +
					entityToCreate.SendHour02 +
					", @SendHour03 = " +
					entityToCreate.SendHour03 +
					", @SendHour04 = " +
					entityToCreate.SendHour04 +
					", @SendHour05 = " +
					entityToCreate.SendHour05 +
					", @SendHour06 = " +
					entityToCreate.SendHour06 +
					", @SendHour07 = " +
					entityToCreate.SendHour07 +
					", @SendHour08 = " +
					entityToCreate.SendHour08 +
					", @SendHour09 = " +
					entityToCreate.SendHour09 +
					", @SendHour10 = " +
					entityToCreate.SendHour10 +
					", @SendHour11 = " +
					entityToCreate.SendHour11 +
					", @SendHour12 = " +
					entityToCreate.SendHour12 +
					", @SendHour13 = " +
					entityToCreate.SendHour13 +
					", @SendHour14 = " +
					entityToCreate.SendHour14 +
					", @SendHour15 = " +
					entityToCreate.SendHour15 +
					", @SendHour16 = " +
					entityToCreate.SendHour16 +
					", @SendHour17 = " +
					entityToCreate.SendHour17 +
					", @SendHour18 = " +
					entityToCreate.SendHour18 +
					", @SendHour19 = " +
					entityToCreate.SendHour19 +
					", @SendHour20 = " +
					entityToCreate.SendHour20 +
					", @SendHour21 = " +
					entityToCreate.SendHour21 +
					", @SendHour22 = " +
					entityToCreate.SendHour22 +
					", @SendHour23 = " +
					entityToCreate.SendHour23 +
					", @SendSun = " +
					entityToCreate.SendSun +
					", @SendMon = " +
					entityToCreate.SendMon +
					", @SendTue = " +
					entityToCreate.SendTue +
					", @SendWed = " +
					entityToCreate.SendWed +
					", @SendThu = " +
					entityToCreate.SendThu +
					", @SendFri = " +
					entityToCreate.SendFri +
					", @SendSat = " +
					entityToCreate.SendSat +
					", @UserTimeZoneOffset = " +
					entityToCreate.UserTimeZoneOffset
					", @UserTimeZoneName = '" +
					entityToCreate.UserTimeZoneName + "'"
					;
				this.dataService.SQLActionAsPromise(statement).then((data: any) => {
					console.log('inserted data:', data);
					if(!this.dbEmailTagUsers) {
						this.dbEmailTagUsers = [];
					}
					data?.forEach((recordSavedToDB) => {
						this.dbEmailTagUsers.push(recordSavedToDB);
					});
					this.dbEmailTagUsers?.forEach((etu) => {
						this.SetButtonSelectionsForCategory("criticalSite", etu);
						this.SetButtonSelectionsForCategory("site", etu);

						this.SetButtonSelectionsForCategory("criticalGateSystem", etu);
						this.SetButtonSelectionsForCategory("gateSystem", etu);

						this.SetButtonSelectionsForCategory("criticalAsset", etu);
						this.SetButtonSelectionsForCategory("alertAsset", etu);
						this.SetButtonSelectionsForCategory("asset", etu);

						this.SetButtonSelectionsForCategory("allPBBCriticalSite", etu);
						this.SetButtonSelectionsForCategory("allPBBSite", etu);

						this.SetButtonSelectionsForCategory("allPCACriticalSite", etu);
						this.SetButtonSelectionsForCategory("allPCASite", etu);

						this.SetButtonSelectionsForCategory("allGPUCriticalSite", etu);
						this.SetButtonSelectionsForCategory("allGPUSite", etu);

						this.SetButtonSelectionsForCategory("allGSECriticalSite", etu);
						this.SetButtonSelectionsForCategory("allGSESite", etu);

						this.SetButtonSelectionsForCategory("CriticalGSEAssetType", etu);
						this.SetButtonSelectionsForCategory("GSEAssetType", etu);

						this.SetButtonSelectionsForCategory("CriticalGSEAssetModel", etu);
						this.SetButtonSelectionsForCategory("GSEAssetModel", etu);

						this.SetButtonSelectionsForCategory("CriticalThing", etu);
						this.SetButtonSelectionsForCategory("AlertThing", etu);
						this.SetButtonSelectionsForCategory("Thing", etu);

						this.SetButtonSelectionsForCategory('tag', etu);
						this.SetButtonSelectionsForCategory('JbtStandardObservationId', etu);
						});


						this.ValidateAllSelections();
						this.GenerateSelectionsSummary();

					});



				// this.dataService.AddEntity("EmailTagUsers", entityToCreate).then((createdEntity) => {
				//     console.log("Added %O", createdEntity);
				//     this.signalR.signalAllClientsInGroup("Admin", "EmailTagUser Added", createdEntity.Id);
				//     this.dbEmailTagUsers.push(createdEntity);
				//     //debugger;
				// });

				});

			//Delete entities that are no longer present as selected.
			var entitiesToDelete = this.dbEmailTagUsers?.filter((dbEntity) => {
				var sbEntityMatch = allSelectedButtonsAsDatabaseRecords.find(
					(sbEntity) => {
						return (
							(sbEntity.SiteId || -1) ==
								(dbEntity.SiteId || -2) ||
							(sbEntity.CriticalSiteId || -1) ==
								(dbEntity.CriticalSiteId || -2) ||
							(sbEntity.GateSystemId || -1) ==
								(dbEntity.GateSystemId || -2) ||
							(sbEntity.CriticalGateSystemId || -1) ==
								(dbEntity.CriticalGateSystemId || -2) ||
							(sbEntity.AssetId || -1) ==
								(dbEntity.AssetId || -2) ||
							(sbEntity.CriticalAssetId || -1) ==
								(dbEntity.CriticalAssetId || -2) ||
							(sbEntity.AlertAssetId || -1) ==
								(dbEntity.AlertAssetId || -2) ||
							(sbEntity.TagId || -1) == (dbEntity.TagId || -2) ||
							(sbEntity.AllPBBSiteId || -1) ==
								(dbEntity.AllPBBSiteId || -2) ||
							(sbEntity.AllPBBCriticalSiteId || -1) ==
								(dbEntity.AllPBBCriticalSiteId || -2) ||
							(sbEntity.AllPCASiteId || -1) ==
								(dbEntity.AllPCASiteId || -2) ||
							(sbEntity.AllPCACriticalSiteId || -1) ==
								(dbEntity.AllPCACriticalSiteId || -2) ||
							(sbEntity.AllGPUSiteId || -1) ==
								(dbEntity.AllGPUSiteId || -2) ||
							(sbEntity.AllGPUCriticalSiteId || -1) ==
								(dbEntity.AllGPUCriticalSiteId || -2) ||
							(sbEntity.AllGSESiteId || -1) ==
								(dbEntity.AllGSESiteId || -2) ||
							(sbEntity.AllGSECriticalSiteId || -1) ==
								(dbEntity.AllGSECriticalSiteId || -2) ||
							(sbEntity.GSEAssetTypeId || -1) ==
								(dbEntity.GSEAssetTypeId || -2) ||
							(sbEntity.CriticalGSEAssetTypeId || -1) ==
								(dbEntity.CriticalGSEAssetTypeId || -2) ||
							(sbEntity.GSEAssetModelId || -1) ==
								(dbEntity.GSEAssetModelId || -2) ||
							(sbEntity.CriticalGSEAssetModelId || -1) ==
								(dbEntity.CriticalGSEAssetModelId || -2) ||
							(sbEntity.ThingId || -1) ==
								(dbEntity.ThingId || -2) ||
							(sbEntity.CriticalThingId || -1) ==
								(dbEntity.CriticalThingId || -2) ||
							(sbEntity.AlertThingId || -1) ==
								(dbEntity.AlertThingId || -2) ||
							(sbEntity.JbtStandardObservationId || -1) ==
								(dbEntity.JbtStandardObservationId || -2)
						);
					}
				);
				return !sbEntityMatch;
			});

			//debugger;
			entitiesToDelete?.forEach((entityToDelete) => {
				let statement = "API.AlertManagementDeleteAlarmRecord " + "@Id = " + entityToDelete.Id + ", @UserId = " + entityToDelete.UserId;
				this.dataService.SQLActionAsPromise(statement).then((data: any) => {
					console.log('data after delete:', data);
					this.dbEmailTagUsers = this.dbEmailTagUsers.filter((record) => {
						return record.Id !== entityToDelete.Id;
					});

					console.log('dbEmailTagUsers:', this.dbEmailTagUsers);

					this.ValidateAllSelections();
					this.GenerateSelectionsSummary();
				});



				// dataService.DeleteEntity("EmailTagUsers", { Id: entityToDelete.Id }).then(() => {
				//     this.signalR.signalAllClientsInGroup("Admin", "EmailTagUser Deleted", entityToDelete.Id);
				//     this.dbEmailTagUsers = this.dbEmailTagUsers.filter((etu) => { return etu.Id != entityToDelete.Id });
				//     //debugger;
				// });
			});

			//debugger;

			this.ValidateAtLeastOneCategoryEnabled();
		}, 500);
	}

	GetDatabaseEntityImageForSelectionCategory(category, imageArray) {
		//The button selection for a given category (like "criticalSite") will have an Id in the array that is set to the id pertinent to the selection.
		//For instance:
		//	criticalSite:				-- This is the category
		//		allDays: []
		//		allHours: []
		//		days: []
		//		email: Array(3)
		//			2: true
		//			length: 3
		//		enabled: Array(3)		--This is the collection that is checked.
		//			2: true				--Index 2 is set to true. In this case, the SiteId is 2.
		//			length: 3
		//		hours: []
		//		textMessage: (3) [empty × 2, true]

		var categoryFieldId = this.buttonSelections[category];

		if (categoryFieldId && this.dataCollections.category.contains(category)) {
				this.buttonSelections[category].enabled.forEach((selected, input_enabledId) => {
				//In the above example, if the "criticalSite" site category is being examined, the the enabled array will be true for the site id as an index item (enabledId).
				if (selected) {
					var enabledId = input_enabledId;

					// for tag - flip to the Std Obs Id
					let databaseId;
					if(category == "tag") {
						let tag = this.alarmTags.find((t) => {
							return t.JBTStandardObservationId == enabledId
						});

						databaseId = tag?.Id;
					}


					if (capitalizedCategory == "GateSystem" || capitalizedCategory == "CriticalGateSystem") {
						enabledId = this.widgetObject.WidgetGateSystemId;
					} else if (capitalizedCategory == "Asset" || capitalizedCategory == "CriticalAsset" || capitalizedCategory == "AlertAsset") {
						enabledId = this.widgetObject.WidgetAssetId;
					} else if (capitalizedCategory == "Thing" || capitalizedCategory == "CriticalThing" || capitalizedCategory == "AlertThing") {
						enabledId = this.myFleet.fleetId;
					}

					var dbEntityImage = {
						UserId: Global.User.currentUser.Id,
						Email: this.buttonSelections[category].email[enabledId],
						TextMessage: this.buttonSelections[category].textMessage[enabledId],
						SendAllDays: this.buttonSelections[category].allDays.some((selectionState, selectionId) => {
							return selectionId == enabledId && selectionState;
						}),
						SendAllHours: this.buttonSelections[category].allHours.some((selectionState, selectionId) => {
							return selectionId == enabledId && selectionState;
						}),
						MinutesToEscalation: this.buttonSelections[category].MinutesToEscalation[enabledId] == '' ? undefined : this.buttonSelections[category].MinutesToEscalation[enabledId]
					};

					var capitalizedCategory = category.substring(0, 1).toUpperCase() + category.substring(1);

					dbEntityImage[capitalizedCategory + "Id"] = category == "tag" ? databaseId : enabledId;

					if(capitalizedCategory == 'JbtStandardObservation' || capitalizedCategory == 'CriticalGSEAssetType' || capitalizedCategory == 'GSEAssetType'
																	|| capitalizedCategory == 'CriticalGSEAssetModel' || capitalizedCategory == 'GSEAssetModel' )
					{
						dbEntityImage['JbtStandardObservationSiteId'] = this.widgetObject.WidgetSiteId;
						dbEntityImage['GroupThingId'] = this.myFleet?.FleetId;
					}

					if(this.buttonSelections[category].MinutesBetweenNotification[enabledId] == '') {
						dbEntityImage['MinutesBetweenNotification'] = undefined;
					}
					else if(this.buttonSelections[category].MinutesBetweenNotification[enabledId] < 15) {

						dbEntityImage['MinutesBetweenNotification'] = undefined;
					}
					else {
						dbEntityImage['MinutesBetweenNotification'] = this.buttonSelections[category].MinutesBetweenNotification[enabledId];
					}



					//Go through each day of the week and set the appropriate property value.
					this.dataCollections.daysOfWeek.forEach((dayOfWeek) => {
						let booleanVariable = this.buttonSelections[category]
							.days[dayOfWeek]
							? this.buttonSelections[category].days[dayOfWeek][
									enabledId
								]
							: false;
						dbEntityImage['Send' + dayOfWeek] = booleanVariable;
					});

					//Go through each hour of the day and set the appropriate property value.
					this.dataCollections.hoursOfDay00to11.concat(this.dataCollections.hoursOfDay12to23).forEach((hourOfDay) => {
						var hourAsTwoDigitString = hourOfDay.toString().padStart(2, "0");
						dbEntityImage["SendHour" + hourAsTwoDigitString] = this.buttonSelections[category].hours[hourOfDay] ? this.buttonSelections[category].hours[hourOfDay][enabledId] : false;
					});
					imageArray.push(dbEntityImage);
				}
			});
		}
	}

	// clickDay - when a Day is selected, auto select the days in between first selected and this day
	//daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
	clickDay(category, enabledId, dayOfWeek) {
		console.log("day click ", this.dataCollections.daysOfWeek.indexOf(dayOfWeek));

		// let's only do the quick select if their is only 1 selected
		var numberSelected = 0;
		this.dataCollections.daysOfWeek.forEach((checkDayOfWeek) => {
			var daySelected = this.buttonSelections[category].days[checkDayOfWeek] ? this.buttonSelections[category].days[checkDayOfWeek][enabledId] : false;
			if (daySelected == true) {
				++numberSelected;
			}
		});

		if (numberSelected > 1) {
			return;
		}

		// get the current selected day. false indicates switching from false to true
		var index = this.dataCollections.daysOfWeek.indexOf(dayOfWeek);
		var currentDaySelected = this.buttonSelections[category].days[dayOfWeek] ? this.buttonSelections[category].days[dayOfWeek][enabledId] : false;
		if (currentDaySelected == false && index > 1) {
			var firstDay = index == 6 ? 0 : -1;
			for (var pastDayIndex = index - 1; pastDayIndex > firstDay; --pastDayIndex) {
				// look at previous days for selection
				var pastDay = this.dataCollections.daysOfWeek[pastDayIndex];
				var pastDaySelected = this.buttonSelections[category].days[pastDay] ? this.buttonSelections[category].days[pastDay][enabledId] : false;

				if (pastDaySelected == true) {
					// got a selected day, select all the days in between
					for (var fwdDayIndex = pastDayIndex + 1; fwdDayIndex < index; ++fwdDayIndex) {
						let fwdDay = this.dataCollections.daysOfWeek[fwdDayIndex];
						this.buttonSelections[category].days[fwdDay][enabledId] = true;
					}
				}
			}
		}
	}

	// clickHour - when a Day is selected, auto select the days in between first selected and this day
	// hoursOfDay00to11: _.range(0, 12).filter((digit) => {
	// 	return digit < 12;
	// }),
	// hoursOfDay12to23: _.range(12, 24).filter((digit) => {
	// 	return digit < 24;
	// }),
	clickHour(category, enabledId, hourOfDay) {
		console.log("hour click ", this.dataCollections.hoursOfDay00to11.concat(this.dataCollections.hoursOfDay12to23).indexOf(hourOfDay));

		// let's only do the quick select if their is only 1 selected
		var numberSelected = 0;
		this.dataCollections.hoursOfDay00to11.concat(this.dataCollections.hoursOfDay12to23).forEach((checkHourOfDay) => {
			var hourSelected = this.buttonSelections[category].hours[checkHourOfDay] ? this.buttonSelections[category].hours[checkHourOfDay][enabledId] : false;
			if (hourSelected == true) {
				++numberSelected;
			}
		});

		if (numberSelected > 1) {
			return;
		}

		var index = this.dataCollections.hoursOfDay00to11.concat(this.dataCollections.hoursOfDay12to23).indexOf(hourOfDay);
		var currentHourSelected = this.buttonSelections[category].hours[hourOfDay] ? this.buttonSelections[category].hours[hourOfDay][enabledId] : false;
		if (currentHourSelected == false && index > 1) {
			var firstDay = index == 23 ? 0 : -1;
			for (var pastHourIndex = index - 1; pastHourIndex > firstDay; --pastHourIndex) {
				// look at previous days for selection
				var pastHour = this.dataCollections.hoursOfDay00to11.concat(this.dataCollections.hoursOfDay12to23)[pastHourIndex];
				var pastHourSelected = this.buttonSelections[category].hours[pastHour] ? this.buttonSelections[category].hours[pastHour][enabledId] : false;

				if (pastHourSelected == true) {
					// got a selected day, select all the days in between
					for (var fwdHourIndex = pastHourIndex + 1; fwdHourIndex < index; ++fwdHourIndex) {
						let fwdHour = this.dataCollections.hoursOfDay00to11.concat(this.dataCollections.hoursOfDay12to23)[fwdHourIndex];
						this.buttonSelections[category].hours[fwdHour][enabledId] = true;
					}
				}
			}
		}
	}

	//+SiteId Change
	selectSite(event, newValue) {
		if (newValue != this.widgetObject.WidgetSiteId && newValue > 0) {
			console.log("SiteId changed now " + newValue);
			//update the widget site id through stored procedure call
			this.widgetObject.WidgetSiteId = +newValue;
			this.widgetObject.WidgetGateSystemId = null;
			this.widgetObject.WidgetAssetId = null;
			this.site = this.dataService.cache.sites.find((site) => {
				return site.Id == this.widgetObject.WidgetSiteId;
			});
			this.SaveWidgetResourceObjectIfChanged("Site").then((returnedWidget) => {
				this.widgetObject.WidgetSiteName = returnedWidget.WidgetSiteName;
				console.log('saved site:', returnedWidget);

				this.gates = null;
				this.assets = null;

				//this.GetGatesListForSideBar();
				this.GetAlarmTagsForAsset();
			});
		}
	}

	//+GateSystemId Change
	// selectGateSystem(event, newValue): void {
	// 	if (newValue !== this.widgetObject.WidgetGateSystemId && newValue > 0) {
	// 		console.log('GateSystemId changed now ' + newValue);

	// 		//update the widget site id through stored procedure call
	// 		//TODO
	// 		this.widgetObject.WidgetGateSystemId = +newValue;
	// 		this.gateSystem = this.dataService.cache.systemsObject[newValue];
	// 		this.widgetObject.WidgetAssetId = null;
	// 		this.SaveWidgetResourceObjectIfChanged('Gate').then(
	// 			(returnedWidget) => {
	// 				this.GetAssetsListForSideBar();
	// 			}
	// 		);
	// 	}
	// }

	// selectAsset(event, newValue) {
	// 	if (newValue != this.widgetObject.WidgetAssetId && newValue > 0) {
	// 		console.log('Asset Id was different');
	// 		this.widgetObject.WidgetAssetId = newValue;

	// 		this.SaveWidgetResourceObjectIfChanged('Asset').then(
	// 			(returnedWidget) => {
	// 				this.asset = this.dataService.cache.assetsObject[+newValue];
	// 				// vm.widget.displaySettings.headingExtraTitle = GetHeadingExtraTitle();
	// 				if (this.asset) {
	// 					this.alarmTags = this.asset.Tags.where(function (tag) {
	// 						return (
	// 							tag.isAlarm ||
	// 							tag.JBTStandardObservation
	// 								.JBTStandardObservationSeverityLevelId
	// 						);
	// 					});
	// 					console.log(this.alarmTags);
	// 				}
	// 			}
	// 		);
	// 	}
	// }

	SaveWidgetResourceObjectIfChanged(SettingSaved?: string) {
		let statement = "API.DashboardUpdateWidgetRecordSettings " + "@ParentDashboardId = " + this.widgetObject.Id + ", @Id = " + this.widgetObject.WidgetId;
		if (!_.isNil(this.widgetObject.WidgetSiteId)) {
			statement = statement + ", @widgetSiteId = " + this.widgetObject.WidgetSiteId;
		}
		if (!_.isNil(this.widgetObject.WidgetTerminalSystemId)) {
			statement = statement + ", @widgetTerminalSystemId = " + this.widgetObject.WidgetTerminalSystemId;
		}

		if (!_.isNil(this.widgetObject.WidgetGateSystemId)) {
			statement = statement + ", @widgetGateSystemId = " + this.widgetObject.WidgetGateSystemId;
		}
		if (!_.isNil(this.widgetObject.WidgetAssetId)) {
			statement = statement + ", @widgetAssetSystemId = " + this.widgetObject.WidgetAssetId;
		}

		return this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			let returnedWidget = data.first();

			// Force Site Time when logged in as another user
			if (Global.User.isLoggedInAsDifferentUser == true) {
				returnedWidget.TimeZoneId = 2;
			}

			this.utilityService.showToastMessageShared({
				type: "success",
				message: SettingSaved + " settings have been changed!"
			});
			return returnedWidget;
		});
	}

	// TODO $scope.$watch("vm.widget.alternateAlarmNotificationPersonId",
	// function (newValue, oldValue) {

	//     //If any of the entities have an Id attribute, then this is editing an existing value.
	//     //send the changes to all other browsers as they press the keys.
	//     if ((oldValue || '') != (newValue || '')) {

	//         //debugger;
	//         console.log("alternate contact person change change = Old = " + oldValue + " New = " + newValue);
	//         if (!newValue) {
	//             vm.widget.alternateAlarmNotificationPerson = null;
	//         }

	//         vm.person.AlternateNotificationEmailPersonId = newValue;
	//         vm.person.$save().then(function () {
	//             signalR.SignalAllClientsInGroup("Admin", "Person Modified", vm.person.Id);
	//         });

	//     }

	// });

	SetSortField(fieldName) {
		var t0 = performance.now();

		if (this.widgetObject.displaySettings.tagDataSortField.substr(1, 50) == fieldName || this.widgetObject.displaySettings.tagDataSortField == fieldName) {
			if (this.widgetObject.displaySettings.tagDataSortField.substr(0, 1) == "-") {
				this.widgetObject.displaySettings.tagDataSortField = fieldName;
			} else {
				this.widgetObject.displaySettings.tagDataSortField = "-" + fieldName;
			}
		} else {
			this.widgetObject.displaySettings.tagDataSortField = fieldName;
		}
	}

	tagFilterFunction(element) {
		if ((this.widgetObject.searchText || "") != "") {
			var elementFound = false;
			try {
				elementFound = element.JBTStandardObservation.Name.toLowerCase().contains((this.widgetObject.searchText || "").toLowerCase()) || element.TagName.toLowerCase().contains((this.widgetObject.searchText || "").toLowerCase()) || element.JbtStandardObservation.toString().contains(this.widgetObject.searchText) || element.TagId.toString().contains(this.widgetObject.searchText);
			}
			catch (e) {
				elementFound = element.JBTStandardObservation.Name.toLowerCase().indexOf((this.widgetObject.searchText || "").toLowerCase()) > -1 || element.TagName.toLowerCase().indexOf((this.widgetObject.searchText || "").toLowerCase()) > -1 || element.JbtStandardObservation.toString().indexOf(this.widgetObject.searchText) > -1 || element.TagId.toString().indexOf(this.widgetObject.searchText) > -1;
			}
			return elementFound;
		} else {
			return true;
		}
	}

	ReportTagClicked(tagClicked) {
		console.log(this.alarmTags);
		console.log("Tag Clicked = %O", tagClicked);
	}

	GetHeadingExtraTitle() {
		//console.log("Getting the title");
		//console.log("vm = %O", vm);
		if (this.asset) {
			return " - " + this.asset.Site.Name + " Gate " + this.asset.ParentSystem.Name + " - " + this.asset.Name + (this.asset.ModelGenericName ? " - " + this.asset.ModelGenericName : "");
		}
	}
}
